<script>
	import { onMount, onDestroy } from 'svelte';
	import { checkMaintenanceStatus } from '../../Middleware/MaintenanceStatus.js'

	let checkInterval;

	onMount(() => {
		checkMaintenanceStatus();
		checkInterval = setInterval(checkMaintenanceStatus, 5 * 60 * 1000);
	});

	onDestroy(() => {
		if (checkInterval) clearInterval(checkInterval);
	});
</script>
