

/**
 * @class Model
 * @description Représentation d'une categorie tarifaire dans Sage
 * @property {string} CT_Intitule - 
 * @property {number} CT_PrixTTC - 
 * @property {number} cbIndice - 
 * @property {number} cbMarq - 
 */
class CategorieTarifaireResponse {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.CT_Intitule = null;
    /** @type {number} */
    this.CT_PrixTTC = null;
    /** @type {number} */
    this.cbIndice = null;
    /** @type {number} */
    this.cbMarq = null;
  }
}

export default CategorieTarifaireResponse;
