<script>
	import { onMount } from 'svelte';
	import { toggleMaintenance, getMaintenanceStatus } from '../../API/Backend/maintenance';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';
	import Input from '../Input/Input.svelte';
	import DateInput from '../Input/DateInput.svelte';
	import { addToast } from '../../store';
	import { formatDateForInput } from '../../utils.js';

	let isInMaintenance = false;
	let message = '';
	let endTime = null;

	onMount(async () => {
		try {
			const status = await getMaintenanceStatus();
			isInMaintenance = status.isInMaintenance;
			message = status.message || '';
			endTime = status.endTime;
		} catch (error) {
			addToast('Erreur lors de la récupération du statut', 'bg-red-500');
		}
	});

	async function handleToggleMaintenance() {
		try {
			const result = await toggleMaintenance({
				isInMaintenance: !isInMaintenance,
				message,
				endtime: formatDateForInput(endTime)
			});
			isInMaintenance = result.isInMaintenance;
			addToast(
				`Mode maintenance ${isInMaintenance ? 'activé' : 'désactivé'} avec succès`,
				'bg-green-500'
			);
		} catch (error) {
			addToast('Erreur lors du basculement de la maintenance', 'bg-red-500');
		}
	}
</script>

<div class="p-4 bg-white rounded-lg shadow">
	<h2 class="text-xl font-bold mb-4">Gestion de la maintenance</h2>

	<div class="space-y-4">
		<Input
			label="Message de maintenance"
			bind:value={message}
			placeholder="Message à afficher pendant la maintenance"
		/>

		<DateInput
			label="Date de fin prévue"
			bind:value={endTime}
			enableFuture={true}
			disablePast={true}
			showTimePicker={true}
		/>

		<ButtonPrimary
			on:click={handleToggleMaintenance}
			content={isInMaintenance ? 'Désactiver la maintenance' : 'Activer la maintenance'}
		/>
	</div>
</div>
