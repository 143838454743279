<script>
	// Components
	import ButtonPrimary from '../../components/Button/ButtonPrimary.svelte';
	import Input from '../../components/Input/Input.svelte';
	import Drawer from '../../components/Drawer.svelte';
	import FormGroup from '../../components/Form/FormGroup.svelte';
	import Card from '../../components/Card.svelte';
	import Modal from '../../components/Modal.svelte';

	// Store
	import { onMount } from 'svelte';
	import { addToast, closeDrawer, openDrawer } from '../../store.js';
	import { openUrlInCurrentTab } from '../../utils.js';
	import { linkClientToCollabo } from '../../API/API_SAGE/utils.js';

	// Queries
	import { getClientByReference, getClients } from '../../API/API_SAGE/ClientQueries.js';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';
	import { Create } from '../../API/Backend/rights/permissions.js';
	import { getCommunesFromCodePostal } from '../../API/API_SAGE/ServiceQueries.js';
	import Combobox from '../../components/Combobox/Combobox.svelte';

	let name;
	let surname;
	let address;
	let city;
	let zipcode;
	let showConfirmModal = false;
	let coordonneesCommunes = [{value: '', label: ''}];

	onMount(() => {
		window.addEventListener('keydown', handleKeyDown);
		connectedUserHasAccess('client', Create);
		return () => {
				window.removeEventListener('keydown', handleKeyDown);
		};
	});

	function createClient() {
		let queryParams = new URLSearchParams({
			isCreationFromUser: true,
			name: name,
			surname: surname,
			address: address,
			city: city,
			zipcode: zipcode
		});

		openUrlInCurrentTab(`/clients/creation?${queryParams.toString()}`);
	}

	async function addClient() {
		let clientSage;

		let searchFields = [];
		let searchValues = [];

		if (name) {
			searchFields.push('Nom');
			searchValues.push(name);
		}
		if (surname) {
			searchFields.push('Prenoms');
			searchValues.push(surname);
		}
		if (address) {
			searchFields.push('CT_Adresse');
			searchValues.push(address);
		}
		if (city) {
			searchFields.push('CT_Ville');
			searchValues.push(city);
		}
		if (zipcode) {
			searchFields.push('CT_CodePostal');
			searchValues.push(zipcode);
		}

		clientSage = await getClients('', '', '', '', searchFields.join(','), searchValues.join(','), '', 1, 100, '', false).then(res => res.data);
		if (!Array.isArray(clientSage)) {
			createClient();
		}

		openDrawer({
			id: 'clientSearch',
			data: clientSage.map(client => ({
				label: `${client.CT_Intitule}, ${client.Adresse.CT_Adresse} ${client.Adresse.CT_Ville} ${client.Adresse.CT_CodePostal}`,
				value: client.CT_Num,
				client: client
			}))
		});
	}

	async function searchClient() {
		let searchFields = [];
		let searchValues = [];

		if(name || surname){
			searchFields.push('CT_Intitule');
			searchValues.push(`${name} ${surname}`)
		}
		if (address) {
			searchFields.push('CT_Adresse');
			searchValues.push(address);
		}
		if (city) {
			searchFields.push('CT_Ville');
			searchValues.push(city);
		}
		if (zipcode) {
			searchFields.push('CT_CodePostal');
			searchValues.push(zipcode);
		}

		let clients = await getClients('', '', '', '', searchFields.join(','), searchValues.join(','), '', 1, 100, '', false).then(res => res.data);

		if (!Array.isArray(clients)) {
			addToast('Aucun client existant', 'bg-red-500');
			showConfirmModal = true;
			return;
		}

		openDrawer({
			id: 'clientSearch',
			data: clients.map(client => ({
				label: `${client.CT_Intitule}, ${client.Adresse.CT_Adresse} ${client.Adresse.CT_Ville} ${client.Adresse.CT_CodePostal}`,
				value: client.CT_Num,
				client: client
			}))
		});
	}

	async function handleKeyDown(event) {
		if (event.key === 'F4') {
			searchClient();
		}
	}

	async function communeQuery(codePostal){
		return await getCommunesFromCodePostal(codePostal).then((res) => {
			if(res.data.length === 0){
				return [{value: '', label: ''}];
			}
			return res.data.map((commune) => ({
				value: commune.LibelleAcheminement,
				label: commune.NomCommune
			}));
		});
	}
</script>

<div class="container mx-auto p-2">
	<Card title="Recherche client avant création">
		<p>Saisissez le nom du client, éventuellement les autres champs puis cliquez sur le bouton <strong class="text-primary">rechercher (F4)</strong> afin de voir les clients existants.</p>
		<p>Si votre client n'apparait pas dans la liste utiliser le bouton créer pour le créer.</p>
	</Card>

	<FormGroup title="Identification" isCollapsible={false}>
		<div class="flex gap-2">
			<Input
				id="name"
				label="Nom client"
				placeholder="Nom client"
				classes="w-1/3"
				bind:value={name}
			/>
			<Input
				id="surname"
				label="Prénom client"
				placeholder="Prénom client"
				classes="w-1/3"
				bind:value={surname}
			/>
		</div>
	</FormGroup>

	<FormGroup title="Adresse" isCollapsible={false}>
		<div class="flex gap-2">
			<Input
				id="address"
				label="Adresse"
				placeholder="Adresse"
				classes="w-1/3"
				bind:value={address}
			/>

			<Input
				id="zip"
				label="Code postal"
				placeholder="Code postal"
				classes="w-1/3"
				bind:value={zipcode}
				on:input={async (e) => {
					coordonneesCommunes = await communeQuery(e.detail)
					city = coordonneesCommunes[0].value;
				}}
			/>

			<Combobox
				id="city"
				label="Ville"
				placeholder="Ville"
				options={coordonneesCommunes}
				classes="w-1/3"
				bind:value={city}
			/>
		</div>
	</FormGroup>


	<div class="flex justify-center mt-2">
		<ButtonPrimary on:click={searchClient}>
			<div class="p-2 text-sm font-medium">
				Rechercher
			</div>
		</ButtonPrimary>
	</div>
</div>

<Drawer id="clientSearch">
	<div slot="drawerContent" let:data>
		{#each data as datum}
			<div class="flex justify-between items-center border-b border-gray-200 w-full">
				<button type="button"
								class="w-full bg-transparent hover:bg-gray-100 text-left p-2 font-medium"
								on:click={async() => {
									let sageClient = await getClientByReference(datum.value).then(res => res.data);
									name = sageClient.InfoLibres.filter(infoLibre => infoLibre.Intitule === 'LS_NOM')[0].Valeur;
									surname = sageClient.InfoLibres.filter(infoLibre => infoLibre.Intitule === 'LS_PRENOMS')[0].Valeur;
									address = sageClient.Adresse.CT_Adresse;
									city = sageClient.Adresse.CT_Ville;
									zipcode = sageClient.Adresse.CT_CodePostal;
									closeDrawer();
								}}>
					{datum.label}
				</button>

				<ButtonPrimary on:click={async() => {
						await linkClientToCollabo(datum.client);
						closeDrawer();
				}} >Sélectionner </ButtonPrimary>
			</div>
		{/each}
		<div class="flex w-full justify-center mt-2">
			<ButtonPrimary on:click={createClient}>
				<div class="p-2 text-sm font-medium">
					Créer
				</div>
			</ButtonPrimary>
		</div>

	</div>
</Drawer>

<Modal
	show={showConfirmModal}
	on:close={() => showConfirmModal = false}
	title="Création d'un nouveau client"
>
	<div class="p-4">
		<p>Aucun client correspondant n'a été trouvé. Voulez-vous créer un nouveau client ?</p>
		<div class="flex justify-end gap-2 mt-4">
			<ButtonPrimary on:click={() => showConfirmModal = false}>
				Annuler
			</ButtonPrimary>
			<ButtonPrimary on:click={() => {
				showConfirmModal = false;
				createClient();
			}}>
				Créer le client
			</ButtonPrimary>
		</div>
	</div>
</Modal>