import { cleanObject } from './utils.js';
import { fetchData } from './utils.js';

// DEVIS
export async function getDevis(
	strictSearchFields = '',
	strictSearchValues = '',
	excludeSearchFields = '',
	excludeSearchValues = '',
	searchFields = '',
	searchValues = '',
	OrderDirection = '',
	pageNumber = 1,
	pageSize = 10,
	OrderBy = ''
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};
	return await fetchData('vente/Devis/LDE', queryParams); // Fetch with pagination
}

/**
 * Créer un nouveau devis
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newDevis(body) {
	body = cleanObject(body); // Clean body before sending
	return await fetchData('vente/Devis/nouveau', {}, 'POST', body); // POST request
}

/**
 * Dupliquer un devis
 * @param body
 * @returns {Promise<{data: object, pagination: null}>}
 */
export async function duplicateDevis(body) {
	return await fetchData('vente/Devis/dupliquer', {}, 'POST', body); // POST request
}

/**
 * Transformer un devis
 * @param {*} body
 * @returns {Promise<{data: any, pagination: null}>}
 */
export async function transformerDevis(body) {
	return await fetchData('vente/Devis/TR', {}, 'PUT', body); // PUT request
}

/**
 * Supprimer un devis
 * @param {string} numPiece
 * @returns {Promise<*|undefined>}
 */
export async function deleteDevis(numPiece) {
	return await fetchData(`vente/Devis/supprimer/${numPiece}`, {}, 'DELETE');
}

// BON COMMANDE

/**
 * Créer un nouveau bon de commande
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newBonCommande(body) {
	body = cleanObject(body); // Clean body before sending
	return await fetchData('vente/BonCommande/nouveau', {}, 'POST', body); // POST request
}

// FACTURE
/**
 * Créer une nouvelle facture
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newFacture(body) {
	body = cleanObject(body);
	return await fetchData('vente/Facture/nouveau', {}, 'POST', body);
}

export async function getFactureComptabilises(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields = '',
	searchValues = '',
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

	return await fetchData('vente/FactureComptabilise/LFAC', queryParams); // Fetch with pagination
}

export async function getFacture(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields = '',
	searchValues = '',
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy,
	isPaid
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy,
		IsPaid: isPaid
	};

	return await fetchData('vente/Facture/LFA', queryParams); // Fetch with pagination
}

/**
 * Modifier une facture
 * @param body
 * @returns {Promise<{data: object, pagination: null}>}
 */
export async function updateFactures(body) {
	return await fetchData('vente/Facture/modifier', {}, 'PATCH', body);
}

/**
 * Dupliquer une facture
 * @param body
 * @returns {Promise<{data: object, pagination: null}>}
 */
export async function duplicateFacture(body) {
	return await fetchData('vente/Facture/dupliquer', {}, 'POST', body); // POST request
}

/**
 * Supprimer une facture
 * @param {string} numPiece
 * @returns {Promise<*|undefined>}
 */
export async function deleteFacture(numPiece) {
	return await fetchData(`vente/Facture/supprimer/${numPiece}`, {}, 'DELETE');
}

export async function getAvoir(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields = '',
	searchValues = '',
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

	return await fetchData('vente/BonAvoirFinancier/LBA', queryParams); // Fetch with pagination
}

export async function getFactureByNumPiece(numPiece) {
	return await fetchData(`vente/facture/${numPiece}`);
}

export async function getAvoirByNumPiece(numPiece) {
	return await fetchData(`vente/BonAvoirFinancier/${numPiece}`);
}

export async function getDevisByNumPiece(numPiece) {
	return await fetchData(`vente/Devis/${numPiece}`);
}

export async function updateDevisByNumPiece(numPiece, body) {
	return await fetchData(`vente/Devis/modifier/${numPiece}`, {}, 'PATCH', body);
}

export async function updateFactureByNumPiece(numPiece, body) {
	return await fetchData(`vente/Facture/modifier/${numPiece}`, {}, 'PATCH', body);
}

export async function updateAvoirByNumPiece(numPiece, body) {
	return await fetchData(`vente/BonAvoirFinancier/modifier/${numPiece}`, {}, 'PATCH', body);
}

export async function duplicateAvoir(body) {
	body = cleanObject(body);
	return await fetchData('vente/BonAvoirFinancier/dupliquer', {}, 'POST', body); // POST request
}

/**
 * Supprimer un bon d'avoir
 * @param {string} numPiece
 * @returns {Promise<*|undefined>}
 */
export async function deleteAvoir(numPiece) {
	return await fetchData(`vente/BonAvoirFinancier/supprimer/${numPiece}`, {}, 'DELETE');
}