<script>
	import { Router, Route } from 'svelte-routing';

	// pages for this layout
	import IndexClient from '../views/client/IndexClient.svelte';
	import ListeClient from '../views/client/ListeClient.svelte';
	import CreationClient from '../views/client/CreationClient.svelte';
	import NotFound from '../views/404.svelte';
</script>

<Router url="client">
	<Route path="/" component={IndexClient} />
	<Route path="liste" component={ListeClient} />
	<Route path="creation" component={CreationClient} />
	<Route path="*" component={NotFound} />
</Router>
