import { del, get, post, put } from '../api.js';

export async function getUsers(strictSearchFields,
															 strictSearchValues,
															 excludeSearchFields,
															 excludeSearchValues,
															 searchFields,
															 searchValues,
															 OrderDirection,
															 pageNumber = 1,
															 pageSize = 25,
															 OrderBy) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

	return get(`/users`, queryParams);
}

export async function getUserById(id) {
	return get(`/users/${id}`);
}

export async function createUser(name, email, password) {
	return post(`/users/create`, { name, email, password });
}

export async function updateUser(id, name, email, password) {
	return put(`/users/${id}`, { name, email, password });
}

export async function changePassword(id, oldPassword, newPassword, confirmPassword) {
	return put(`/users/change-password/${id}`, { oldPassword, newPassword, confirmPassword });
}

export async function deleteUser(id) {
	return del(`/users/${id}`);
}
