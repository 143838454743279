<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { Icon, Calendar, XMark } from 'svelte-hero-icons';
  import { DatePicker } from '@svelte-plugins/datepicker';
  import { formatDateForDisplay } from '../../utils.js';

  export let label = '';
  export let value = '';
  export let placeholder = '';
  export let required = false;
  export let disabled = false;
  export let hidden = false;
  export let classes = '';
  export let startDate = new Date();
  export let endDate = new Date();
  export let startTimeDate = new Date();
  export let endTimeDate = new Date();
  export let enabledDates;
  export let disabledDates;
  export let range = false;
  export let showPresets = false;
  export let showPresetsOnly = false;
  export let showTimePicker = false;
  export let disablePast = false;
  export let enableFuture = false;
  export let presets = [{label: '', start: Date.now(), end: Date.now()}];

  const startOfWeek = 1;
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const dowLabels = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  const monthLabels = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const dispatch = createEventDispatcher();

  let inputHtml;
  let isOpen = false;
  let isMount = true;
  let formattedStartDate = formatDateForDisplay(startDate);
  let formattedEndDate = formatDateForDisplay(endDate);

  $: formattedStartDate = formatDateForDisplay(startDate);
  $: formattedEndDate = formatDateForDisplay(endDate);
  $: if (range) {
    if (isMount && value) {
      startDate = value.startDate;
      endDate = value.endDate;
      isMount = false;
    } else {
      value = startDate && endDate ? {startDate: new Date(startDate), endDate: new Date(endDate)} : null;
    }
  } else {
    if (isMount && value !== null) {
      if (typeof value === 'string') {
        value = value.split('T')[0];
      }
      value = new Date(value);
      startDate = value;
      isMount = false;
    }
    value = startDate;
  }

  onMount(() => {
    window.addEventListener('click', handleFocusOut);
    return () => {
      window.removeEventListener('click', handleFocusOut);
    };
  });

  export function focus() {
    if (inputHtml) {
      inputHtml.focus();
    }
    if (inputHtml !== window.document.activeElement) {
      setTimeout(() => {
        focus();
      }, 10);
    }
  }

  function handleFocusOut(event) {
    if (!inputHtml.contains(event.target)) {
      dispatch('focusout');
    }
  }
</script>

<div class="input-group w-64 {hidden ? 'hidden' : ''} {classes}">
  {#if label}
    <label class="font-medium" for={label}>
      {label}
    </label>
  {/if}
  <div bind:this={inputHtml} class="flex relative w-full rounded-md border-none py-2 pl-3 h-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 appearance-none {disabled ? 'bg-gray-200 cursor-default' : 'cursor-pointer bg-white'}">
    <DatePicker
      bind:isOpen
      bind:startDate
      bind:endDate
      bind:startTimeDate
      bind:endTimeDate
      bind:enabledDates
      bind:disabledDates
      isRange={range}
      showPresets={showPresets}
      showPresetsOnly={showPresetsOnly}
      presetRanges={presets}
      showTimePicker={showTimePicker}
      enablePastDates={!disablePast}
      enableFutureDates={enableFuture}
      startOfWeek={startOfWeek}
      dowLabels={dowLabels}
      monthLabels={monthLabels}
      defaultMonth={currentMonth}
      defaultYear={currentYear}
      theme="custom-datepicker"
      onDateChange={(event) => dispatch('change', range ? {startDate: new Date(event.startDate), endDate: new Date(event.endDate)} : new Date(event.startDate))}
    >
      <input
        type="text"
        class="hidden"
        bind:value
        required={required}
      >
      <div
        class="flex items-center h-full w-full justify-between pr-2"
        role="button"
        tabindex="0"
        on:click={() => {
          if (!disabled) {
            isOpen = !isOpen
          }
        }}
        on:keydown={(e) => {
          if (e.key === 'Enter' && !disabled) {
            isOpen = !isOpen
          }
        }}
        class:open={isOpen}
      >
        <div class="flex space-x-2">
          <Icon src={Calendar} class="text-gray-400 w-5 h-5" />
          <div class="date">
            {#if range}
              {formattedStartDate} - {formattedEndDate}
            {:else if startDate}
              {formattedStartDate}
            {:else}
              {placeholder}
            {/if}
          </div>
        </div>
        {#if startDate && !disabled}
          <button on:click={() => {startDate = ''; endDate = ''; startTimeDate = ''; endTimeDate = ''; dispatch('change', null)}} type="button">
            <Icon src={XMark} class="text-gray-400 w-5 h-5" />
          </button>
        {/if}
      </div>
    </DatePicker>
  </div>
</div>

<style>
  .input-group {
    margin-bottom: 1rem;
  }
  label {
    display: block;
  }
  :global(.datepicker[data-picker-theme="custom-datepicker"]) {
    width: 100%;
    height: 100%;
    /**
     * Common Variables
     */
    --datepicker-border-color: #e8e9ea;

    /*--datepicker-border-radius-small: .125rem;*/
    /*--datepicker-border-radius-base: .25rem;*/
    /*--datepicker-border-radius-large: .5rem;*/
    /*--datepicker-border-radius-xlarge: .75rem;*/
    /*--datepicker-border-radius-xxlarge: 1rem;*/
    /*--datepicker-border-radius-xxxlarge: 1.125rem;*/

    --datepicker-state-active: #B8212D;
    --datepicker-state-hover: #DC9096;

    /*--datepicker-color: #21333d;*/

    /*--datepicker-font-family: 'Rubik', sans-serif;*/

    /*--datepicker-font-size-jumbo: 1.75rem;*/
    /*--datepicker-font-size-xxxlarge: 1.5rem;*/
    /*--datepicker-font-size-xxlarge: 1.375rem;*/
    /*--datepicker-font-size-xlarge: 1.25rem;*/
    /*--datepicker-font-size-large: 1.125rem;*/
    /*--datepicker-font-size-base: 14px;*/
    /*--datepicker-font-size-medium: 0.89rem;*/
    /*--datepicker-font-size-small: 0.75rem;*/
    /*--datepicker-font-size-xsmall: 0.625rem;*/
    /*--datepicker-font-size-xxsmall: 0.5rem;*/
    /*--datepicker-font-size-xxxsmall: 0.375rem;*/
    /*--datepicker-font-weight-thin: 100;*/
    /*--datepicker-font-weight-light: 300;*/
    /*--datepicker-font-weight-base: 400;*/
    /*--datepicker-font-weight-medium: 500;*/
    /*--datepicker-font-weight-bold: 700;*/
    /*--datepicker-font-weight-black: 900;*/

    /*--datepicker-spacing: 8px;*/

    /*--datepicker-margin-xsmall: calc(var(--datepicker-spacing) / 4);*/
    /*--datepicker-margin-small: calc(var(--datepicker-spacing) / 2);*/
    /*--datepicker-margin-base: var(--datepicker-spacing);*/
    /*--datepicker-margin-large: calc(var(--datepicker-spacing) * 2);*/
    /*--datepicker-margin-xlarge: calc(var(--datepicker-spacing) * 3);*/
    /*--datepicker-margin-xxlarge: calc(var(--datepicker-spacing) * 4);*/
    /*--datepicker-margin-xxxlarge: calc(var(--datepicker-spacing) * 5);*/
    /*--datepicker-margin-jumbo: calc(var(--datepicker-spacing) * 6);*/

    /*--datepicker-padding-xsmall: calc(var(--datepicker-spacing) / 4);*/
    /*--datepicker-padding-small: calc(var(--datepicker-spacing) / 2);*/
    /*--datepicker-padding-base: var(--datepicker-spacing);*/
    /*--datepicker-padding-large: calc(var(--datepicker-spacing) * 2);*/
    /*--datepicker-padding-xlarge: calc(var(--datepicker-spacing) * 3);*/
    /*--datepicker-padding-xxlarge: calc(var(--datepicker-spacing) * 4);*/
    /*--datepicker-padding-xxxlarge: calc(var(--datepicker-spacing) * 5);*/
    /*--datepicker-padding-jumbo: calc(var(--datepicker-spacing) * 6);*/

    /*--datepicker-calendar-day-color: #B8212D;*/
    /*--datepicker-calendar-day-color-disabled: #B8212D;*/
    /**
     * Calendar Range
     */
    --datepicker-calendar-range-background: #DC9096;
    /*--datepicker-calendar-range-background-disabled: var(--datepicker-state-hover);*/
    /*--datepicker-calendar-range-border: 0;*/
    /*--datepicker-calendar-range-border-radius: 0;*/
    /*--datepicker-calendar-range-color: #DC9096;*/
    /*--datepicker-calendar-range-color-disabled: #ffc0b7;*/
    /*--datepicker-calendar-range-cursor: #DC9096;*/
    /*--datepicker-calendar-range-font-weight: var(--datepicker-font-weight-base);*/

    /**
		 * Calendar Range Start & End
		 */
    --datepicker-calendar-range-start-box-shadow: inset -20px 0 0 var(--datepicker-state-hover);
    --datepicker-calendar-range-end-box-shadow: inset 20px 0 0 var(--datepicker-state-hover);
    --datepicker-calendar-range-start-box-shadow-selected: inset -20px 0 0 var(--datepicker-state-hover);
    --datepicker-calendar-range-end-box-shadow-selected: inset 20px 0 0 var(--datepicker-state-hover);

    --datepicker-calendar-range-start-end-background: var(--datepicker-state-active);
    --datepicker-calendar-range-start-end-color: #fff;

    /**
		 * Calendar Range Selected
		 */
    --datepicker-calendar-range-selected-background: var(--datepicker-state-active);
    /*--datepicker-calendar-range-selected-border-radius: 20px;*/
    /*--datepicker-calendar-range-selected-color: #fff;*/
    /*--datepicker-calendar-range-selected-font-weight: var(--datepicker-font-weight-medium);*/

    /*--datepicker-calendar-range-selected-start-border-radius: 20px;*/

    /**
		 * Calendar Range Hover
		 */
    --datepicker-calendar-range-included-background: var(--datepicker-state-hover);
    --datepicker-calendar-range-included-box-shadow: inset 20px 0 0 var(--datepicker-state-hover);
    --datepicker-calendar-range-included-color: #fff;
    /*--datepicker-calendar-range-included-font-weight: var(--datepicker-font-weight-base);*/
    /*--datepicker-calendar-range-included-height: var(--datepicker-calendar-day-height);*/


    /**
	   * Timepicker Container
	   */
    --datepicker-timepicker-container-align-items: center;
    --datepicker-timepicker-container-display: flex;
    --datepicker-timepicker-container-justify-content: space-around;
    --datepicker-timepicker-container-margin-bottom: var(--datepicker-margin-xlarge);

    /**
		 * Timepicker Input
		 */
    --datepicker-timepicker-input-border: 1px solid var(--datepicker-border-color);
    --datepicker-timepicker-input-border-radius: var(--datepicker-border-radius-base);
    --datepicker-timepicker-input-display: block;
    --datepicker-timepicker-input-font-family: var(--datepicker-font-family);
    --datepicker-timepicker-input-margin: 0 auto;
    --datepicker-timepicker-input-padding: var(--datepicker-padding-small) var(--datepicker-padding-base);


    /**
     * Calendar Day
     */
    /*--datepicker-calendar-day-align-items: center;*/
    --datepicker-calendar-day-background-hover: #B8212D;
    /*--datepicker-calendar-day-border: 1px solid transparent;*/
    /*--datepicker-calendar-day-border: 1px solid transparent;*/
    /*--datepicker-calendar-day-border-radius: 100%;*/
    /*--datepicker-calendar-day-color: #232a32;*/
    /*--datepicker-calendar-day-color-disabled: #b9bdc1;*/
    --datepicker-calendar-day-color-hover: #fff;
    /*--datepicker-calendar-day-cursor: pointer;*/
    /*--datepicker-calendar-day-cursor-disabled: default;*/
    /*--datepicker-calendar-day-display: flex;*/
    /*--datepicker-calendar-day-height: 40px;*/
    /*--datepicker-calendar-day-justify-content: center;*/
    /*--datepicker-calendar-day-font-family: var(--datepicker-font-family);*/
    /*--datepicker-calendar-day-font-size: var(--datepicker-font-size-base);*/
    /*--datepicker-calendar-day-margin-bottom: 1px;*/
    /*--datepicker-calendar-day-padding: var(--datepicker-padding-base);*/
    /*--datepicker-calendar-day-text-align: center;*/
    /*--datepicker-calendar-day-width: 40px;*/
    /*--datepicker-calendar-day-zindex-focus: 12;*/

    /**
     * Presets
     */
    --datepicker-presets-button-background-hover: #DC9096;
    --datepicker-presets-button-background-active: #B8212D;
    /*--datepicker-presets-button-color: #fff;*/
    --datepicker-presets-button-color-active: #fff;
    --datepicker-presets-button-color-hover: #fff;
    --datepicker-presets-button-color-focus: #74282E;
  }
</style>
