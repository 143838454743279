<script>
	import { createEventDispatcher } from 'svelte';
	import { Icon, Eye, EyeSlash } from 'svelte-hero-icons';

	export let id = '';
	export let label = '';
	export let value = '';
	export let placeholder = '';
	export let required = false;
	export let disabled = false;
	export let hidden = false;
	export let minlength = 0;
	export let maxlength = 255;
	export let pattern;
	export let timing = 500;
	export let classes = '';
	export let description = '';
	export let showDescription = true;
	export let autoComplete = true;

	const dispatch = createEventDispatcher();

	let showPassword = false;
	let typingTimer;
	let inputElement; // Référence à l'élément input

	function toggleType() {
		showPassword = !showPassword;
		if (inputElement) {
			inputElement.type = showPassword ? 'text' : 'password';
		}
	}

	function handleInput(event) {
		value = event.target.value;
		clearTimeout(typingTimer);
		typingTimer = setTimeout(() => {
			dispatch('input', value);
		}, timing);
	}

	function handleFocusOut() {
		dispatch('focusout');
	}
</script>

<div class="w-full" {hidden}>
	{#if label}
		<label class="font-medium" for={id}>
			{label}
		</label>
	{/if}
	<div class="relative">
		<input
			{id}
			type="password"
			class="cursor-default w-full rounded-md border-none bg-{disabled
				? 'gray-200'
				: 'white'} py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 {classes}"
			bind:value
			bind:this={inputElement}
			{placeholder}
			{required}
			{minlength}
			{maxlength}
			on:input={handleInput}
			on:focusout={handleFocusOut}
			{pattern}
			{disabled}
			autocomplete={autoComplete ? 'on' : 'off' }
		/>

		<button type="button" on:click={toggleType} class="absolute top-0 end-0 p-2.5 rounded-e-md">
			{#if showPassword}
				<Icon src={EyeSlash} class="size-5" />
			{:else}
				<Icon src={Eye} class="size-5" />
			{/if}
		</button>
	</div>
	{#if description && showDescription}
		<p class="text-sm text-gray-500">{description}</p>
	{/if}
</div>

<style>
	label {
		display: block;
		margin-bottom: 0.5rem;
	}
</style>
