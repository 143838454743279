<script>
	import NewDocVenteForm from '../../components/Form/NewDocVenteForm.svelte';
	import { docVenteTypes } from '../../store.js';

	$: statutOptions = [
		{ value: 'Saisie', label: 'Saisie' },
		{ value: 'Confirme', label: 'Confirmé' },
		{ value: 'Accepte', label: 'Accepté' },
		{ value: 'Perdu', label: 'Perdu' },
		{ value: 'Archive', label: 'Archivé' }
	];
</script>

<NewDocVenteForm docType={docVenteTypes.DEVIS} statutOptions={statutOptions} />

