<script>
	import { Route, Router } from 'svelte-routing';
	import NotFound from 'views/404.svelte';
	import AdminPanel from '../views/administration/AdminPanel.svelte';
	import ManageUser from '../views/administration/user/ManageUser.svelte';
	import ManageUserRight from '../views/administration/user-rights/ManageUserRight.svelte';
</script>

<Router url="administration">
	<Route path="/" component={AdminPanel} />
	<Route path="manage-user-right" component={ManageUserRight} />
	<Route path="manage-user-right/:id" component={ManageUserRight} />
	<Route path="manage-user" component={ManageUser} />
	<Route path="manage-user/:id" component={ManageUser} />
	<!-- TODO -->
	<Route path="*" component={NotFound} />
</Router>
