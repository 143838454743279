<script>
	import { Icon, ArrowRightCircle, ArrowLeftCircle } from 'svelte-hero-icons';
	// COMPONENTS
	import Input from '../Input/Input.svelte';
	import NumberInput from '../Input/NumberInput.svelte';
	import DoubleInput from '../Input/DoubleInput.svelte';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';
	import Steps from '../../components/Steps.svelte';
	import DateInput from '../Input/DateInput.svelte';
	import Combobox from '../Combobox/Combobox.svelte';
	import Select from '../Select/Select.svelte';
	// DOCUMENT VENTE
	import { newDevis, newBonCommande } from '../../API/API_SAGE/DocVenteQueries.js';
	import DocumentVenteCreationModel from '../../API/API_SAGE/model/DocumentVenteCreationModel.js';
	//CLIENT
	import { getClients } from '../../API/API_SAGE/ClientQueries.js';

	// SVELTE
	import { onMount } from 'svelte';
	// STORE
	import { addToast, docVenteTypes } from '../../store.js';

	// VARIABLES
	export let docType = '';
	export let statutOptions = [{ value: '', label: '' }];
	let clients = [];
	let clientOptions = [{ value: '', label: '' }];

	// MODELS
	$: docVenteBody = new DocumentVenteCreationModel();

	// DOC LIGNES
	let DOCL_RefArticle = '';
	let DOCL_Qte = 1;
	let DOCL_PU = '';

	const steps = [
		{ href: '#', label: 'En-tête' },
		{ href: '#', label: 'Ligne' },
		{ href: '#', label: 'Récapitulatif' }
	];
	$: currentStep = 0;

	// FUNCTIONS
	onMount(async () => {
		docVenteBody.ListeRefArticle = [];

		await getClients('', '', '', '', '', '', '', 1, 1000, '').then((data) => {
			clients = data.data;
			clientOptions = clients.map((client) => ({
				value: client.CT_Num,
				label: client.CT_Intitule
			}));
		});

		window.addEventListener('keydown', handleKeydown);
	});

	async function createDocVente(event) {
		event.preventDefault();
		let response;
		try {
			if (docType === docVenteTypes.DEVIS) response = await newDevis(docVenteBody).data;
			if (docType === docVenteTypes.COMMANDE) response = await newBonCommande(docVenteBody).data;

			console.log(response);

			if (response.status === 200) {
				addToast(response.detail, 'bg-green-500');
			} else {
				addToast(response.detail, 'bg-red-500');
			}
		} catch (error) {
			addToast('Erreur innatendue lors de la création du document de vente', 'bg-red-500');
		}
	}

	function addDocLigne() {
		docVenteBody = {
			...docVenteBody,
			ListeRefArticle: [
				...docVenteBody.ListeRefArticle,
				{
					Reference: DOCL_RefArticle,
					Quantite: DOCL_Qte,
					PrixUnitaire: DOCL_PU
				}
			]
		};

		DOCL_RefArticle = '';
		DOCL_PU = '';
		DOCL_Qte = 1;
	}

	function removeDocLigne(index) {
		docVenteBody = {
			...docVenteBody,
			ListeRefArticle: docVenteBody.ListeRefArticle.filter((_, i) => i !== index)
		};
	}

	function handleKeydown(event) {
		if (event.ctrlKey) {
			if (event.key === 'ArrowRight' && currentStep < steps.length - 1) {
				currentStep++;
				event.preventDefault();
			} else if (event.key === 'ArrowLeft' && currentStep > 0) {
				currentStep--;
				event.preventDefault();
			}
		}
	}
</script>

<div class="flex flex-col justify-between gap-4 pb-4 h-full p-4">
	<div class="flex flex-col space-y-1.5">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Création d'un document de vente - {docType}
		</h1>
	</div>

	<div>
		<h2 class="text-base font-semibold leading-none tracking-tight">CA</h2>
	</div>

	<!-- FORM -->
	<div class="h-max">
		<!-- Formulaire pour l'entête -->
		<form on:submit={createDocVente}>
			{#if currentStep === 0}
				<div class="space-y-6">
					<div class="grid grid-cols-3 gap-4">
						<Combobox
							id="CT_Num"
							label="N° client"
							placeholder="N° client"
							options={clientOptions}
							bind:value={docVenteBody.CtNum}
							required
						/>

						<Select options={statutOptions} bind:value={docVenteBody.Status} required />
						<Input
							id="reference"
							placeholder="REF123"
							label="Référence"
							bind:value={docVenteBody.Reference}
							required
							minlength="3"
							maxlength="15"
						/>
					</div>

					<!-- <div class="grid grid-cols-2 gap-4">
						<Input
							id="codeAffaireIntitule"
							placeholder="Intitulé du plan analytique"
							label="Intitulé du plan analytique"
							bind:value={docVenteBody.CodeAffaire.IntitulePlanAnalytique}
						/>
						<Input
							id="codeAffaireCode"
							placeholder="Code affaire"
							label="Code affaire"
							bind:value={docVenteBody.CodeAffaire.Code}
						/>
					</div> -->

					<div class="grid grid-cols-2 gap-4">
						<Input
							id="entete1"
							placeholder="Entête 1"
							label="Entête 1"
							bind:value={docVenteBody.Entete1}
						/>
						<Input
							id="entete2"
							placeholder="Entête 2"
							label="Entête 2"
							bind:value={docVenteBody.Entete2}
						/>
					</div>

					<div class="grid grid-cols-2 gap-4">
						<Input
							id="entete3"
							placeholder="Entête 3"
							label="Entête 3"
							bind:value={docVenteBody.Entete3}
						/>
						<Input
							id="entete4"
							placeholder="Entête 4"
							label="Entête 4"
							bind:value={docVenteBody.Entete4}
						/>
					</div>

					<div class="grid grid-cols-3 gap-4">
						<Input
							id="contact"
							placeholder="Nom du contact"
							label="Contact"
							bind:value={docVenteBody.Contact}
						/>
						<DateInput id="date" label="Date" bind:value={docVenteBody.Date} />

						<Input
							id="tierPayeur"
							placeholder="Tiers payeur"
							label="Tiers payeur"
							bind:value={docVenteBody.TierPayeur}
						/>
					</div>

					<div class="grid grid-cols-3 gap-4">
						<Input
							id="expediteur"
							placeholder="Expéditeur"
							label="Expéditeur"
							bind:value={docVenteBody.Expediteur}
						/>
						<Input
							id="centralAchat"
							placeholder="Central d'achat"
							label="Central d'achat"
							bind:value={docVenteBody.CentralAchat}
						/>
						<DateInput
							id="DO_DateLivr"
							label="Date de livraison"
							bind:value={docVenteBody.DO_DateLivr}
						/>
					</div>

					<div class="grid grid-cols-2 gap-4">
						<NumberInput
							id="DO_ValFrais"
							placeholder="0.00"
							label="Valeur des frais"
							bind:value={docVenteBody.DO_ValFrais}
							min="0"
							step="0.01"
						/>
						<NumberInput
							id="DE_No"
							placeholder="0"
							label="Numéro DE"
							bind:value={docVenteBody.DE_No}
							min="0"
							step="1"
						/>
					</div>
				</div>
			{/if}

			<!-- Formulaire pour les lignes -->
			{#if currentStep === 1}
				<div class="p-6 space-y-4">
					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2">
							<Input
								id="AR_Ref"
								placeholder="BAAR01"
								label="Référence article"
								bind:value={DOCL_RefArticle}
								required
								minlength="3"
								maxlength="10"
							/>
						</div>
						<div class="space-y-2">
							<DoubleInput
								id="pu"
								placeholder="10.00"
								label="Prix unitaire"
								bind:value={DOCL_PU}
								type="number"
								min="0.01"
								step="0.01"
								required
							/>
						</div>
					</div>

					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2">
							<NumberInput
								id="qte"
								placeholder="1"
								label="Quantité"
								bind:value={DOCL_Qte}
								type="number"
								min="1"
								required
							/>
						</div>
					</div>

					<div>
						<ButtonPrimary on:click={addDocLigne} type="button">Ajouter la ligne</ButtonPrimary>
					</div>

					<!-- Liste des lignes ajoutées -->
					<div class="mt-6">
						{#if docVenteBody.ListeRefArticle.length > 0}
							<table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
								<thead class="bg-gray-50">
									<tr>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Référence</th
										>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Quantité</th
										>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Prix Unitaire</th
										>
										<th
											class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Actions</th
										>
									</tr>
								</thead>
								<tbody class="bg-white divide-y divide-gray-200">
									{#each docVenteBody.ListeRefArticle as ligne, index}
										<tr>
											<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
												>{ligne.Reference}</td
											>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
												>{ligne.Quantite}</td
											>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
												>{ligne.PrixUnitaire} €</td
											>
											<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
												<button
													class="text-red-600 hover:text-red-900"
													on:click={() => removeDocLigne(index)}>Supprimer</button
												>
											</td>
										</tr>
									{/each}
								</tbody>
							</table>
						{:else}
							<p class="text-gray-500">Aucune ligne ajoutée pour le moment.</p>
						{/if}
					</div>
				</div>
			{/if}

			<!-- Formulaire pour le récapitulatif -->
			{#if currentStep === 2}
				<div class="flex items-center w-full justify-center">
					<ButtonPrimary px="px-6" py="py-2" text="text-lg" type="submit">Créer</ButtonPrimary>
				</div>
			{/if}
		</form>
	</div>

	<div class="flex flex-col gap-4 pl-4 pr-4">
		<div class="flex justify-between mt-8">
			<button
				class="text-primary rounded-full shadow-lg hover:text-primaryDark transition duration-300 ease-in-out"
				on:click={() => currentStep--}
				disabled={currentStep === 0}
			>
				<Icon src={ArrowLeftCircle} solid class="h-10 w-10" />
			</button>
			<button
				class="text-primary rounded-full shadow-lg hover:text-primaryDark transition duration-300 ease-in-out"
				on:click={() => currentStep++}
				disabled={currentStep === steps.length - 1}
			>
				<Icon src={ArrowRightCircle} solid class="h-10 w-10" />
			</button>
		</div>

		<Steps {steps} {currentStep} />
	</div>
</div>
