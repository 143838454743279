import { fetchData } from './utils.js';

/**
 * 
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get all families
 * 
 */
export async function getFamilles(SearchFields, SearchValues) {

    let queryParams = {
        SearchFields: SearchFields,
        SearchValues: SearchValues
    };

    return await fetchData('/famille/LF', queryParams);
}