<script>
	import { onMount } from 'svelte';
	import { get } from '../API/Backend/api';

	import { Bar } from 'svelte-chartjs';
  import { Chart, registerables } from 'chart.js';
  Chart.register(...registerables);

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(199, 199, 199, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(199, 199, 199, 1)'
        ],
        borderWidth: 1
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart'
      }
    }
  };

	onMount(() => {
		// Fetch the data from the backend
		get('/users/rights/2')
			.then((data) => {
				console.log(data);
			})
			.catch((error) => {
				console.log(error);
			});
	});


</script>

<a href="mailto:user@example.com?subject=Subject&body=message%20goes%20here" >Example of Mail to</a>

<Bar {data} {options} />
