<script>
	import { onMount } from 'svelte';

	export let title = '';
	export let classes = '';
	export let noTitle = false;
	export let padding = 'p-6';
	export let background = 'bg-white';

	onMount(() => {
		let listClasses = classes.split(' ');

		if (listClasses.some((c) => c.includes('bg'))) {
			background = '';
			listClasses.forEach((c) => {
				if (c.includes('bg')) {
					background = background ? ` ${c}` : c;
				}
			});
		}

		if (listClasses.some((c) => c.startsWith('p'))) {
			padding = '';
			listClasses.forEach((c) => {
				if (c.startsWith('p')) {
					padding = padding ? ` ${c}` : c;
				}
			});
		}
	})
</script>

<div class="block rounded-lg {background} shadow-secondary-1 shadow-md {classes}">
	{#if !noTitle}
		<div
			class="border-b-2 border-neutral-100 px-6 py-3  font-medium">
			<slot name="title">{title}</slot>
		</div>
	{/if}
	<div class="{padding}">
		<slot></slot>
	</div>
</div>