<script>
	// Components
	import Input from '../../components/Input/Input.svelte';
	import DoubleInput from '../../components/Input/DoubleInput.svelte';
	import NumberInput from '../../components/Input/NumberInput.svelte';
	import ButtonPrimary from '../../components/Button/ButtonPrimary.svelte';
	import Select from '../../components/Select/Select.svelte';
	import Combobox from '../../components/Combobox/Combobox.svelte';

	// Stock
	import { newEntreeStock, newSortieStock } from '../../API/API_SAGE/DocStockQueries';
	import MouvementEntreeNewModel from '../../API/API_SAGE/model/MouvementEntreeNewModel';

	// Depots
	import { getDepots } from '../../API/API_SAGE/DepotQueries';

	// Store
	import { addToast } from '../../store.js';
	import { onMount } from 'svelte';

	/**
	 * @type {{ value: string, label: string }[]}
	 */
	let depotOptions = [{ value: '', label: '' }];

	onMount(async () => {
		try {

			// Get depots
			const depots = await getDepots().then((res) => res.data);
			// Construct options			
			depotOptions = depots.map((depot) => ({
				value: depot.DE_Intitule,
				label: depot.DE_Intitule
			}));
			mouvementEntreeBody.IntituleDepotStockage = depotOptions[0].value;

		} catch (error) {
			addToast(`Erreur lors du chargement des dépôts: ${error.message}`, 'bg-red-500');
		}
	});
	// Variables

	$: mouvementOptions = [
		{ value: 1, label: 'Entrée' },
		{ value: 2, label: 'Sortie' }
	];
	let mouvement = 1;
	let mouvementEntreeBody = new MouvementEntreeNewModel();

	let Reference = '';
	let Quantite = 0;
	let PrixUnitaire = '';
	let No_SerieLot = '';
	let InfoLibres = [];

	function addStockLigne() {

		// Check if the reference is empty
		if (Reference === '') {
			addToast('La référence article est obligatoire', 'bg-red-500');
			return;
		}
		if(Quantite === 0){
			addToast('La quantité doit être supérieure à 0', 'bg-red-500');
			return;
		}


		mouvementEntreeBody = {
			...mouvementEntreeBody,
			ListeRefArticle: [
				...mouvementEntreeBody.ListeRefArticle,
				{
					Reference: Reference,
					Quantite: Quantite,
					PrixUnitaire: PrixUnitaire,
					No_SerieLot: No_SerieLot,
					InfoLibres: InfoLibres
				}
			]
		};

		Reference = '';
		Quantite = 0;
		PrixUnitaire = '';
		No_SerieLot = '';
		InfoLibres = '';
	}
	function removeDocLigne(index) {
		mouvementEntreeBody = {
			...mouvementEntreeBody,
			ListeRefArticle: mouvementEntreeBody.ListeRefArticle.filter((_, i) => i !== index)
		};
	}

	// Functions

	async function createMouvement(event) {
		event.preventDefault();

		try {
			let response =
				mouvement == 1
					? await newEntreeStock(mouvementEntreeBody)
					: await newSortieStock(mouvementEntreeBody);

			if (response.data.status == 200) {
				addToast(response.data.detail, 'bg-green-500');
				resetForm();
				return;
			}

			addToast(response.data.detail, 'bg-red-500');
		} catch (error) {
			addToast(`Erreur innatendue ${error}`, 'bg-red-500');
		}
	}

	function resetForm() {
		mouvementEntreeBody = new MouvementEntreeNewModel();
		mouvementEntreeBody.IntituleDepotStockage = depotOptions[0].value;
	}
</script>

<div class="text-card-foreground pb-4">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Mouvement de stock
		</h1>

		<Select
			id="Mouvement"
			label="Type de mouvement"
			options={mouvementOptions}
			bind:value={mouvement}
			width="w-1/3"
		></Select>
	</div>

	<form class="p-6 space-y-4" on:submit={createMouvement}>
		<div class="flex items-baseline justify-between">
			<h2
				class="whitespace
            -nowrap text-xl font-semibold leading-none tracking-tight"
			>
				En-tête
			</h2>

			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-3 gap-4">
			<Combobox
				id="IntituleDepotStockage"
				label="Dépôt de stockage"
				placeholder="Dépôt de stockage"
				options={depotOptions}
				bind:value={mouvementEntreeBody.IntituleDepotStockage}
				isSearchable={true}
				required
			></Combobox>
			<Input
				id="Reference"
				placeholder="Reference"
				label="Reference"
				bind:value={mouvementEntreeBody.Reference}
				maxlength="69"
			/>

			<div class="flex items-baseline col-span-3">
				<h2
					class="whitespace
                    -nowrap text-xl font-semibold leading-none tracking-tight"
				>
					Liste des articles
				</h2>

				<hr class="flex-grow ml-4 border-t border-gray-300" />
			</div>

			<div class="col-span-3 flex justify-evenly gap-4">
				<Input
					id="Reference"
					placeholder="Référence Article"
					label="Référence Article"
					bind:value={Reference}
				/>
				<NumberInput
					id="Quantite"
					placeholder="Quantité"
					label="Quantité"
					min=0
					bind:value={Quantite}
					required
				/>
				<DoubleInput
					id="PrixUnitaire"
					placeholder="Prix Unitaire"
					label="Prix Unitaire"
					bind:value={PrixUnitaire}
				/>
				<Input
					id="No_SerieLot"
					placeholder="No_SerieLot"
					label="No_SerieLot"
					bind:value={No_SerieLot}
					maxlength="69"
				/>
				<Input
					id="InfoLibres"
					placeholder="InfoLibres"
					label="InfoLibres"
					bind:value={InfoLibres}
					maxlength="69"
				/>

				<div class="flex items-center">
					<ButtonPrimary px='px-3' py="py-2" on:click={addStockLigne}>Ajouter Ligne</ButtonPrimary>
				</div>
			</div>
		</div>

		<!-- Liste des lignes ajoutées -->
		<div class="mt-6 col-span-3">
			{#if mouvementEntreeBody.ListeRefArticle.length > 0}
				<table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
					<thead class="bg-gray-50">
						<tr>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>Référence</th
							>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>Quantité</th
							>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>Prix Unitaire</th
							>
							<th
								class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
								>Actions</th
							>
						</tr>
					</thead>
					<tbody class="bg-white divide-y divide-gray-200">
						{#each mouvementEntreeBody.ListeRefArticle as ligne, index}
							<tr>
								<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
									>{ligne.Reference}</td
								>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ligne.Quantite}</td>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
									>{ligne.PrixUnitaire} €</td
								>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button
										class="text-red-600 hover:text-red-900"
										type="button"
										on:click={() => removeDocLigne(index)}>Supprimer</button
									>
								</td>
							</tr>
						{/each}
					</tbody>
				</table>
			{:else}
				<p class="text-gray-500">Aucune ligne ajoutée pour le moment.</p>
			{/if}
		</div>

		<div class="flex justify-center mt-6">
			<ButtonPrimary px='px-4' py="py-2" type="submit">Créer le mouvement</ButtonPrimary>
		</div>
	</form>
</div>
