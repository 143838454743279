<script>
	import NewDocVenteForm from '../../components/Form/NewDocVenteForm.svelte';
	import { docVenteTypes } from '../../store.js';


	$: statutOptions = [
		{ value: 'Saisie', label: 'Saisie' },
		{ value: 'Confirme', label: 'Confirmé' },
		{ value: 'A preparer', label: 'A préparer' }
	];
</script>

<NewDocVenteForm docType={docVenteTypes.COMMANDE} statutOptions={statutOptions} />

