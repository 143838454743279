import { getMaintenanceStatus } from '../API/Backend/maintenance.js';
import { siteStatus, isConnected } from '../store.js';
import { connectedUserHasRight } from '../API/Backend/api.js';
import { Edit } from '../API/Backend/rights/permissions.js';

export async function checkMaintenanceStatus() {
	// Ne vérifier que si l'utilisateur est connecté
	let isUserConnected;
	isConnected.subscribe(value => isUserConnected = value)(); // Exécuter immédiatement pour se désabonner
	if (!isUserConnected) return;

	try {
		const status = await getMaintenanceStatus();
			siteStatus.set({
			isInMaintenance: status.isInMaintenance,
			message: status.message,
			endTime: status.endTime
		});

		// Récupérer le statut mis à jour avec subscribe()
		let currentStatus;
		siteStatus.subscribe(value => currentStatus = value)();

		if (currentStatus.isInMaintenance && !connectedUserHasRight('admin', Edit)) {
			if (!window.location.pathname.includes('maintenance')) {
				window.location.href = '/maintenance';
			}
		}
	} catch (error) {
		console.error('Erreur lors de la vérification du statut de maintenance:', error);
	}
}
