<script>

    import { Icon, ChevronDown } from 'svelte-hero-icons';
    import { createEventDispatcher, onMount } from 'svelte';

    export let options = [];
    export let value = '';
    export let label = 'Combobox';
    export let placeholder = 'Search...';
    export let width = 'w-full'; // Default width
    let isOpen = false;
    let searchTerm = ''; // Term used for filtering
    let filteredOptions = options;
    let firstMount = true;
    const dispatch = createEventDispatcher();

    // Get the selected option
    $: selectedOption = options.find((option) => option.value === value) || options[0];

    // Filter options based on the search term
    $: filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Toggle the dropdown visibility
    function toggleDropdown() {
        isOpen = !isOpen;
    }

    // Handle selecting an option
    function selectOption(option) {
        value = option.value;
        searchTerm = ''; // Clear the search input but keep the selected value
        isOpen = false;
        dispatch('change', value);
    }

    let comboboxElement;

    onMount(() => {

        // Handle click outside
        const handleClickOutside = (event) => {
            if (comboboxElement && !comboboxElement.contains(event.target)) {
                isOpen = false;
            }
        };
        document.addEventListener('click', handleClickOutside);
    });
</script>

<div class={width} bind:this={comboboxElement}>
    <label for="combobox" class="block text-sm font-medium leading-6 text-gray-900">{label}</label>
    <div class="relative mt-2">
        <!-- Input field shows the selected option's label but does not affect search -->
        <input
            id="combobox"
            type="text"
            class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            placeholder={placeholder}
            value={firstMount ? selectedOption.label : searchTerm} 
            on:input={(e) => { searchTerm = e.target.value; isOpen = true }}
            aria-controls="options"
            aria-expanded={isOpen}
            role="combobox"
        />

        <!-- Button to toggle the dropdown -->
        <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none" on:click={toggleDropdown}>
            <Icon src={ChevronDown} class="h-5 w-5 text-gray-400" />
        </button>

        <!-- Dropdown options -->
        {#if isOpen}
            <ul id="options" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" role="listbox">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                {#each filteredOptions as option}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                    <li
                        class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 hover:bg-primary hover:text-white"
                        on:click={() => selectOption(option)}
                    >
                        <span class="block truncate {option.value === value ? 'font-semibold' : 'font-normal'}">{option.label}</span>
                        {#if option.value === value}
                            <span class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-primary">
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        {/if}
                    </li>
                {/each}
            </ul>
        {/if}
    </div>
</div>
