<script>
	// Components
	import Input from '../Input/Input.svelte';
	import TelInput from '../Input/TelInput.svelte';
	import MailInput from '../Input/MailInput.svelte';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';

	// Client
	import ClientNewModel from '../../API/API_SAGE/model/ClientNewModel';
	import { newClient, newClientProspect } from '../../API/API_SAGE/ClientQueries';

	// Store
	import { addToast } from '../../store.js';

	// Variables
	let clientBody = new ClientNewModel();
	let prospect = false;

	// Functions
	async function createClient(event) {
		event.preventDefault();

		try {
			let response = prospect ? await newClientProspect(clientBody) : await newClient(clientBody);

			if (response.data.status == 200) {
				addToast(response.data.detail, 'bg-green-500');
				resetForm();
				return;
			}

			addToast(response.data.detail, 'bg-red-500');
		} catch (error) {
			addToast(`Erreur innatendue ${error}`, 'bg-red-500');
		}
	}

	async function resetForm() {
		clientBody = new ClientNewModel();
		prospect = false;
	}
</script>

<div class="text-card-foreground pb-4">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Créer un client
		</h1>
	</div>

	<!-- Wrap everything in a form -->
	<form class="p-6 space-y-4" on:submit={createClient}>
		<div class="flex items-baseline justify-between">
			<h2
				class="whitespace
            -nowrap text-xl font-semibold leading-none tracking-tight"
			>
				Identification
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-3 gap-4">
			<Input
				id="CT_Num"
				placeholder="CT_Num"
				label="CT_Num"
				bind:value={clientBody.CT_Num}
				required
				minlength="1"
				maxlength="18"
			/>
			<Input
				id="Intitule"
				placeholder="Intitule"
				label="Intitule"
				bind:value={clientBody.Intitule}
				required
				maxlength="69"
			/>

			<div class="relative flex items-center">
				<div class="flex h-6 items-center">
					<input
						id="prospect"
						aria-describedby="prospect-description"
						name="prospect"
						type="checkbox"
						class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
						bind:checked={prospect}
					/>
				</div>
				<div class="ml-3 text-sm leading-6">
					<label for="prospect" class="font-medium">Prospect</label>
					<p id="prospect-description" class="text-gray-500">Gérer en tant que prospect</p>
				</div>
			</div>

			<div class="w-full col-span-2 {prospect ? 'hidden' : ''}">
				<Input
					id="CompteCollectif"
					placeholder="CompteCollectif"
					label="CompteCollectif"
					maxlength="20"
					bind:value={clientBody.CompteCollectif}
					required={!prospect}
				/>
			</div>
			<Input
				id="Qualite"
				placeholder="Qualite"
				label="Qualite"
				maxlength="17"
				bind:value={clientBody.Qualite}
			/>

			<div class="col-span-3 flex items-baseline justify-between">
				<h2
					class="whitespace
                -nowrap text-xl font-semibold leading-none tracking-tight"
				>
					Coordonnées
				</h2>
				<hr class="flex-grow ml-4 border-t border-gray-300" />
			</div>

			<Input
				id="Adresse"
				placeholder="Adresse"
				label="Adresse"
				maxlength="35"
				bind:value={clientBody.Adresse}
			/>
			<Input
				id="Complement"
				placeholder="Complement"
				label="Complement"
				maxlength="35"
				bind:value={clientBody.Complement}
			/>
			<Input
				id="CodePostal"
				placeholder="CodePostal"
				label="CodePostal"
				maxlength="10"
				bind:value={clientBody.CodePostal}
			/>
			<Input
				id="Ville"
				placeholder="Ville"
				label="Ville"
				maxlength="35"
				bind:value={clientBody.Ville}
			/>
			<Input
				id="Region"
				placeholder="Region"
				label="Region"
				maxlength="25"
				bind:value={clientBody.Region}
			/>

			<div class="col-span-3 flex items-baseline justify-between">
				<h2
					class="whitespace
                -nowrap text-xl font-semibold leading-none tracking-tight"
				>
					Télécommunication
				</h2>
				<hr class="flex-grow ml-4 border-t border-gray-300" />
			</div>

			<TelInput
				id="Telephone"
				placeholder="Telephone"
				label="Telephone"
				maxlength="11"
				bind:value={clientBody.Telephone}
			/>
			<Input
				id="Telecopie"
				placeholder="Telecopie"
				label="Telecopie"
				maxlength="11"
				bind:value={clientBody.Telecopie}
			/>
			<MailInput
				id="Mail"
				placeholder="Mail"
				label="Mail"
				maxlength="69"
				bind:value={clientBody.Mail}
			/>
			<Input
				id="Website"
				placeholder="Website"
				label="Website"
				maxlength="69"
				bind:value={clientBody.Website}
			/>

			<div class="col-span-3 flex items-baseline justify-between">
				<h2
					class="whitespace
                -nowrap text-xl font-semibold leading-none tracking-tight"
				>
					Immatriculation
				</h2>
				<hr class="flex-grow ml-4 border-t border-gray-300" />
			</div>

			<Input
				id="Siret"
				placeholder="Siret"
				label="Siret"
				maxlength="14"
				bind:value={clientBody.Siret}
			/>
			<Input id="Naf" placeholder="Naf" label="Naf" maxlength="6" bind:value={clientBody.Naf} />
			<Input id="Tva" placeholder="Tva" label="Tva" maxlength="25" bind:value={clientBody.Tva} />
		</div>

		<div class="flex justify-center col-span-3">
			<ButtonPrimary py="py-2" px="px-5" type="submit" text="">Créer</ButtonPrimary>
		</div>
	</form>
</div>
