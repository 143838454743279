import App from './App.svelte';
import './global.css';

document.addEventListener('DOMContentLoaded', () => {
  new App({
    target: document.body,
    props: {
      // pass all required props here
    }
  });
});
