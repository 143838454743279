

/**
 * @class Model
 * @description Représentation d'une information libre dans Sage
 * @property {string} CB_Name - 
 * @property {string} CB_Type - 
 * @property {number} CB_Len - 
 * @property {number} EstCalculee - 
 */
class InfoLibreResponse {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.CB_Name = null;
    /** @type {string} */
    this.CB_Type = null;
    /** @type {number} */
    this.CB_Len = null;
    /** @type {number} */
    this.EstCalculee = null;
  }
}

export default InfoLibreResponse;
