import { writable } from 'svelte/store';

// SITE STATUS
export const siteStatus = writable({
	isInMaintenance: false,
	message: '',
	endTime: null
});

// USER
export const isConnected = writable(false);

// Layout - Sidebar and Header
export const showLayout = writable(false);

const excludedRoutes = ['/auth'];

export function toggleLayout(pathname) {
	if (excludedRoutes.includes(pathname)) {
		showLayout.set(false);
	} else {
		showLayout.set(true);
	}
}

// SIDE BAR
export const sidebarOpen = writable(true);

// TOASTS
export const toasts = writable([]);

export function addToast(message, colorClass) {
	const id = Date.now();
	toasts.update((t) => [...t, { id, message, colorClass }]);
}

export function removeToast(id) {
	toasts.update((t) => t.filter((toast) => toast.id !== id));
}

// STORES
export const drawerState = writable({
	isOpen: false,
	data: null
});

export function openDrawer(data) {
	if (!('data' in data)) {
		data['data'] = data;
	}
	if (!('id' in data)) {
		data['id'] = 'drawer';
	}
	drawerState.set({ isOpen: true, ...data });
}

export function closeDrawer() {
	drawerState.set({ isOpen: false, data: null });
}

// UTILS
export function debounce(func, wait) {
	let timeout;
	return function (...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

// Dictionnaire des types de documents de vente
export const DOCVENTETYPES = {
	DEVIS: 'Devis',
	COMMANDE: 'Bon de Commande',
	PREPA_LIVRAISON: 'Préparation de livraison',
	BON_LIVRAISON: 'Bon de Livraison',
	BON_RETOUR: 'Bon de Retour',
	BON_AVOIR: "Bon d'avoir financier",
	FACTURE: 'Facture'
};

export const TYPEDOCDEST = {
	FA: 'facture',
	BC: 'Bon de Commande',
	BL: 'Bon de Livraison',
	PL: 'Préparation de livraison'
};

// Dictionnaire des types de comptabilité
export const COMPTATYPES = {
	Vente: 0,
	Achat: 1,
	Stock: 2
};
