<script>
	// ICONS
	import {
		Icon,
		ChevronUp,
		ChevronDown,
		ChevronUpDown,
		ArrowLeft,
		ArrowRight,
		LockClosed
	} from 'svelte-hero-icons';

	// STORES
	import { writable } from 'svelte/store';
	import { addToast, debounce } from '../store';

	//SVELTE
	import { onMount } from 'svelte';
	import { createEventDispatcher } from 'svelte';
	import { onDestroy } from 'svelte';

	// IMPORT COMPONENTS
	import Select from './Select/Select.svelte';
	import LoadingDots from './Loading/LoadingDots.svelte';
	import Tooltip from './Tooltip/Tooltip.svelte';
	import DropdownButtonCheckbox from './Dropdowns/DropdownButtonCheckbox.svelte';
	import DropdownDots from './Dropdowns/DropdownDots.svelte';
	import Input from './Input/Input.svelte';
	import {
		chunkArray,
		formatDateForDisplay,
		formatDateForInput,
		formatToCurrency,
		formatToDate,
		mergeWithoutDuplicates,
		sortObjectListByKey
	} from '../utils.js';
	import CheckBox from './Input/CheckBox.svelte';
	import DateInput from './Input/DateInput.svelte';

	// EXPORT VARIABLES
	export let getQuery; // Function to fetch data
	export let fromList; // List with data
	export let columns = [];
	export let rowsPerPage = 1000;
	export let rowCondition = '';
	export let actions;
	export let bulkActions;
	export let unselectedColumnsKeys = [];
	export let dateMax;
	export let canGroupBy = true;
	export let canSort = true;
	export let canSearch = true;
	export let canSelectColumns = true;

	// CONSTANTS
	const pageRowOptions = [
		{ value: 10, label: '10' },
		{ value: 25, label: '25' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' }
	];
	const groupByOptions = [
		{ value: '', label: 'Aucun' },
		...columns.map((col) => ({ value: col.key, label: col.title }))
	];
	const refreshHandler = () => refresh();
	const dispatch = createEventDispatcher();

	// VARIABLES
	let data = [];

	let currentPage = 1;
	let totalPages;
	let hasNext;
	let hasPrevious;
	let totalCount = 0;

	let searchKey = []; // Pour garder la trace des colonnes de recherche
	let searchValue = []; // Pour garder la trace des valeurs de recherche de chaque colonne
	let selectedColumns = writable([]);
	let groupByColumn = null;
	let sortOrder = writable('ASC'); // Pour garder la trace de l'état de tri de chaque colonne
	let orderBy = writable([]);
	let groupedData = [];

	let searchInputs = [];
	let searchInputsVisible = [];
	let searchInputsButtonVisible = [];
	let headersSearch = [];

	// Ajoutez ces variables
	let hoveredRow = null;
	let mouseX = 0;
	let mouseY = 0;
	let tooltipTimer = null;
	let showTooltip = false;

	let isLoading = true;
	let selectedElements = [];
	let dateDisabled = [];

	let currentSearches = {};

	$: pageSelected = data.length > 0 && data.every(item => selectedElements.some(selected => JSON.stringify(item) === JSON.stringify(selected)));
	$: {
		if (Array.isArray(data) && data.length !== 0) {
			// Grouper les données
			if (!groupByColumn) {
				groupedData = [...data];
			} else {
				groupedData = [...data].reduce((acc, row) => {
					const key = getValueByPath(row, groupByColumn); // Utiliser getValueByPath pour obtenir la valeur du groupe
					if (!acc[key]) acc[key] = [];
					acc[key].push(row);
					return acc;
				}, {});
			}
		}
	}
	// FUNCTIONS

	onMount(async () => {
		await promiseQuery();
		selectedColumns.set(
			columns.filter((col) => !unselectedColumnsKeys.includes(col.key)).map((col) => col.key)
		);
		checkSearchInputSize();
		window.addEventListener('refresh', refreshHandler);
		window.addEventListener('resize', checkSearchInputSize);
		if (actions) {
			selectedColumns.update((value) => value.concat('action'));
		}
		if (dateMax) {
			let date = new Date(dateMax);
			dateDisabled.push(`${formatDateForDisplay(date, 'en-EN')}:${formatDateForDisplay(new Date(), 'en-EN')}`);
			date.setDate(date.getDate() - 1);
		}
	});

	async function refresh(clearSelection = false) {
		await promiseQuery();
		if (clearSelection) {
			selectedElements = [];
		}
	}

	async function promiseQuery() {
		isLoading = true;
		if (fromList) {
			let list = fromList;
			searchKey.forEach((key, _) => {
				list = list.filter((item) => {
					const value = getValueByPath(item, key).toString();
					return value && value.toLowerCase().includes(searchValue[_].toLowerCase());
				});
			});
			if (list.length === 0) {
				data = [];
				totalPages = 0;
				hasNext = false;
				hasPrevious = false;
				isLoading = false;
				return;
			}

			$orderBy.reverse().forEach((key) => {
				list = sortObjectListByKey(list, key, $sortOrder);
			});
			list = chunkArray(list, rowsPerPage);
			data = list[currentPage - 1];
			totalPages = list.length;
			hasNext = currentPage < totalPages;
			hasPrevious = currentPage > 1;
			isLoading = false;
			return;
		}
		try {
			let tempSearchVal = [...searchValue];

			let tempSearchKey = searchKey.map(key => {
				const column = columns.find(col => col.key === key || col.searchKey === key);
				if (column && column.type === 'date') {
					const index = searchKey.indexOf(key);
					if (tempSearchVal[index]) {
						tempSearchVal[index] = 'BETWEEN ' + Object.values(tempSearchVal[index])
							.map(date => formatDateForInput(date))
							.join(' AND ');
					} else {
						delete tempSearchVal[index];
						delete searchKey[index];
					}
				}
				return column?.searchKey || key; // Utiliser searchKey s'il existe
			});

			const searchKeysString = tempSearchKey
				.filter(item => item)
				.join(',')
				.replace('Famille', 'F_Famille')
				.replace('Representant', 'F_COMPTET');
			const searchValuesString = tempSearchVal.filter(item => item).join(',');

			let queryData = await getQuery(
				'',
				'',
				'',
				'',
				searchKeysString,
				searchValuesString,
				$sortOrder,
				currentPage,
				rowsPerPage,
				$orderBy.join(',')
			);

			if (queryData.data.status === 404) {
				addToast(queryData.data.detail, 'bg-red-500');
				data = [];
				totalPages = 0;
				hasNext = false;
				hasPrevious = false;
				isLoading = false;
				return;
			}

			data = queryData.data;

			if (queryData.pagination) {
				totalCount = queryData.pagination.TotalCount;
				currentPage = queryData.pagination.PageNumber;
				totalPages = queryData.pagination.TotalPages;
				hasNext = queryData.pagination.HasNext;
				hasPrevious = queryData.pagination.HasPrevious;
			}
		} catch (error) {
			addToast(error, 'bg-red-500');
		} finally {
			isLoading = false;
		}
	}

	async function selectAll() {
		isLoading = true;
		if (fromList) {
			selectedElements = fromList;
			isLoading = false;
			return;
		}
		selectedElements = [];
		let page = 0;
		let nextPage = false;
		do {
			try {
				const searchKeysString = searchKey
					.join(',')
					.replace('Famille', 'F_Famille')
					.replace('Representant', 'F_COMPTET');
				const searchValuesString = searchValue.join(',');

				let queryData = await getQuery(
					'',
					'',
					'',
					'',
					searchKeysString,
					searchValuesString,
					$sortOrder,
					page,
					50,
					$orderBy.join(',')
				);

				if (queryData.data.status === 404) {
					addToast(queryData.data.detail, 'bg-red-500');
					data = [];
					totalPages = 0;
					hasNext = false;
					hasPrevious = false;
					isLoading = false;
					return;
				}

				selectedElements = mergeWithoutDuplicates(selectedElements, queryData.data);
				if (queryData.pagination) {
					nextPage = queryData.pagination.HasNext;
				}
				searchValue = searchValuesString.split(',').filter((value) => value !== '');
			} catch (error) {
				addToast(error, 'bg-red-500');
			} finally {
				page++;
			}
		} while (nextPage);
		await refresh();
		isLoading = false;
	}

	async function deselectAll() {
		selectedElements = [];
		await refresh();
	}

	function selectCurrentPage(checked) {
		if (checked) {
			selectedElements = mergeWithoutDuplicates(selectedElements, data);
		} else {
			selectedElements = selectedElements.filter((element) => !data.some(datum => JSON.stringify(element) === JSON.stringify(datum)));
		}
	}

	function goToPage(page) {
		currentPage = page;
		promiseQuery();
	}

	// Fonction pour obtenir la valeur d'un objet par un chemin
	function getValueByPath(object, path) {
		return path.split('.').reduce((o, p) => o && o[p], object);
	}

	function openSearchInput(index) {
		searchInputsVisible[index] = !searchInputsVisible[index];
		searchInputsVisible[index] ? searchInputs[index].focus() : '';
	}

	function checkSearchInputSize() {
		if (searchInputs.length === 0) {
			searchInputs = columns.map((col) => col.key);
			headersSearch = columns.map((col) => col.key);
		}
		headersSearch.forEach((div, index) => {
			if (div) {
				searchInputsVisible[index] = div.clientWidth > 100;
			}
			searchInputsButtonVisible[index] = !searchInputsVisible[index];
		});
	}

	const handleSearchInput = debounce((value, columnKey) => {
		const column = columns.find(col => col.key === columnKey);
		const searchKeyToUse = column.searchKey || columnKey;

		// Mettre à jour la recherche pour cette colonne
		if (value) {
			currentSearches[columnKey] = value;

			const searchIndex = searchKey.indexOf(searchKeyToUse);
			if (searchIndex === -1) {
				searchKey.push(searchKeyToUse);
				searchValue.push(value);
			} else {
				searchValue[searchIndex] = value;
			}
		} else {
			// Supprimer la recherche si la valeur est vide
			delete currentSearches[columnKey];

			const searchIndex = searchKey.indexOf(searchKeyToUse);
			if (searchIndex !== -1) {
				searchKey.splice(searchIndex, 1);
				searchValue.splice(searchIndex, 1);
			}
		}

		currentPage = 1;
		promiseQuery();
	}, 300);

	function getSearchValue(columnKey) {
		return currentSearches[columnKey] || '';
	}

	function handlePageChange(page) {
		currentPage = page;
		promiseQuery();
	}

	function handleSort(columnKey) {
		const column = columns.find(col => col.key === columnKey);
		const sortKeyToUse = column?.searchKey || columnKey;

		if (sortKeyToUse.includes('.')) {
			addToast('Ce champ ne peut pas être trié', 'bg-yellow-500');
			return;
		}

		orderBy.update((order) => {
			const index = order.indexOf(sortKeyToUse);
			if (index === -1) {
				// Ajouter la colonne au tri
				return [...order, sortKeyToUse];
			} else if ($sortOrder === 'DESC') {
				// Supprimer la colonne du tri
				return order.filter((col) => col !== sortKeyToUse);
			}
			// Sinon, ne rien changer
			return order;
		});

		sortOrder.update((current) => {
			if (!$orderBy.includes(sortKeyToUse)) return 'ASC';
			return current === 'ASC' ? 'DESC' : 'ASC';
		});

		promiseQuery();
	}

	function handleRowsPerPageChange(event) {
		rowsPerPage = event.detail;
		currentPage = 1;
		promiseQuery();
	}

	function handleRowClick(row) {
		dispatch('rowClick', row);
	}

	function handleAction(action, row) {
		dispatch(action, row);
	}

	function handleBulkAction(action, data) {
		dispatch(action, data);
		refresh(true);
	}

	function handleMouseEnter(event, row) {
		clearTimeout(tooltipTimer);
		hoveredRow = row;
		mouseX = event.clientX;
		mouseY = event.clientY;
		tooltipTimer = setTimeout(() => {
			showTooltip = true;
		}, 1000);
	}

	function handleMouseMove(event) {
		mouseX = event.clientX;
		mouseY = event.clientY;
	}

	function handleMouseLeave() {
		clearTimeout(tooltipTimer);
		hoveredRow = null;
		showTooltip = false;
	}

	onDestroy(() => {
		clearTimeout(tooltipTimer);
		window.removeEventListener('refresh', refreshHandler);
		window.removeEventListener('resize', checkSearchInputSize);
	});
</script>

{#if isLoading}
	<div class="flex justify-center items-center h-64">
		<LoadingDots />
	</div>
{:else}
	<div class="p-4">
		<div class="flex items-end mb-4 space-x-4">
			{#if canSelectColumns}
				<!-- Dropdown pour sélectionner les colonnes à afficher -->
				<div class="relative inline-block">
					<DropdownButtonCheckbox
						id="dropdownSearchButton"
						title="Sélectionner les colonnes"
						elements={actions ? columns.concat({ title: 'Actions', key: 'action' }) : columns}
						selectedElements={selectedColumns}
					/>
				</div>
			{/if}

			{#if canGroupBy}
				<!-- Sélecteur pour choisir le champs par lequel regrouper -->
				<Select
					width="w-40"
					options={groupByOptions}
					bind:value={groupByColumn}
					label="Regrouper par"
				/>
			{/if}

			<!-- Sélecteur pour le nombre de lignes par page -->
			<Select
				width="w-18"
				options={pageRowOptions}
				bind:value={rowsPerPage}
				on:change={handleRowsPerPageChange}
				label="Lignes par page"
			/>

			{#if bulkActions && bulkActions.length > 0}
				<div class="flex flex-col">
					<label for="bulkActions" id="bulkActions-label" class="block text-sm font-medium leading-6 text-gray-900">
						Actions :
					</label>
					<div>
						{#if bulkActions.length > 3}
							<DropdownDots
								id="BulkActions"
								elements={bulkActions}
								on:*={(event) => {
							handleBulkAction(event.detail, selectedElements)
						}} />
						{:else}
							<div class="flex gap-2">
								{#each bulkActions as bulkAction}
									<svelte:component
										this={bulkAction.component}
										{...{ id: "bulkActions",
											...Object.keys(bulkAction.props)
												.filter((key) => key !== 'action' && key !== 'condition')
												.reduce((obj, key) => {
													obj[key] = bulkAction.props[key];
													return obj;
												}, {}),
											action: () => handleBulkAction(bulkAction.props.action, selectedElements)
										}}
									/>
								{/each}
							</div>
						{/if}
					</div>
				</div>
			{/if}
		</div>

		<div class="overflow-auto custom-scrollbar rounded-lg shadow-md">
			<table class="min-w-full divide-y divide-gray-200">
				<thead class="bg-gray-200">
					<tr>
						{#if bulkActions}
							<th class="py-5 tracking-wider flex items-center justify-center">
								<CheckBox
									checked={pageSelected}
									on:change={(e) => selectCurrentPage(e.detail)}
								/>
							</th>
						{/if}
						{#each columns as column, index}
							{#if $selectedColumns.includes(column.key)}
								<th
									class="px-4 py-3 text-left text-sm font-medium text-gray-500 tracking-wider cursor-pointer"
								>
									<div class="relative flex items-center gap-4">
										<!-- svelte-ignore a11y-click-events-have-key-events -->
										<!-- svelte-ignore a11y-no-static-element-interactions -->

										{#if !column.key.includes('.') && canSort && (column.canSort ?? true)}
											<div class="flex items-center gap-2" on:click={() => handleSort(column.key)}>
												{column.title}
												{#if $orderBy.includes(column.key)}
													<Icon
														src={$sortOrder === 'ASC' ? ChevronUp : ChevronDown}
														class="w-4 h-4"
													/>
												{:else}
													<Icon src={ChevronUpDown} class="w-4 h-4" />
												{/if}
											</div>
										{:else}
											{column.title}
										{/if}
										{#if canSearch && (column.canSearch ?? true)}
											<div bind:this={headersSearch[index]} class="w-full">
												<button
													on:click={() => openSearchInput(index)}
													class="p-2 rounded-full text-white hover:bg-gray-500 focus:outline-none"
													hidden={!searchInputsButtonVisible[index]}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="h-6 w-6"
														fill="none"
														viewBox="0 0 24 24"
														stroke="black"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M11 4a7 7 0 107 7 7 7 0 00-7-7zM21 21l-4.35-4.35"
														/>
													</svg>
												</button>

												<div
													class="{searchInputsButtonVisible[index] ? 'absolute' : ''} w-full my-2"
												>
													{#if column.type === 'date'}
														<DateInput
															range={true}
															hidden={!searchInputsVisible[index]}
															classes={searchInputsButtonVisible[index] ? 'z-50' : ''}
															value={getSearchValue(column.key)}
															disabledDates={dateDisabled}
															bind:this={searchInputs[index]}
															on:change={(customEvent) => handleSearchInput(customEvent.detail, column.key)}
														/>
													{:else}
														<Input
															bind:this={searchInputs[index]}
															hidden={!searchInputsVisible[index]}
															type="text"
															classes={searchInputsButtonVisible[index] ? 'z-50' : ''}
															value={getSearchValue(column.key)}
															timing={5000}
															on:input={(customEvent) => handleSearchInput(customEvent.detail, column.key)}
															on:focusout={() => searchInputsButtonVisible[index] ? openSearchInput(index) : ''}
														/>
													{/if}
												</div>
											</div>
										{/if}
									</div>
								</th>
							{/if}
						{/each}
						{#if actions && $selectedColumns.includes('action') && actions.length > 0}
							<th class="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
								<div class="flex gap-4 items-center">Actions</div>
							</th>
						{/if}
					</tr>
				</thead>

				<tbody class="bg-white divide-y divide-gray-200">
					{#if bulkActions && selectedElements && selectedElements.length > 0}
						<td colspan="100%" class="bg-gray-300 text-gray-600 text-sm py-1" on:click={(event) => event.stopPropagation()}>
							<div class="flex justify-between w-full items-center">
								<div class="px-2">
									{ selectedElements.length } éléménts sélectionnés.
									{#if totalCount !== selectedElements.length}
										<button type="button" on:click={selectAll} class="text-primary">Tout sélectionner</button>
									{/if}
									<button type="button" on:click={deselectAll} class="text-primary">Tout désélectionner</button>
								</div>
								<div class="p-2">
								</div>
							</div>
						</td>
					{/if}
					{#if (Array.isArray(data) && data.length === 0) || (typeof data === 'object' && !Array.isArray(data))}
						<td colspan="100%">
							<p class="text-center text-lg text-gray-500 pl-1 w-full">Aucun objet trouvé</p>
						</td>
					{:else if Array.isArray(groupedData)}
						{#each groupedData as row}
							<tr
								class="hover:bg-gray-50"
								on:click={() => handleRowClick(row)}
								on:mouseenter={(event) => handleMouseEnter(event, row)}
								on:mousemove={handleMouseMove}
								on:mouseleave={handleMouseLeave}
							>
								{#if bulkActions}
									<td class="px-6" on:click={(event) => event.stopPropagation()}>
										<div class="w-full h-full flex items-center justify-center">
											<CheckBox
												checked={selectedElements.some(selected => JSON.stringify(row) === JSON.stringify(selected))}
												on:change={(event) => {
													if (event.detail) {
														selectedElements = mergeWithoutDuplicates(selectedElements, [row]);
													} else {
														selectedElements = selectedElements.filter((element) => JSON.stringify(element) !== JSON.stringify(row));
													}
												}} />
										</div>
									</td>
								{/if}
								{#each columns as column}
									{#if $selectedColumns.includes(column.key)}
										<td class="px-6 py-4 text-xs text-gray-700 whitespace-nowrap">
											{#if column.type}
												{#if column.type === 'email'}
													<a
														href="mailto:{getValueByPath(row, column.key)}"
														on:click={(event) => event.stopPropagation()}
														>{getValueByPath(row, column.key)
															? getValueByPath(row, column.key)
															: ''}</a
													>
												{:else if column.type === 'indicator'}
													<div class="w-4 h-4 rounded-full {column.action(row)}"></div>
												{:else if column.type === 'lock'}
													{#if column.action(row)}
														<Icon src={LockClosed} class="size-5" />
													{/if}
												{:else if column.type === 'currency'}
													{formatToCurrency(getValueByPath(row, column.key) ?? 0)}
												{:else if column.type === 'date'}
													{formatToDate(getValueByPath(row, column.key) ?? '')}
												{:else}
													{getValueByPath(row, column.key) ?? ''}
												{/if}
											{:else if column.display}
												{column.display(row)}
											{:else}
												{getValueByPath(row, column.key) ? getValueByPath(row, column.key) : ''}
											{/if}
										</td>
									{/if}
								{/each}
								{#if actions && $selectedColumns.includes('action') && actions.length > 0}
									<td
										class="px-6 py-4 text-xs text-gray-700 whitespace-nowrap"
										on:click={(event) => event.stopPropagation()}
									>
										{#if actions.length === 1}
											{#if actions[0].component && (!actions[0].props.condition || actions[0].props.condition(row))}
												<svelte:component
													this={actions[0].component}
													{...{
														...Object.keys(actions[0].props)
															.filter((key) => key !== 'action' && key !== 'condition')
															.reduce((obj, key) => {
																obj[key] = actions[0].props[key];
																return obj;
															}, {}),
														action: () => handleAction(actions[0].props.action, row)
													}}
												/>
											{/if}
										{:else}
											<DropdownDots
												id="dropDownActions"
												elements={actions.filter(
													(action) => !action.props.condition || action.props.condition(row)
												)}
												on:*={(event) => {
													handleAction(event.detail, row);
												}}
											/>
										{/if}
									</td>
								{/if}
							</tr>
						{/each}
					{:else}
						{#each Object.keys(groupedData) as groupKey}
							<tr>
								<td colspan={columns.length} class="px-6 py-4 font-bold">{groupKey}</td>
							</tr>
							{#each groupedData[groupKey] as row}
								<tr
									class="hover:bg-gray-50"
									on:click={() => handleRowClick(row)}
									on:mouseenter={(event) => handleMouseEnter(event, row)}
									on:mousemove={handleMouseMove}
									on:mouseleave={handleMouseLeave}
								>
									{#if bulkActions}
										<td class="px-6" on:click={(event) => event.stopPropagation()}>
											<div class="w-full h-full flex items-center justify-center">
												<CheckBox
													checked={selectedElements.some(selected => JSON.stringify(row) === JSON.stringify(selected))}
													on:change={(event) => {
													if (event.detail) {
														selectedElements = mergeWithoutDuplicates(selectedElements, [row]);
													} else {
														selectedElements = selectedElements.filter((element) => JSON.stringify(element) !== JSON.stringify(row));
													}
												}} />
											</div>
										</td>
									{/if}
									{#each columns as column}
										{#if $selectedColumns.includes(column.key)}
											<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap {rowCondition}">
												{getValueByPath(row, column.key)}
											</td>
										{/if}
									{/each}
								</tr>
							{/each}
						{/each}
					{/if}
				</tbody>
			</table>
		</div>
		<nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 w-full">
			<div class="-mt-px flex w-0 flex-1">
				<button
					on:click={() => handlePageChange(currentPage - 1)}
					disabled={!hasPrevious}
					class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
				>
					<Icon src={ArrowLeft} class="mr-3 h-5 w-5 text-gray-400" />
				</button>
			</div>
			<div class="hidden md:-mt-px md:flex">
				{#each Array.from({ length: totalPages }, (_, i) => i + 1) as page}
					{#if page === currentPage}
						<button
							class="inline-flex items-center px-4 pt-4 text-sm font-medium text-primary relative"
							aria-current="page"
							on:click={() => goToPage(page)}
						>
							<span
								class="absolute inset-x-0 top-0 h-1/5 bg-gradient-to-b from-primary to-transparent"
							/>
							{page}
						</button>
					{:else if page === 1 || page === totalPages || (page >= currentPage - 1 && page <= currentPage + 1)}
						<button
							class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
							on:click={() => goToPage(page)}
						>
							{page}
						</button>
					{:else if page === currentPage - 2 || page === currentPage + 2}
						<span
							class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
						>
							...
						</span>
					{/if}
				{/each}
			</div>
			<div class="-mt-px flex w-0 flex-1 justify-end">
				<button
					on:click={() => handlePageChange(currentPage + 1)}
					disabled={!hasNext}
					class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
				>
					<Icon src={ArrowRight} class="ml-3 h-5 w-5 text-gray-400" />
				</button>
			</div>
		</nav>
	</div>
	<!-- Ajoutez ce code à la fin du fichier, juste avant la balise de fermeture </script> -->
	{#if hoveredRow && showTooltip}
		<Tooltip text="Voir les détails" x={mouseX} y={mouseY}></Tooltip>
	{/if}

	<style>
		.custom-scrollbar::-webkit-scrollbar {
			height: 8px;
		}
		.custom-scrollbar::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		.custom-scrollbar::-webkit-scrollbar-thumb {
			background: #888;
		}
		.custom-scrollbar::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	</style>
{/if}
