<script>
    import { createEventDispatcher } from 'svelte';
    import { Icon, Calendar } from 'svelte-hero-icons';

    export let label = '';
    export let value = '';
    export let placeholder = '';
    export let required = false;
    export let pattern;

    const dispatch = createEventDispatcher();

    const handleInput = (event) => {
      value = event.target.value;
      dispatch('input', value);
    };

</script>

<div class="input-group w-36">
  {#if label}
    <label class="font-medium" for={label}>
      {label}
    </label>
  {/if}
  <div class="relative">
    <input
      id={label}
      type="date"
      class="cursor-pointer w-full rounded-md border-none bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 appearance-none"
      bind:value
      {placeholder}
      {required}
      on:input={handleInput}
      {pattern}
    />
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <Icon src={Calendar} class="h-5 w-5 text-gray-400" />
    </div>
   
  </div>
</div>

<style>
  .input-group {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
</style>
