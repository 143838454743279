<script>
	import { Router, Route } from 'svelte-routing';

	// pages for this layout
	import IndexVente from '../views/vente/IndexVente.svelte';
	import NewBc from '../views/vente/NewBonCommande.svelte';
	import NewDevis from '../views/vente/NewDevis.svelte';
	import NotFound from '../views/404.svelte';
</script>

<Router url="vente">
	<Route path="/" component={IndexVente} />
	<Route path="creationBC" component={NewBc} />
	<Route path="creationDE" component={NewDevis} />
	<Route path="*" component={NotFound} />
</Router>
