import { cleanObject } from "./utils";
import { fetchData } from "./utils";

// DEPOT

/**
 * Liste des dépôts*
 * 
 * @returns {Promise<{data: any, pagination: any}>}
 * 
*/

export async function getDepots() {
    return await fetchData('/depot/LD'); 
}

