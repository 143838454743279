import StocksDataEntryModel from './StocksDataEntryModel';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Adresse - 
 * @property {string} Dte - 
 * @property {string} NomSociete - 
 * @property {Array<StocksDataEntryModel>} DataSet - 
 * @property {string} Titre - 
 * @property {string} NoMvt - 
 * @property {string} Ref - 
 */
class StocksModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Adresse = null;
    /** @type {string} */
    this.Dte = null;
    /** @type {string} */
    this.NomSociete = null;
    /** @type {Array<StocksDataEntryModel>} */
    this.DataSet = [];
    /** @type {string} */
    this.Titre = null;
    /** @type {string} */
    this.NoMvt = null;
    /** @type {string} */
    this.Ref = null;
  }
}

export default StocksModel;
