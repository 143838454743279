import { jwtVerify } from 'jose';
import { transformObjectToQueryParams } from '../../utils.js';
import ForbiddenScreen from '../../views/401.svelte';

const API_URL = process.env.API_BACKEND_URL; // L'URL de votre backend NestJS

// Fonction pour récupérer le token JWT depuis le local storage
function getToken() {
	return sessionStorage.getItem('token');
}

// Fonction pour configurer les headers avec JWT
function getHeaders() {
	const token = getToken();
	return token
		? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
		: { 'Content-Type': 'application/json' };
}

// Fonction pour faire une requête GET
export async function get(endpoint, queryParams = {}) {
	const response = await fetch(`${API_URL}${endpoint}?${transformObjectToQueryParams(queryParams)}`, {
		origin: window.origin,
		method: 'GET',
		headers: getHeaders()
	});
	return response.json();
}

// Fonction pour faire une requête POST
export async function post(endpoint, data) {
	const response = await fetch(`${API_URL}${endpoint}`, {
		origin: window.origin,
		method: 'POST',
		headers: getHeaders(),
		body: JSON.stringify(data)
	});
	return response.json();
}

// Fonction pour faire une requête DELETE
export async function del(endpoint, data) {
	const response = await fetch(`${API_URL}${endpoint}`, {
		origin: window.origin,
		method: 'DELETE',
		headers: getHeaders(),
		body: JSON.stringify(data)
	});
	return response.json();
}

export async function put(endpoint, data) {
	const response = await fetch(`${API_URL}${endpoint}`, {
		origin: window.origin,
		method: 'PUT',
		headers: getHeaders(),
		body: JSON.stringify(data)
	});
	return response.json();
}

export async function patch(endpoint, data) {
	const response = await fetch(`${API_URL}${endpoint}`, {
		origin: window.origin,
		method: 'PATCH',
		headers: getHeaders(),
		body: JSON.stringify(data)
	});
	return response.json();
}

// Autres méthodes (PUT, DELETE) peuvent être ajoutées ici
/**
 * @description returns the connected user
 * @returns {JWTPayload}
 * @example
 * const user = await getConnectedUser();
 * console.log(user);
 * {
 *   "sub": 1,
 *   "username": "admin@example.com",
 *   "name": "Admin",
 *   "rights": [
 *     {
 *       "id": 1,
 *       "userId": 1,
 *       "rightId": 1,
 *       "hasRight": true,
 *       "right": {
 *         "id": 1,
 *         "name": "read_users",
 *         "description": "Ability to read user information"
 *       }
 *     }
 *   ],
 *   "sageLink": "1",
 * }
 */
export async function getConnectedUser() {
	let secret = new TextEncoder().encode(process.env.JWT_SECRET);
	return await jwtVerify(sessionStorage.getItem('token'), secret).then((res) => res.payload);
}

/**
 * @description returns the connected user rights
 * @returns {*}
 * @example
 * const rights = getConnectedUserRights();
 * console.log(rights);
 * [
 *   {
 *     "id": 11,
 *     "userId": 1,
 *     "rightId": 1,
 *     "hasRight": {
 *         "type": "Buffer",
 *         "data": [
 *             1
 *         ]
 *     },
 *     "right": {
 *         "id": 1,
 *         "name": "read_users",
 *         "description": "Ability to read user information"
 *     }
 *   }
 * ]
 */
export function getConnectedUserRights() {
	return JSON.parse(sessionStorage.getItem('rights'));
}

/**
 * @description checks if the connected user has a specific right with a specific permission
 * @param {string} right
 * @param {number} permission
 * @returns {boolean}
 * @example
 * console.log(connectedUserHasRight('read_users', 1)); // true
 * console.log(connectedUserHasRight('read_users', 2)); // false
 * console.log(connectedUserHasRight('users', 1)); // false
 */
export function connectedUserHasRight(right, permission) {
	let rights = getConnectedUserRights();
	let userRight = rights.filter((r) => r.right.name === right)[0];
	if (!userRight) {
		return false;
	}

	return (userRight.permissions & permission) !== 0;
}

/**
 * @description blocks the user if he does not have the right to access a route
 * @param {string} right
 * @param {number} permission
 */
export function connectedUserHasAccess(right, permission) {
	if (!connectedUserHasRight(right, permission)) {
		document.body.innerHTML = '';
		new ForbiddenScreen({ target: document.body });
	}
}