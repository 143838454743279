import axios from 'axios';
import { getConnectedUser } from '../Backend/api.js';
import { getCollaborateurByNum } from './CollaborateurQueries.js';
import { newBonCommande } from './DocVenteQueries.js';
import { addToast, COMPTATYPES } from '../../store.js';


// Fonction pour configurer les headers avec JWT
function getHeaders() {
	const token = sessionStorage.getItem('token');
	return {
		'Content-Type': 'application/json',
		...(token && { 'Authorization': `Bearer ${token}` }),
	};
}

const api = axios.create({
	baseURL: `${process.env.API_BACKEND_URL}/sage`,
});

// Mise à jour des headers avant chaque requête
api.interceptors.request.use(
	async config => {
		config.headers = {
			...config.headers,
			...getHeaders()
		};
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export async function fetchData(endpoint, queryParams = {}, method = 'GET', body = null) {
	try {
		const config = {
			method,
			url: endpoint,
			params: queryParams
		};

		if (body) {
			config.data = body;
		}

		const response = await api(config);

		return response.data

	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}

function isEmpty(value) {
	return (
		value === '' ||
		value === null ||
		value === undefined ||
		(typeof value === 'number' && value === 0) ||
		(typeof value === 'object' && Object.keys(value).length === 0)
	);
}

// Nettoyer les objets imbriqués vides et les valeurs vides dans un objet donné
export function cleanObject(obj, seen = new WeakSet()) {
	if (seen.has(obj)) return obj; // Détection de référence circulaire
	seen.add(obj);

	for (const key in obj) {
		const value = obj[key];

		if (typeof value === 'object' && value !== null) {
			if (Array.isArray(value)) {
				obj[key] = value.reduce((acc, item) => {
					const cleanedItem = typeof item === 'object' ? cleanObject(item, seen) : item;
					if (!isEmpty(cleanedItem)) {
						acc.push(cleanedItem);
					}
					return acc;
				}, []);

				if (obj[key].length === 0) {
					delete obj[key];
				}
			} else {
				obj[key] = cleanObject(value, seen);

				if (isEmpty(obj[key])) {
					delete obj[key];
				}
			}
		} else if (isEmpty(value)) {
			delete obj[key];
		}
	}

	seen.delete(obj);
	return obj;
}

export async function linkClientToCollabo(clientSage) {
	let user = await getConnectedUser();
	if (!user.sageLink) {
		return;
	}

	let collabo = await getCollaborateurByNum(user.sageLink).then((res) => res.data);

	let body = {
		CtNum: clientSage.CT_Num,
		Status: 'A preparer',
		Collaborateur: {
			CO_Nom: collabo.CO_Nom,
			CO_Prenom: collabo.CO_Prenom
		}
	};

	let result = await newBonCommande(body).then(res => res.data);
	if (result.status && result.status !== 200) {
		addToast(result.detail, 'bg-red-500');
		return;
	}

	addToast('Client ajouté avec succès', 'bg-green-500');
}

/**
 * Calculer le montant HT, la taxe et le montant TTC d'une ligne de document
 * @param {*} article
 * @param {*} client
 * @param {{ Quantite: number, PrixUnitaire: number, PrixTTC: number }} line
 * @returns {[number,number,number, string]}
 */
export function calculateMontant(article, client, line) {
	const { Quantite, PrixUnitaire, PrixTTC } = line;
	const price = Quantite * PrixUnitaire;

	// Si l'article a une comptabilité spécifique
	if (article.Comptabilite) {
		const taxe = article.Comptabilite?.find(
			(compta) => compta?.CategorieCompta === client.CategorieCompta
		);

		return calculateMontantWithTaxe(price, {
			tauxTaxe: taxe?.TA_Taux ?? 0,
			taxeIntitule: taxe?.TA_Intitule ?? 'Taxe non renseignée',
			prixTTC: PrixTTC === 1
		});
	}

	// Sinon utiliser la famille comptable
	const taxe = article.FamilleCompta?.find(
		(famC) => famC?.FCP_Type === COMPTATYPES[client.CategorieCompta.trim()]
	);

	return calculateMontantWithTaxe(price, {
		tauxTaxe: taxe?.TA_Taux ?? 0,
		taxeIntitule: taxe?.FCP_ComptaCPT_Taxe1 ?? 'Taxe non renseignée',
		prixTTC: PrixTTC === 1
	});
}

/**
 * Calculer le HT, la taxe et le TTC depuis un montant
 * @param {number} montantHT
 * @param {number} tauxTaxe
 * @param {string} taxeIntitule
 * @param {boolean} prixTTC
 * @returns {[number, number, number, string]}
 */
function calculateMontantWithTaxe(montantHT, { tauxTaxe, taxeIntitule, prixTTC }) {
	let montantTaxe, montantTTC;
	if (prixTTC) {
		montantTTC = montantHT;
		montantHT = montantTTC / (1+(tauxTaxe / 100));
		montantTaxe = montantTTC - montantHT;
	} else {
		montantTaxe = montantHT * (tauxTaxe / 100);
		montantTTC = montantHT + montantTaxe;
	}

	return [montantHT, montantTaxe, montantTTC, taxeIntitule];
}

// Intercepteur pour gérer les erreurs globalement
api.interceptors.response.use(
	response => response,
	error => {
		if (error.response) {
			// Erreur de réponse du serveur (status !== 2xx)
			console.error('Server Error:', error.response.data);
			throw new Error(error.response.data.message || 'Une erreur est survenue');
		} else if (error.request) {
			// Pas de réponse reçue du serveur
			console.error('Network Error:', error.request);
			throw new Error('Erreur de connexion au serveur');
		} else {
			// Erreur lors de la configuration de la requête
			console.error('Request Error:', error.message);
			throw error;
		}
	}
);