<script>
    import { createEventDispatcher } from 'svelte';

    export let label = '';
    export let value = '';
    export let placeholder = '';
    export let required = false;
    export let minlength = 0;
    export let maxlength = 255;
    export let pattern;

    const handleInput = (event) => {
      value = event.target.value;
      dispatch('input', value);
    };
  
    const dispatch = createEventDispatcher();
  </script>
  
  <div class="input-group">
    {#if label}
      <label class="font-medium" for={label}>
        {label}
      </label>
    {/if}
    <input
      id={label}
      type='text'
      class="cursor-default w-full rounded-md border-none bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
      bind:value
      placeholder={placeholder}
      required={required}
      minlength={minlength}
      maxlength={maxlength}
      on:input={handleInput}
      pattern={pattern}
    />
  </div>
  
  <style>
    .input-group {
      margin-bottom: 1rem;
    }
    label {
      display: block;
      margin-bottom: 0.5rem;
    }

  </style>
  