import { fetchData, cleanObject } from './utils.js';

/**
 * 
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get all articles
 * @example
 * const articles = await getArticles();
 * console.log(articles);
 * 
 */
export async function getArticles(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 25,
	OrderBy
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

	return await fetchData('/article/LA', queryParams);
}

/**
 * @param {string} reference
 * @returns {Promise<{data: any}>}
 * @description Get article by reference
 */
export async function getArticleByReference(reference) {
	return await fetchData(`/article/${reference}`);
}

/**
 * 
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get All Article info libres
 */
export async function getInfoLibres() {
    return await fetchData('/article/infoLibres'); // GET request
}


/**
 * 
 * @param {*} body 
 * @returns  {Promise<{data: any, pagination: any}>}
 * @description Create a new article
 */
export async function newArticle(body) {
    body = cleanObject(body); // Ensure body is cleaned before sending
    return await fetchData('/article/nouveau', {}, 'POST', body);
}

/**
 *
 * @param {string} currentRef
 * @param {string} newRef
 * @param {string} newDesign
 * @returns {Promise<{data: any}>}
 */
export async function duplicateArticle(currentRef, newRef, newDesign) {
		const body = {
			ArticleOriginalNum: currentRef,
			ArticleDuplicateNum: newRef,
			ArticleDuplicateDesign: newDesign
		};
		return await fetchData('/article/dupliquer', {}, 'POST', body);
}