<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
	<div class="text-center">
		<p class="font-semibold text-primary text-4xl">404</p>
		<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
			Page non trouvé
		</h1>
		<p class="mt-6 text-base leading-7">Désoler, nous n'avons pas trouvé la page recherché.</p>
	</div>
	<div class="bg-black mt-10 flex items-center justify-center gap-x-6 rounded-md w-40">
		<a href="/accueil" class="text-primaryLight rounded-md px-3.5 py-2.5 text-base font-semibold shadow-sm"
			>Aller à l'accueil</a
		>
	</div>
</main>
