<script>
	// Components
	import Input from '../../components/Input/Input.svelte';
	import DateInput from '../../components/Input/DateInput.svelte';
	import DoubleInput from '../../components/Input/DoubleInput.svelte';
	import NumberInput from '../../components/Input/NumberInput.svelte';
	import ButtonPrimary from '../../components/Button/ButtonPrimary.svelte';
	import Select from '../../components/Select/Select.svelte';
	import Combobox from '../../components/Combobox/Combobox.svelte';
	import Drawer from '../../components/Drawer.svelte';

	// Stock
	import {
		getDocEntree,
		getDocSortie,
		getDocTransfert,
		newEntreeStock,
		newSortieStock
	} from '../../API/API_SAGE/DocStockQueries';
	import MouvementEntreeNewModel from '../../API/API_SAGE/model/MouvementEntreeNewModel';

	// Depots
	import { getDepots } from '../../API/API_SAGE/DepotQueries';

	// Article
	import { getArticleByReference, getArticles } from '../../API/API_SAGE/ArticleQueries.js';

	//Unite vente
	import { getUniteVenteByReference } from '../../API/API_SAGE/UniteVenteQueries.js';

	// Store
	import { addToast, closeDrawer, openDrawer } from '../../store.js';
	import { onDestroy, onMount } from 'svelte';
	import { printMouvement, formatToDate } from '../../utils.js';

	// Constantes
	const providers = {
		1: getDocEntree,
		2: getDocSortie,
		3: getDocTransfert
	};

	// Variables
	/**
	 * @type {{ value: string, label: string }[]}
	 */
	let depotOptions = [{ value: '', label: '' }];
	$: mouvementOptions = [
		{ value: 1, label: 'Entrée' },
		{ value: 2, label: 'Sortie' }
	];
	let mouvement = 2;
	let mouvementEntreeBody = new MouvementEntreeNewModel();

	let reference = '';
	let article = null;
	let showDescription = false;
	let quantite = 0;
	let poidsBrut = '';
	let conditionnement = '';

	// Functions
	onMount(async () => {
		try {
			// Get depots
			const depots = await getDepots().then((res) => res.data);
			// Construct options
			depotOptions = depots.map((depot) => ({
				value: depot.DE_Intitule,
				label: depot.DE_Intitule
			}));
			mouvementEntreeBody.IntituleDepotStockage = depotOptions[0].value;
			mouvementEntreeBody.Date = new Date().toISOString().split('T')[0];
		} catch (error) {
			addToast(`Erreur lors du chargement des dépôts: ${error.message}`, 'bg-red-500');
		}

		window.addEventListener('keydown', handleKeyDown);
	});


	async function setConditionnement(articleDefault) {
		article = articleDefault ?? await getArticleByReference(reference).then((res) => res.data);
		if (article.status) {
			conditionnement = '';
			article = null;
			showDescription = false;
			return;
		}
		var conditionnement = await getUniteVenteByReference(article.AR_UniteVen).then((res) => res.data);
		conditionnement = conditionnement.U_Intitule;
		showDescription = true;
	}

	async function setPoidsBrut(articleDefault) {
		article = articleDefault ?? await getArticleByReference(reference).then((res) => res.data);
		if (article.status) {
			poidsBrut = '';
			article = null;
			showDescription = false;
			return;
		}
		poidsBrut = article.AR_PoidsBrut;
		showDescription = true;
	}

	async function addStockLigne() {
		// Check if the reference is empty
		if (reference === '') {
			addToast('La référence article est obligatoire', 'bg-red-500');
			return;
		}
		if (quantite === 0) {
			addToast('La quantité doit être supérieure à 0', 'bg-red-500');
			return;
		}
		// Check existence of the article
		article = article ?? await getArticleByReference(reference).then((res) => res.data);
		if (article.status) {
			addToast(article.detail, 'bg-red-500');
			return;
		}

		if (conditionnement === '') {
			await setConditionnement();
		}

		if (poidsBrut === '') {
			poidsBrut = article.AR_PoidsBrut;
		}

		mouvementEntreeBody = {
			...mouvementEntreeBody,
			ListeRefArticle: [
				...mouvementEntreeBody.ListeRefArticle,
				{
					Reference: article.AR_Ref,
					Quantite: quantite,
					PoidsBrut: poidsBrut,
					Conditionnement: conditionnement,
				}
			]
		};

		reference = '';
		quantite = 0;
		poidsBrut = '';
		conditionnement = '';
		article = null;
	}

	function removeDocLigne(index) {
		mouvementEntreeBody = {
			...mouvementEntreeBody,
			ListeRefArticle: mouvementEntreeBody.ListeRefArticle.filter((_, i) => i !== index)
		};
	}

	async function createMouvement(event) {
		event.preventDefault();

		console.log(mouvementEntreeBody);



		if (mouvementEntreeBody.ListeRefArticle.length === 0) {
			addToast('Veuillez ajouter au moins une ligne', 'bg-red-500');
			return;
		}

		if(mouvementEntreeBody.Date != null) {
			mouvementEntreeBody.Date = new Date(mouvementEntreeBody.Date).toISOString().split('T')[0];
		}

		console.log(mouvementEntreeBody);

		try {
			let response =
				mouvement === 1
					? await newEntreeStock(mouvementEntreeBody)
					: await newSortieStock(mouvementEntreeBody);

			if (response.data.status === 200) {
				generatePDF(response.data.do_piece);
				addToast(response.data.detail, 'bg-green-500');
				resetForm();
				return;
			}

			addToast(response.data.detail, 'bg-red-500');
		} catch (error) {
			addToast(`Erreur innatendue ${error}`, 'bg-red-500');
		}
	}

	/**
	 *
	 * @param {string} no_mvmt Numéro de mouvement du document
	 */
	async function generatePDF(no_mvmt) {
		let typeDoc = mouvementOptions.find(e => e.value === mouvement).label;
		let response = await providers[mouvement]("", "", "", "", "DO_Piece", no_mvmt, "", 1, 1, "");

		printMouvement(response.data[0], typeDoc);
	}

	function resetForm() {
		mouvementEntreeBody = new MouvementEntreeNewModel();
		mouvementEntreeBody.IntituleDepotStockage = depotOptions[0].value;
	}

	async function handleInput(article) {
		if (!article.AR_Ref) {
			article = null;
		}
		await setConditionnement(article);
		await setPoidsBrut(article);
	}

	async function handleKeyDown(event) {
		if (event.key === 'F4' && document.activeElement.id === 'Reference') {
			let data = await getArticles('', '', '', '', 'AR_Ref', reference, 'ASC', 1, 1000, 'AR_Ref').then((res) => {
				return res.data.map((article) => ({
					value: article.AR_Ref,
					label: `${article.AR_Ref} | ${article.AR_Design}`
				}));
			});
			openDrawer(data);
		}
	}

	onDestroy(() => {
		window.removeEventListener('keydown', handleKeyDown);
	});
</script>

<div class="text-card-foreground pb-4">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Mouvement de stock
		</h1>

		<Select
			id="Mouvement"
			label="Type de mouvement"
			options={mouvementOptions}
			bind:value={mouvement}
			width="w-1/3"
		></Select>
	</div>

	<form class="p-6 space-y-4" on:submit={createMouvement}>
		<div class="flex items-baseline justify-between">
			<h2
				class="whitespace
            -nowrap text-xl font-semibold leading-none tracking-tight"
			>
				En-tête
			</h2>

			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-3 gap-4">
			<Combobox
				id="IntituleDepotStockage"
				label="Dépôt de stockage"
				placeholder="Dépôt de stockage"
				options={depotOptions}
				bind:value={mouvementEntreeBody.IntituleDepotStockage}
				isSearchable={true}
				required
			></Combobox>
			<Input
				id="Reference"
				placeholder="Reference"
				label="Reference"
				bind:value={mouvementEntreeBody.Reference}
				maxlength="69"
				classes="my-2"
			/>
			<DateInput
				id="Date"
				placeholder="Date"
				label="Date"
				bind:value={mouvementEntreeBody.Date}
				enableFuture={true}
			/>

			<div class="flex items-baseline col-span-3">
				<h2
					class="whitespace
                    -nowrap text-xl font-semibold leading-none tracking-tight"
				>
					Liste des articles
				</h2>

				<hr class="flex-grow ml-4 border-t border-gray-300" />
			</div>

			<div class="col-span-3 flex justify-evenly gap-4">
				<Input
					id="Reference"
					placeholder="Référence Article"
					label="Référence Article"
					bind:value={reference}
					timing={1000}
					on:input={handleInput}
					description={article?.AR_Design}
					showDescription={showDescription}
					classes="my-2"
				/>
				<Drawer title="Sélection de l'article">
					<div slot="drawerContent" let:data>
						{#each data as datum}
							<div class="flex justify-between items-center border-b border-gray-200 w-full">
								<button type="button"
												class="w-full bg-transparent hover:bg-gray-100 text-left p-2 font-medium"
												on:click={async() => {
												article = await getArticleByReference(datum.value).then((res) => res.data ?? '')
												reference = article.AR_Ref;
												await handleInput(article);
												closeDrawer();
											}}>
									{datum.label}
								</button>
							</div>
						{/each}
					</div>
				</Drawer>
				<NumberInput
					id="Quantite"
					placeholder="Quantité"
					label="Quantité"
					min=0
					bind:value={quantite}
					required
				/>
				<DoubleInput
					id="PoidBrut"
					placeholder="Poids Brut"
					label="Poids Brut"
					bind:value={poidsBrut}
					classes="my-2"
				/>
				<Input
					id="Conditionnement"
					label="Conditionnement"
					bind:value={conditionnement}
					disabled
					classes="my-2"
				/>

				<div class="flex items-center">
					<ButtonPrimary px='px-3' py="py-2" on:click={addStockLigne}>Ajouter Ligne</ButtonPrimary>
				</div>
			</div>
		</div>

		<!-- Liste des lignes ajoutées -->
		<div class="mt-6 col-span-3">
			{#if mouvementEntreeBody.ListeRefArticle.length > 0}
				<table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
					<thead class="bg-gray-50">
					<tr>
						<th
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>Référence
						</th
						>
						<th
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>Quantité
						</th
						>
						<th
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>Poids brut
						</th
						>
						<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Conditionnement
						</th>
						<th
							class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
						>Actions
						</th
						>
					</tr>
					</thead>
					<tbody class="bg-white divide-y divide-gray-200">
					{#each mouvementEntreeBody.ListeRefArticle as ligne, index}
						<tr>
							<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
							>{ligne.Reference}</td
							>
							<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ligne.Quantite}</td>
							<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{ligne.PoidsBrut}
							</td>
							<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{ligne.Conditionnement}
							</td>
							<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
								<button
									class="text-red-600 hover:text-red-900"
									type="button"
									on:click={() => removeDocLigne(index)}>Supprimer
								</button
								>
							</td>
						</tr>
					{/each}
					</tbody>
				</table>
			{:else}
				<p class="text-gray-500">Aucune ligne ajoutée pour le moment.</p>
			{/if}
		</div>

		<div class="flex justify-center mt-6 gap-4">
			<ButtonPrimary px='px-4' py="py-2" type="submit">Créer et imprimer le mouvement</ButtonPrimary>
		</div>
	</form>
</div>
