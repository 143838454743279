<script>
	import { BookOpen, ArchiveBox, UserGroup, ChartBar  } from 'svelte-hero-icons'
	import { sidebarOpen } from '../../store.js';
	import { onMount, onDestroy } from 'svelte';

	import Logo from '../../../public/assets/img/Logo_Assortir.png';

	import SidebarLinkGroup from './SidebarLinkGroup.svelte';

	$: isOpen = $sidebarOpen;

	function toggleSidebar() {
		sidebarOpen.update((value) => !value);
	}

	// Function to handle window resize
	function handleResize() {
		if (window.innerWidth >= 1024 && !isOpen) {
			sidebarOpen.set(true);
		}
	}

	// Add event listener for window resize
	onMount(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
	});

	// Remove event listener when component is destroyed
	onDestroy(() => {
		window.removeEventListener('resize', handleResize);
	});

	const groups = [
		{
			title: 'Articles',
			items: [
				{ label: 'Liste des articles', href: '/article/liste' },
				{ label: 'Nouvel article', href: '/article/creation' }
			],
			icon : BookOpen
		},
		{
			title: 'Clients',
			items: [
				{ label: 'Liste des clients', href: '/client/liste' },
				{ label: 'Nouveau client', href: '/client/creation' }
			],
			icon : UserGroup
		},
		{
			title: 'Ventes',
			items: [
				{ label: 'Liste des documents', href: '#' },
				{ label: 'Nouveau devis', href: '/vente/creationDE' },
				{ label: 'Nouveau BC', href: '/vente/creationBC' }
			],
			icon : ChartBar
		},
		{
			title: 'Stocks',
			items: [
				{ label: 'Liste des documents', href: '#' },
				{ label: 'Mouvement de stock', href: '/stock/mouvement' }
			],
			icon : ArchiveBox
		}
	];
</script>

<div>
	<!-- Sidebar backdrop (mobile only)  -->
	<div
		class={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
			isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
		}`}
		aria-hidden="true"
	></div>

	<div
		class:hidden={!isOpen}
		class="h-screen overflow-y-hidden bg-dark text-white fixed lg:relative w-64 lg:w-64 z-40 flex flex-col left-0 top-0 lg:left-auto lg:top-auto lg:translate-x-0 lg:overflow-y-auto no-scrollbar lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 p-4 transition-all duration-200 ease-in-out ${isOpen
			? 'translate-x-0'
			: '-translate-x-64'}"
	>
		<!-- Side bar Header -->

		<div class="flex justify-between mb-10 pr-3 sm:px-2">
			<!-- CLose button -->
			<button class="lg:hidden text-white" on:click={toggleSidebar}>
				<span class="sr-only">Close sidebar</span>
				<svg class="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
				</svg>
			</button>

			<!-- Logo -->

			<img src={Logo} alt="logo" class="w-10" />
		</div>

		<div class="lg:flex lg:flex-col w-full">
			{#each groups as group}
				<SidebarLinkGroup title={group.title} items={group.items} icon={group.icon} />
			{/each}
		</div>
	</div>
</div>
