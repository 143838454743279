<script>
    export let steps = [];
    export let currentStep = 0;
</script>

<nav aria-label="Progress">
    <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {#each steps as step, index}
            <li class="md:flex-1">
                {#if index < currentStep}
                    <!-- Completed Step -->
                    <a href={step.href} class="group flex flex-col border-l-4 border-primary py-2 pl-4 hover:border-primaryDark md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                        <span class="text-sm font-medium text-primary group-hover:text-primaryDark">Etape {index + 1}</span>
                        <span class="text-sm font-medium">{step.label}</span>
                    </a>
                {:else if index === currentStep}
                    <!-- Current Step -->
                    <a href={step.href} class="flex flex-col border-l-4 border-primary py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
                        <span class="text-sm font-medium text-primary">Etape {index + 1}</span>
                        <span class="text-sm font-medium">{step.label}</span>
                    </a>
                {:else}
                    <!-- Upcoming Step -->
                    <a href={step.href} class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                        <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">Etape {index + 1}</span>
                        <span class="text-sm font-medium">{step.label}</span>
                    </a>
                {/if}
            </li>
        {/each}
    </ol>
</nav>
