<script>
    import { createEventDispatcher } from 'svelte';

    export let px = 'px-2';
    export let py = 'py-1';
    export let text = 'text-xs';
    export let content = 'Button text';
    export let type = 'button';

    const dispatch = createEventDispatcher();

    function handleClick(event) {
        dispatch('click', event);
    }
</script>

<button
    on:click={handleClick}
    type={type}
    class="rounded bg-primary {px} {py} {text} font-semibold text-white shadow-sm hover:bg-primaryDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
>
    <slot>{content}</slot>
</button>
