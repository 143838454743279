import { del, get, post } from '../api.js';

export async function getUsersRights(strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 25,
	OrderBy) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};
	return await get('/users-rights', queryParams);
}

/**
 * @description returns all the rights of a user
 * @param user_id
 */
export async function getUserRights(user_id) {
	return await get(`/users-rights/user/${user_id}`);
}

/**
 * @description returns the right of a user
 * @param id
 */
export async function getUserRight(id) {
	return await get(`/users-rights/${id}`);
}

export async function addRightToUser(user_id, right_id, permission) {
	return await post('/users-rights', { user_id, right_id, permission });
}

export async function removeRightFromUser(user_id, right_id) {
	return await del('/users-rights', { user_id, right_id });
}