<script>
    import { onMount } from 'svelte';
    import { Chart, registerables } from 'chart.js';
  
    Chart.register(...registerables);
  

    // CHART TYPE
    
    /*
      - 'bar'
      - 'bubble'
      - 'doughnut'
      - 'line'
      - 'pie'
      - 'polarArea'
      - 'radar'
      - 'scatter'
    */



    export let type = ''; // Default chart type
    export let data = {};
    export let options = {};
  
    let chart;
    let chartRef;
  
    onMount(() => {
      chart = new Chart(chartRef, {
        type,
        data,
        options
      });
  
      return () => {
        chart.destroy();
      };
    });
  
    // Watch for changes in data or options
    $: if (chart) {
      chart.data = data;
      chart.options = options;
      chart.update();
    }
  </script>
  
  <canvas bind:this={chartRef}></canvas>
  
  <style>
    canvas {
      max-width: 100%;
      height: auto;
    }
  </style>
  