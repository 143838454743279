<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';
	import Select from '../../components/Select/Select.svelte';

	// Store
	import { openDrawer } from '../../store';
	import { getDocEntree, getDocSortie, getDocTransfert } from '../../API/API_SAGE/DocStockQueries.js';

	// Svelte
	import ButtonAction from '../../components/Actions/ButtonAction.svelte';
	import { printMouvement } from '../../utils.js';
	import Drawer from '../../components/Drawer.svelte';

	const docTypeOptions = [
		{ value: 'entree', label: 'Entrée' },
		{ value: 'sortie', label: 'Sortie' },
		{ value: 'transfert', label: 'Transfert'}
	];

	const columns = [
		{ key: 'DO_Piece', title: 'N° Pièce' },
		{ key: 'DO_Ref', title: 'Référence' },
		{ key: 'DepotDestination.DE_Intitule', title: 'Dépôt Destination' },
		{ key: 'Do_Date', title: 'Date'},
		{ key: 'CA_Num', title: 'Code Affaire'}
	];

	const transfertColumns = [
		{ key: 'DO_Piece', title: 'N° Pièce' },
		{ key: 'DO_Ref', title: 'Référence' },
		{ key: 'DepotOrigine.DE_Intitule', title: 'Dépôt Origine' },
		{ key: 'DepotDestination.DE_Intitule', title: 'Dépôt Destination' },
		{ key: 'Do_Date', title: 'Date'},
		{ key: 'CA_Num', title: 'Code Affaire'}
	];

	let docLignesColumns = [
		{key: 'Article.AR_Ref', title: 'Référence article'},
		{key: 'DL_Design', title: 'Désignation'},
		{key: 'DL_Qte', title: 'Quantité'},
		{key: 'Conditionnement.EU_Enumere', title: 'Conditionnement'},
		{key: 'DL_PoidsBrut', title: 'Poids brut'},
		{key: 'DL_PrixUnitaire', title: 'PU HT'},
		{key: 'DL_PUTTC', title: 'PU Net'},
	];
	let selectedDocType = 'entree';

	let actions = [
		{ component: ButtonAction, props: { content: 'Imprimer', action: 'impression' } },
	];

	async function handleStock(
		strictSearchFields,
		strictSearchValues,
		excludeSearchFields,
		excludeSearchValues,
		searchFields,
		searchValues,
		OrderDirection,
		pageNumber,
		pageSize,
		OrderBy) {

		switch (selectedDocType) {
			case 'entree':
				return await getDocEntree(
					strictSearchFields,
					strictSearchValues,
					excludeSearchFields,
					excludeSearchValues,
					searchFields,
					searchValues,
					OrderDirection,
					pageNumber,
					pageSize,
					OrderBy
				);
			case 'sortie':
				return await getDocSortie(
					strictSearchFields,
					strictSearchValues,
					excludeSearchFields,
					excludeSearchValues,
					searchFields,
					searchValues,
					OrderDirection,
					pageNumber,
					pageSize,
					OrderBy
				);
			case 'transfert':
				return await getDocTransfert(
					strictSearchFields,
					strictSearchValues,
					excludeSearchFields,
					excludeSearchValues,
					searchFields,
					searchValues,
					OrderDirection,
					pageNumber,
					pageSize,
					OrderBy
				);
			default: return [];
		}
	}

	function handleClick(message) {
		alert(message);
	}

	function handleRowClick(event) {
		const rowData = event.detail;
		openDrawer(rowData);
	}

</script>

<div class="m-2">
	<Select
		id="docType"
		label="Type de document"
		options={docTypeOptions}
		bind:value={selectedDocType}
		on:change={() => window.dispatchEvent(new CustomEvent('refresh'))}
		width="w-1/3"
	/>
</div>
{#key selectedDocType}
	{#if selectedDocType === 'transfert'}
		<DataTable
			onClick={handleClick}
			getQuery={handleStock}
			columns={transfertColumns}
			rowsPerPage={10}
			actions={actions}
			on:rowClick={handleRowClick}
			on:impression={e => printMouvement(e.detail, docTypeOptions.find(e => e.value === selectedDocType).label)}
		/>
	{:else}
		<DataTable
			onClick={handleClick}
			getQuery={handleStock}
			{columns}
			rowsPerPage={10}
			actions={actions}
			on:rowClick={handleRowClick}
			on:impression={e => printMouvement(e.detail, docTypeOptions.find(e => e.value === selectedDocType).label)}
		/>
	{/if}
{/key}

<Drawer title="Détails du document de stock" width="w-2/3" maxWidth="">
	<div slot="drawerContent" let:data>
		<p>N° Pièce: {data.DO_Piece}</p>
		{#if data.DO_Ref}
			<p>Référence: {data.DO_Ref}</p>
		{/if}
		{#if data.DepotOrigine.DE_Intitule}
			<p>Dépôt Origine: {data.DepotOrigine.DE_Intitule}</p>
		{/if}
		{#if data.DepotDestination.DE_Intitule}
			<p>Dépôt destination: {data.DepotDestination.DE_Intitule}</p>
		{/if}
		{#if data.Do_Date}
			<p>Date: {data.Do_Date}</p>
		{/if}
		{#if data.CA_Num}
			<p>Code Affaire: {data.CA_Num}</p>
		{/if}
		<!-- Datatable with doc lines -->
		{#if data.DocLignes.length > 0}
			<h3 class="text-base font-semibold text-gray-700 mt-2">Lignes du document</h3>
			<hr class="mt-2">
			<DataTable
				fromList={data.DocLignes}
				rowsPerPage={10}
				columns="{docLignesColumns}"
			/>
		{/if}
	</div>
</Drawer>