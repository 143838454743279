<script>
	// COMPONENTS
	import Input from '../Input/Input.svelte';
	import DoubleInput from '../Input/DoubleInput.svelte';
	import Select from '../Select/Select.svelte';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';
	// ARTICLE
	import { getInfoLibres, newArticle } from '../../API/API_SAGE/ArticleQueries.js';
	import ArticleNewModel from '../../API/API_SAGE/model/ArticleNewModel.js';
	import InfoLibreResponse from '../../API/API_SAGE/model/InfoLibreResponse.js';
	// FAMILLE
	import { getFamilles } from '../../API/API_SAGE/FamilleQueries.js';
	import FamilleResponse from '../../API/API_SAGE/model/FamilleResponse.js';

	// SVELTE
	import { onMount } from 'svelte';
	// STORE
	import { addToast } from '../../store.js';

	// VARIABLES
	let infoLibres = new InfoLibreResponse();

	/**
	 * @type {FamilleResponse[]}
	 */
	let codeFamilles = new FamilleResponse();

	// MODELS

	/**
	 * @type {ArticleNewModel}
	 */
	let articleBody = new ArticleNewModel();

	// SELECT OPTIONS
	let codeFamillesOptions = [{ value: '', label: '' }];

	// ON MOUNT
	onMount(async () => {
		// GET Sage Data
		infoLibres = await getInfoLibres().then((response) => response.data);
		codeFamilles = await getFamilles(['FA_Type'], [0]).then((response) => response.data);

		// CONSTRUCT OPTIONS
		constructCodeFamilleOptions();

		// INIT MODEL
		articleBody.infoLibres = [];
		articleBody.codeFamille = codeFamillesOptions[0].value;
		articleBody.typeFamille = 'Detail';
	});

	// FUNCTIONS
	function constructCodeFamilleOptions() {
		codeFamillesOptions = codeFamilles.map((famille) => {
			return {
				value: famille.FA_CodeFamille,
				label: famille.FA_Intitule
			};
		});
	}

	function addInfoLibre(event, CB_Name) {
		if (articleBody.InfoLibres.find((infoLibre) => infoLibre.Intitule === CB_Name) != undefined) {
			articleBody.InfoLibres.find((infoLibre) => infoLibre.Intitule === CB_Name).Valeur =
				event.detail;
			return;
		}
		articleBody.InfoLibres.push({ Intitule: CB_Name, Valeur: event.detail });
	}

	async function createArticle(event) {
		event.preventDefault(); // Prevent default form submission

		try {
			let response = await newArticle(articleBody);

			if (response.data.status == 400) {
				addToast(`Erreur lors de la création de l'article. ${response.data.detail}`, 'bg-red-500');
				return;
			}

			addToast(response.data.detail, 'bg-green-500');
		} catch (error) {
			addToast("Erreur innatendue lors de la création de l'article", 'bg-red-500');
		}
	}
</script>

<div class="text-card-foreground pb-4">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Créer un article
		</h1>
	</div>
	<!-- Wrap everything in a form -->
	<form class="p-6 space-y-4" on:submit={createArticle}>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tight">
				Identification
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Input
					id="ref"
					placeholder="Réf"
					label="Référence"
					bind:value={articleBody.reference}
					required
					minlength="1"
					maxlength="18"
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="designation"
					placeholder="Désignation"
					label="Désignation"
					bind:value={articleBody.Design}
					required
					maxlength="69"
				/>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Select
					label="Code Famille"
					options={codeFamillesOptions}
					bind:value={articleBody.CodeFamille}
					required
				/>
			</div>
			<div class="space-y-2">
				<Input id="catalogue" placeholder="Catalogue" label="Catalogue" maxlength="20" />
			</div>
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<DoubleInput
					id="pu-vente"
					placeholder="10.00"
					label="PU Vente"
					bind:value={articleBody.PrixVente}
					type="number"
					min="0.01"
					step="0.01"
					required
				/>
			</div>
		</div>

		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tigh">
				Infos Libres
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-2 gap-4">
			{#each infoLibres as infoLibre}
				<div class="space-y-2">
					<Input
						id={infoLibre.CB_Name}
						label={infoLibre.CB_Name}
						on:input={(event) => {
							addInfoLibre(event, infoLibre.CB_Name);
						}}
						minlength="1"
					/>
				</div>
			{/each}
		</div>

		<div class="flex items-center w-full justify-center">
			<!-- Submit button now tied to the form submission -->
			<ButtonPrimary px="px-6" py="py-2" text="text-lg" type="submit">Créer</ButtonPrimary>
		</div>
	</form>
</div>
