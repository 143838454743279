<script>
	import { sidebarOpen } from '../store.js';
	import { afterUpdate } from 'svelte';
	import Breadcrumbs from './Breadcrumbs.svelte';
	import { Icon, ChevronDown, ChevronUp, Bars3BottomLeft } from 'svelte-hero-icons';

	let username = 'Default User';
	let userDropdownOpen = false;

	afterUpdate(async () => {
		if (localStorage.getItem('username')) {
			username = localStorage.getItem('username');
		}
	});

	$: isOpen = sidebarOpen.subscribe((value) => {
		isOpen = value;
	});

	// Function to toggle sidebar state
	function toggleSidebar() {
		sidebarOpen.update((value) => !value);
	}

	// Function to toggle user dropdown state
	function toggleUserDropdown() {
		userDropdownOpen = !userDropdownOpen;
	}

	function disconnect() {
		sessionStorage.clear();
	}
</script>

<header class="sticky top-0 bg-white border-b border-slate-200 z-30">
	<div class="px-4 sm:px-6 lg:px-8">
		<div class="flex items-center justify-between h-16 -mb-px">
			<!-- Header: Left side  -->
			<div class="flex">
				<!-- Hamburger button  -->
				<button aria-expanded={isOpen} on:click={toggleSidebar} class="pr-4 cursor-pointer lg:hidden text-slate-400">
					<Icon src={Bars3BottomLeft} class="h-8 w-8" />
				</button>

				<!-- Breadcrumbs | Hidden on mobile -->
				<div class="hidden lg:block">
					<Breadcrumbs/>
				</div>
			</div>

			<!-- Header: Right side  -->
			<div class="flex items-center space-x-3">
				<!-- User avatar  -->

				<!-- User name  -->
				<div class="lg:block">
					<p class="text-sm font-semibold text-slate-800">{username}</p>
					<p class="text-xs text-slate-600">Admin</p>
				</div>

				<!-- User dropdown  -->
				<div class="relative">
					<button
						class="p-1 text-primary rounded-full hover:text-primaryDark"
						on:click={toggleUserDropdown}
					>
						<Icon src={userDropdownOpen ? ChevronUp : ChevronDown} class="h-6 w-6" />
					</button>

					<!-- Dropdown menu, show/hide based on dropdown state. -->
					{#if userDropdownOpen}
						<div
							class="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
						>
							<div class="py-1">
								<a
									href="/auth/login"
									class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem"
									on:click={disconnect}
								>
									Déconnexion
								</a>
							</div>
						</div>
					{/if}
				</div>
			</div>
		</div>
	</div>
</header>
