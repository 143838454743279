<script>
	// Components
	import Combobox from '../../../components/Combobox/Combobox.svelte';
	import Select from '../../../components/Select/Select.svelte';
	import ButtonSecondary from '../../../components/Button/ButtonSecondary.svelte';
	import ButtonPrimary from '../../../components/Button/ButtonPrimary.svelte';
	import Modal from '../../../components/Modal.svelte';
	import Card from '../../../components/Card.svelte';

	//Queries
	import { getUsers } from '../../../API/Backend/rights/UserQueries.js';
	import { getRights } from '../../../API/Backend/rights/RightsQueries.js';
	import { addRightToUser, getUserRight, removeRightFromUser } from '../../../API/Backend/rights/UserRightsQueries.js';

	//Svelte
	import { onMount } from 'svelte';

	//Utils
	import { allPermissions, Create, Delete, Edit } from '../../../API/Backend/rights/permissions.js';
	import { addToast } from '../../../store.js';
	import { arrayWrap, openUrlInCurrentTab, ucfirst } from '../../../utils.js';
	import { connectedUserHasAccess, connectedUserHasRight } from '../../../API/Backend/api.js';

	const permissionsOptions = allPermissions
		.map(permission => ({ value: permission.value, label: permission.name }));

	let users = [{ label: '', value: '' }];
	let rights = [{ label: '', value: '' }];
	let user = '';
	let right = '';
	let permission = [permissionsOptions[0].value];
	let isCreation = false;
	let isEdition = false;
	let canDelete = false;
	let showConfirmDeleteModal = false;

	onMount(async () => {
		let userRightID = parseInt(window.location.pathname.split('/').pop());

		if (!userRightID) {
			connectedUserHasAccess('rights', Create);
			isCreation = true;
			users = await getUsers('', '', '', '', '', '', '', 1, 100, '')
				.then(res => res.data.map(user => ({ label: user.name, value: user.id })));
			rights = await getRights()
				.then(res => res.map(right => ({ label: ucfirst(right.name).replace('_', ' '), value: right.id })));
			user = users[0].value;
			right = rights[0].value;
			return;
		}

		connectedUserHasAccess('rights', Edit);
		await getUserRight(userRightID).then(res => {
			let result = res[0];
			user = result.user;
			right = result.right;
			permission = allPermissions.filter(perm => result.permissions & perm.value).map(perm => perm.value);
		});

		users = arrayWrap({ label: user.name, value: user.id });
		rights = arrayWrap({ label: ucfirst(right.name).replace('_', ' '), value: right.id });
		isEdition = true;
		canDelete = connectedUserHasRight('rights', Delete);
	});

	async function saveRight() {
		permission = arrayWrap(permission).flat();
		let response = await addRightToUser(user.id, right.id, permission);
		if (response.statusCode && response.statusCode !== 200) {
			addToast(`Erreur lors de l'ajout du droit ! ${response.message}`, 'bg-red-500');
			return;
		}

		addToast('Droit ajouté avec succès !', 'bg-green-500');
		window.dispatchEvent(new CustomEvent('refresh'));
	}
</script>

<Card classes="m-4">
	<div slot="title">
		{#if isCreation}
			Ajout d'un droit à un utilisateur
		{:else if isEdition}
			Modification du droit de l'utilisateur {user?.name ?? ''}
		{/if}
	</div>

	<div class="flex-col space-y-2 w-1/3 p-4">
		<Combobox id="user"
							label="Utilisateur"
							options={users}
							bind:value={user}
							disabled={isEdition} />

		<Combobox id="right"
							label="Droit"
							options={rights}
							bind:value={right}
							disabled={isEdition} />

		{#key isEdition}
			<Select id="permission"
							label="Permission"
							multiple
							options={permissionsOptions}
							bind:value={permission} />
		{/key}
	</div>

	<div class="flex justify-center space-x-2">
		<ButtonPrimary on:click={saveRight}>
			<div class="m-2">
				{#if isEdition}
					Sauvegarder le droit
				{:else}
					Ajouter un droit
				{/if}
			</div>
		</ButtonPrimary>
		{#if canDelete}
			<ButtonPrimary on:click={() => showConfirmDeleteModal = true}>
				<div class="m-2">
					Supprimer le droit
				</div>
			</ButtonPrimary>
		{/if}
	</div>
</Card>


<Modal
	show={showConfirmDeleteModal}
	on:close={() => showConfirmDeleteModal = false}
	title="Suppression d'un droit d'un utilisateur"
>
	<div class="p-4">
		<p>Vous vous apprêtez à supprimer le droit {right.name} de l'utilisateur {user.name}.</p>
		<p>Êtes-vous sûr ?</p>
		<div class="flex justify-end gap-2 mt-4">
			<ButtonSecondary on:click={() => showConfirmDeleteModal = false}>
				Annuler
			</ButtonSecondary>
			<ButtonPrimary on:click={async () => {
				showConfirmDeleteModal = false;
				await removeRightFromUser(user.id, right.id);
				addToast('Droit supprimé avec succès', 'bg-green-500');
				openUrlInCurrentTab('/administration');
			}}>
				Supprimer le droit
			</ButtonPrimary>
		</div>
	</div>
</Modal>