<script>
	// Components
	import DataTable from '../../../components/DataTable.svelte';
	import ButtonPrimary from '../../../components/Button/ButtonPrimary.svelte';
	import LinkAction from '../../../components/Actions/LinkAction.svelte';
	import Modal from '../../../components/Modal.svelte';
	import ButtonSecondary from '../../../components/Button/ButtonSecondary.svelte';

	// Queries
	import { deleteUser, getUsers } from '../../../API/Backend/rights/UserQueries.js';

	// Utils
	import { openUrlInCurrentTab } from '../../../utils.js';

	const columns = [
		{ key: 'name', title: 'Nom' },
		{ key: 'email', title: 'Email' }
	];
	const actions = [
		{ component: LinkAction, props: { content: 'Modifier', action: 'edit' } },
		{ component: LinkAction, props: { content: 'Supprimer', action: 'delete' } },
	];
	const url = "administration/manage-user";

	let showConfirmDeleteModal = false;
	let user;

	function handleEdit(event) {
		let userID = event.detail.id;
		openUrlInCurrentTab(`${url}/${userID}`);
	}

	function handleDelete(event) {
		user = event.detail;
		showConfirmDeleteModal = true;
	}
</script>

<ButtonPrimary>
	<a href={url}>
		Créer utilisateur
	</a>
</ButtonPrimary>

<DataTable
	getQuery={getUsers}
	columns={columns}
	actions={actions}
	rowsPerPage={10}
	canGroupBy={false}
	on:edit={handleEdit}
	on:delete={handleDelete}
/>

<Modal
	show={showConfirmDeleteModal}
	on:close={() => showConfirmDeleteModal = false}
	title="Suppression d'un utilisateur"
>
	<div class="p-4">
		<p>Vous vous apprêtez à supprimer l'utilisateur {user.name} possédant l'email : {user.email}.</p>
		<p>Êtes-vous sûr ?</p>
		<div class="flex justify-end gap-2 mt-4">
			<ButtonSecondary on:click={() => showConfirmDeleteModal = false}>
				Annuler
			</ButtonSecondary>
			<ButtonPrimary on:click={async () => {
				showConfirmDeleteModal = false;
				await deleteUser(user.id);
				window.dispatchEvent(new CustomEvent('refresh'));
			}}>
				Supprimer l'utilisateur
			</ButtonPrimary>
		</div>
	</div>
</Modal>