import { cleanObject } from "./utils";
import { fetchData } from "./utils";

// STOCK
/**
 * Récupérer les document d'entrée en stock
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function getDocEntree(strictSearchFields,
                                    strictSearchValues,
                                    excludeSearchFields,
                                    excludeSearchValues,
                                    searchFields,
                                    searchValues,
                                    OrderDirection,
                                    pageNumber = 1,
                                    pageSize = 10,
                                    OrderBy) {
    const queryParams = {
        StrictSearchFields: strictSearchFields,
        StrictSearchValues: strictSearchValues,
        ExcludeSearchFields: excludeSearchFields,
        ExcludeSearchValues: excludeSearchValues,
        SearchFields: searchFields,
        SearchValues: searchValues,
        OrderDirection: OrderDirection,
        PageNumber: pageNumber,
        PageSize: pageSize,
        OrderBy: OrderBy
    };
    return await fetchData('/Stock/LME', queryParams); // GET request
}
/**
 * Récupérer les document de sortie de stock
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function getDocSortie(strictSearchFields,
                                    strictSearchValues,
                                    excludeSearchFields,
                                    excludeSearchValues,
                                    searchFields,
                                    searchValues,
                                    OrderDirection,
                                    pageNumber = 1,
                                    pageSize = 10,
                                    OrderBy) {
    const queryParams = {
        StrictSearchFields: strictSearchFields,
        StrictSearchValues: strictSearchValues,
        ExcludeSearchFields: excludeSearchFields,
        ExcludeSearchValues: excludeSearchValues,
        SearchFields: searchFields,
        SearchValues: searchValues,
        OrderDirection: OrderDirection,
        PageNumber: pageNumber,
        PageSize: pageSize,
        OrderBy: OrderBy
    };
    return await fetchData('/Stock/LMS', queryParams); // GET request
}
/**
 * Récupérer les document de transfert en stock
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function getDocTransfert(strictSearchFields,
                                    strictSearchValues,
                                    excludeSearchFields,
                                    excludeSearchValues,
                                    searchFields,
                                    searchValues,
                                    OrderDirection,
                                    pageNumber = 1,
                                    pageSize = 10,
                                    OrderBy) {
    const queryParams = {
        StrictSearchFields: strictSearchFields,
        StrictSearchValues: strictSearchValues,
        ExcludeSearchFields: excludeSearchFields,
        ExcludeSearchValues: excludeSearchValues,
        SearchFields: searchFields,
        SearchValues: searchValues,
        OrderDirection: OrderDirection,
        PageNumber: pageNumber,
        PageSize: pageSize,
        OrderBy: OrderBy
    };
    return await fetchData('/Stock/LMT', queryParams); // GET request
}

/**
 * Créer une nouvelle entrée en stock
 * @param {*} body 
 * @returns {Promise<{data: any, pagination: any}>}
 * 
 */
export async function newEntreeStock(body) {
    body = cleanObject(body); // Clean body before sending
    return await fetchData('/Stock/nouveau/entreeStock', {}, 'POST', body); // POST request
}

/**
 * Créer une nouvelle sortie en stock
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newSortieStock(body) {
    body = cleanObject(body); // Clean body before sending
    return await fetchData('/Stock/nouveau/sortieStock', {}, 'POST', body); // POST request
}

