<script>
	//Components
	import Modal from './Modal.svelte';
	import PasswordInput from './Input/PasswordInput.svelte';
	// Svelte
	import { createEventDispatcher } from 'svelte';
	//Store
	import { addToast } from '../store.js';

	import { changePassword } from '../API/Backend/rights/UserQueries.js';
	import { getConnectedUser } from '../API/Backend/api.js';

	export let show = false;

	const dispatch = createEventDispatcher();

	//Variables
	let oldPassword = '';
	let newPassword = '';
	let confirmPassword = '';
	let isSubmitting = false;

	function closeModal() {
		dispatch('close');
		oldPassword = '';
		newPassword = '';
		confirmPassword = '';
	}

	async function handleSubmit() {
		try {
			isSubmitting = true;

			const user = await getConnectedUser();
			console.log(user);
			const response = await changePassword(user.sub, oldPassword, newPassword, confirmPassword);

			if (!response.statusCode != 200) {
				addToast(response.message);
				return;
			}

			addToast('Mot de passe modifié avec succès', 'bg-green-500');
			closeModal();
		} catch (error) {
			addToast(error.message, 'bg-red-500');
		} finally {
			isSubmitting = false;
		}
	}
</script>

<Modal {show} title="Changer mon mot de passe" on:close={closeModal}>
	<form on:submit|preventDefault={handleSubmit} class="p-4 space-y-4">
		<PasswordInput
			id="oldPassword"
			label="Ancien mot de passe"
			bind:value={oldPassword}
			required={true}
		/>

		<PasswordInput
			id="newPassword"
			label="Nouveau mot de passe"
			bind:value={newPassword}
			required={true}
			pattern="^(?=.*[A-Z])(?=.*\d).{'{'}8,{'}'}$"
			description="Le mot de passe doit contenir au moins 8 caractères, une majuscule et un chiffre"
		/>

		<PasswordInput
			id="confirmPassword"
			label="Confirmer le nouveau mot de passe"
			bind:value={confirmPassword}
			required={true}
		/>

		<div class="flex justify-end space-x-3 pt-4">
			<button
				type="button"
				class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
				on:click={closeModal}
			>
				Annuler
			</button>
			<button
				type="submit"
				class="px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
				disabled={isSubmitting}
			>
				{isSubmitting ? 'Chargement...' : 'Enregistrer'}
			</button>
		</div>
	</form>
</Modal>
