import ArticleReferenceModel from './ArticleReferenceModel';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} IntituleDepotStockage - 
 * @property {string} Reference - 
 * @property {Array<ArticleReferenceModel>} ListeRefArticle - 
 */
class MouvementEntreeNewModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.IntituleDepotStockage = null;
    /** @type {string} */
    this.Reference = null;
    /** @type {Array<ArticleReferenceModel>} */
    this.ListeRefArticle = [];
  }
}

export default MouvementEntreeNewModel;
