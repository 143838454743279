import { fetchData } from './utils.js';
import { cleanObject } from './utils.js';

/**
 * 
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get all articles
 * @example
 * const articles = await getArticles();
 * console.log(articles);
 * 
 */
export async function getArticles(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

    return await fetchData('/article/LA', queryParams);
}

/**
 * 
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get All Article info libres
 */
export async function getInfoLibres() {
    return await fetchData('/article/infoLibres'); // GET request
}


/**
 * 
 * @param {*} body 
 * @returns  {Promise<{data: any, pagination: any}>}
 * @description Create a new article
 */
export async function newArticle(body) {
    body = cleanObject(body); // Ensure body is cleaned before sending
    return await fetchData('/article/nouveau', {}, 'POST', body); // POST request with body
}
