<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';

	// Client
	import { getClients } from '../../API/API_SAGE/ClientQueries';

	// Facture
	import { getFactureComptabilises } from '../../API/API_SAGE/DocVenteQueries';

	// Store
	import { addToast, openDrawer } from '../../store';

	// Svelte
	import Drawer from '../../components/Drawer.svelte';
	import ButtonAction from '../../components/Actions/ButtonAction.svelte';
	import { formatToCurrency, openUrlInCurrentTab } from '../../utils.js';
	import { onMount } from 'svelte';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';
	import { List } from '../../API/Backend/rights/permissions.js';

	const unselectedColumns = ['Adresse.CT_Adresse', 'Adresse.CT_CodePostal', 'Adresse.CT_Pays'];

	let columns = [
		{
			key: 'Urssaf',
			title: '',
			type: 'indicator',
			action: actionAPIIndicator,
			canSort: false,
			canSearch: false
		},
		{ key: 'CT_Intitule', title: 'Intitulé' },
		{ key: 'Adresse.CT_Adresse', title: 'Adresse', canSort: false, canSearch: false },
		{ key: 'Adresse.CT_CodePostal', title: 'Code Postal', canSort: false, canSearch: false },
		{ key: 'Adresse.CT_Ville', title: 'Ville', canSort: false, canSearch: false },
		{ key: 'Adresse.CT_Pays', title: 'Pays', canSort: false, canSearch: false },
		{ key: 'Telecom.CT_Telephone', title: 'Tél', canSort: false, canSearch: false },
		{ key: 'Telecom.CT_Telecopie', title: 'Tél 2', canSort: false, canSearch: false },
		{ key: 'Telecom.CT_EMail', title: 'Email', type: 'email', canSort: false, canSearch: false }
	];

	let actions = [{ component: ButtonAction, props: { content: 'Modifier', action: 'edition' } }];

	onMount(() => {
		connectedUserHasAccess('client', List);
	});

	function handleClick(message) {
		alert(message);
	}

	async function handleRowClick(event) {
		/** @type {TiersResponse} */
		const rowData = event.detail;
		let CA = await CalculCA(rowData);
		openDrawer({ rowData, CA });
	}

	/**
	 * @param {TiersResponse} client
	 * @returns {Promise<DocumentResponse[]>}
	 */
	async function getFacture(client) {
		const currentYear = new Date(Date.now()).getFullYear();

		try {
			await getFactureComptabilises('DO_TIERS,DO_DATE', `${client.CT_Num},${currentYear}`).then(
				(res) => {
					return res.data;
				}
			);
		} catch (error) {
			//
		}
	}

	/**
	 * @param {TiersResponse} client
	 * @returns {number}
	 */
	async function CalculCA(client) {
		const currentYear = new Date(Date.now()).getFullYear();
		let facturesComptabilises = [];
		try {
			facturesComptabilises = await getFacture(client).then((res) => {
				return res.data;
			});
		} catch (error) {
			//
		}

		if (facturesComptabilises.status === 404) return 0;

		let CA = 0;

		facturesComptabilises.forEach((facture) => {
			CA += facture.DO_TotalHT;
		});

		return CA;
	}

	function actionAPIIndicator(row) {
		let apiUrssaf = row.InfoLibres.find(res => res.Intitule === "LS_API");
		if (apiUrssaf) {
			switch(apiUrssaf.Valeur) {
				case "2": return 'bg-green-500';  // En attente de validation
				case "1": return 'bg-yellow-300';  // En cours d'inscription
				case "0": return 'bg-transparent'; // Non inscrit
				default: return 'bg-transparent';
			}
		}
	}

	function edit(event) {
		const data = event.detail;
		openUrlInCurrentTab(`/clients/modifier?${data.CT_Num}`);
	}
</script>

<DataTable
	onClick={handleClick}
	getQuery={getClients}
	{columns}
	unselectedColumnsKeys={unselectedColumns}
	rowsPerPage={10}
	{actions}
	on:rowClick={handleRowClick}
	on:edition={edit}
/>

<Drawer title="Détails du client">
	<div slot="drawerContent" let:data>
		<p>Numéro: {data.rowData.CT_Num}</p>
		<p>Intitulé: {data.rowData.CT_Intitule}</p>
		<p>Chiffre d'affaire: {formatToCurrency(data.CA)}</p>
		<p>Chiffre d'affaire Coop: {formatToCurrency(data.CA * 0.95)}</p>
	</div>
</Drawer>
