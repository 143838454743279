<script>
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import { fade } from 'svelte/transition';

    export let id;
    export let message = '';
    export let colorClass = 'bg-blue-500';
    export let removeToast;

    const isVisible = writable(true);
    const progress = writable(100);
    let interval;

    function closeToast() {
        isVisible.set(false);
        setTimeout(() => removeToast(id), 300); // Wait for fade out before removing
    }

    onMount(() => {
        interval = setInterval(() => {
            progress.update(n => {
                if (n > 0) {
                    return n - 0.5;
                } else {
                    clearInterval(interval);
                    closeToast();
                    return 0;
                }
            });
        }, 25);

        return () => clearInterval(interval);
    });

    $: if (!$isVisible) {
        clearInterval(interval);
    }
</script>

{#if $isVisible}
    <div class="fixed bottom-5 right-5 max-w-xs w-full p-4 rounded shadow-lg {colorClass}" in:fade={{ duration: 300 }} out:fade={{ duration: 300 }}>
        <div class="flex justify-between items-center">
            <span class="text-white">{message}</span>
            <button class="text-white ml-2" on:click={closeToast}>✕</button>
        </div>
        <div class="h-1 bg-gray-200 mt-2">
            <div class="h-full bg-white" style="width: {$progress}%"></div>
        </div>
    </div>
{/if}

<style>
    .fixed {
        transition: opacity 0.3s ease-in-out;
    }
</style>
