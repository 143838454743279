<script>
    import { fade } from 'svelte/transition';

    export let text = '';
    export let x = 0;
    export let y = 0;
</script>

<style>
    .tooltip {
        position: fixed;
        z-index: 1000;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.3s;
    }
</style>

    <div transition:fade={{ duration: 300 }} class="tooltip" style="left: {x + 20}px; top: {y + 20}px;">
    <slot>{text}</slot>
</div>
