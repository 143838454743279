<script>
	import { Router, Route } from 'svelte-routing';

	// pages for this layout
	import IndexArticle from '../views/article/IndexArticle.svelte';
	import ListeArticle from '../views/article/ListeArticle.svelte';
	import CreationArticle from '../views/article/CreationArticle.svelte';
	import NotFound from '../views/404.svelte';
</script>

<Router url="article">
	<Route path="/" component={IndexArticle} />
	<Route path="liste" component={ListeArticle} />
	<Route path="creation" component={CreationArticle} />
	<Route path="*" component={NotFound} />
</Router>
