<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';
	import Drawer from '../../components/Drawer.svelte';
	import ButtonAction from '../../components/Actions/ButtonAction.svelte';

	// Store
	import { getFacture, updateFactures } from '../../API/API_SAGE/DocVenteQueries.js';
	import { getFacturesAchat, newFactureAchat } from '../../API/API_SAGE/DocAchatQueries.js';
	import { addToast, openDrawer } from '../../store.js';
	import { onMount } from 'svelte';
	import { All } from '../../API/Backend/rights/permissions.js';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';
	import { sortKeysObject } from '../../utils.js';

	const columns = [
		{ key: 'DO_Valide', title: 'Validé', type: 'lock', action: row => row.DO_Valide },
		{ key: 'Urssaf', title: '', type: 'indicator', action: actionAPIIndicator, canSearch: false, canSort: false },
		{ key: 'DO_Piece', title: 'N° Piece' },
		{ key: 'CT_NumPayeur', title: 'Collaborateur' },
		{ key: 'Client.CT_Intitule', title: 'Intitule Client', canSort: false, canSearch: false },
		{ key: 'DO_Ref', title: 'Référence' },
		{ key: 'DO_Date', title: 'Date du document', type: 'date' },
		{ key: 'DO_TotalHT', title: 'Montant HT', canSort: false, canSearch: false },
		{ key: '', title: 'Montant COOP', display: actionCalculMontantCoop, canSort: false, canSearch: false },
		{ key: 'DO_TotalTTC', title: 'Montant TTC' },
		{ key: 'Reglement', title: '', type: 'indicator', action: actionPaymentIndicator, canSort: false, canSearch: false },
		{ key: 'DO_MontantRegle', title: 'Montant réglé', canSort: false, canSearch: false }
	];

	const bulkActions = [
		{ component: ButtonAction, props: { content: 'Cloture', action: 'closing', classes: 'py-2 text-sm mt-2' }},
	];

	const docLignesColumns = [
		{ key: 'Article.AR_Ref', title: 'Référence article' },
		{ key: 'DL_Design', title: 'Désignation' },
		{ key: 'DL_Qte', title: 'Quantité' },
		{ key: 'Conditionnement.EU_Enumere', title: 'Conditionnement' },
		{ key: 'DatePrestation.Valeur', title: 'Date de prestation' },
		{ key: 'DL_PrixUnitaire', title: 'PU HT' },
		{ key: 'DL_PUTTC', title: 'PU Net' }
	];

	onMount(() => {
		connectedUserHasAccess('cloture', All);
	});

	function actionAPIIndicator(row) {
		let apiUrssaf = row.Client.InfoLibres?.filter(res => res.Intitule === "LS_API")[0];
		let id = row.Client.InfoLibres?.filter(res => res.Intitule === "LS_ID")[0];
		if (id && id.Valeur !== null && id.Valeur !== '') {
			return 'bg-green-500';
		} else if (apiUrssaf) {
			if (apiUrssaf.Valeur === "2") {
				return 'bg-orange-300';
			} else if (apiUrssaf.Valeur === "1") {
				return 'bg-yellow-300';
			}
		} else {
			return 'bg-transparent';
		}
	}

	function actionPaymentIndicator(row) {
		if (row.DO_TotalTTC - row.DO_MontantRegle === 0) {
			return 'bg-green-500';
		} else if (row.DO_MontantRegle === 0) {
			return "bg-red-500";
		} else {
			return "bg-orange-300";
		}
	}

	function actionCalculMontantCoop(row) {
		let mtnCoop = (row.DO_TotalHT * 0.95).toFixed(2);

		return mtnCoop === 0 ? '' : mtnCoop;
	}

	async function handleFacture(
		strictSearchFields,
		strictSearchValues,
		excludeSearchFields,
		excludeSearchValues,
		searchFields,
		searchValues,
		OrderDirection,
		pageNumber,
		pageSize,
		OrderBy
	) {
		excludeSearchFields += excludeSearchFields ? ",REVERSE" : "REVERSE";
		excludeSearchValues += excludeSearchValues ? ",1" : "1";
		let result = await getFacture(
			strictSearchFields,
			strictSearchValues,
			excludeSearchFields,
			excludeSearchValues,
			searchFields,
			searchValues,
			OrderDirection,
			pageNumber,
			pageSize,
			OrderBy,
			true
		);

		return result;
	}

	async function handleClosing(event) {
		let documents = event.detail;
		if (documents.length === 0) {
			addToast('Veuillez sélectionner au moins une facture', 'bg-yellow-500');
			return;
		}
		let documentsByTier = Object.groupBy(documents, (doc) => doc.CT_NumPayeur);
		let errors = [];
		documentsByTier = sortKeysObject(documentsByTier);
		for (let tier in documentsByTier) {
			let docs = documentsByTier[tier];
			let articles = [];
			for (let doc of docs) {
				articles.push({
					Reference: 'ZREV',
					Designation: `${doc.Client.CT_Intitule} - ${doc.DO_Piece}`,
					Quantite: 1,
					PrixUnitaire: doc.DO_TotalHT * 0.95,
				});
			}
			let reference = tier.replace('411', '');
			let res = await getFacturesAchat('', '', '', '', 'DO_Piece', reference, 'DESC', 1, 5, 'DO_Piece').then(res => res.data);

			if (!Array.isArray(res)) {
				reference = reference + '1';
			} else {
				reference = reference + (Number(res[0].DO_Ref.replace(reference, '')) + 1);
			}
			let body = {
				DO_Piece: reference,
				CtNum: tier.replace('1', '0'),
				ListeRefArticle: articles,
			}
			let response = await newFactureAchat(body).then(res => res.data);
			if (response.status !== 200) {
				errors.push({ message: response.detail, docs: docs });
			}
		}

		if (errors.length > 0) {
			addToast(`Erreur lors de la création des facture d'achats ! ${errors.map(error => error.message).join(', ')}`, 'bg-yellow-500');
		}

		let body = documents.filter(doc => !errors.some(error => error.docs.includes(doc)))
			.map(doc => {
				return {
					NumPiece: doc.DO_Piece,
					DocumentVente: {
						InfoLibres: [{
							Intitule: "REVERSE",
							Valeur: "1"
						}]
					}
				}
			});

		let response = await updateFactures(body).then(res => res.data);

		if (response.status !== 200) {
			addToast(`Erreur lors de la modification des facture! ${response.detail}`, 'bg-red-500');
			return;
		}

		window.dispatchEvent(new CustomEvent('refresh'));
		addToast('Cloture effectuée avec succès', 'bg-green-500');
	}

	function handleRowClick(event) {
		const rowData = event.detail;
		openDrawer(rowData);
	}
</script>

<DataTable
	getQuery={handleFacture}
	{columns}
	rowsPerPage={10}
	on:closing={handleClosing}
	on:rowClick={handleRowClick}
	bulkActions={bulkActions}
/>

<Drawer title="Détails du document de vente" width="w-2/3" maxWidth="">
	<div slot="drawerContent" let:data>
		<!-- Drawer content -->
		{#if data.DO_Piece}
			<p>N° Piece: {data.DO_Piece}</p>
		{/if}
		{#if data.CT_Intitule}
			<p>Intitule Client: {data.Client.CT_Intitule}</p>
		{/if}
		{#if data.DO_Ref}
			<p>Référence: {data.DO_Ref}</p>
		{/if}
		{#if data.DO_Date}
			<p>Date du document: {data.DO_Date}</p>
		{/if}
		{#if data.DO_TotalHT}
			<p>Montant HT: {data.DO_TotalHT}</p>
		{/if}
		{#if data.DO_TotalTTC}
			<p>Montant TTC: {data.DO_TotalTTC}</p>
		{/if}
		{#if data.DO_MontantRegle}
			<p>Montant réglé: {data.DO_MontantRegle}</p>
		{/if}
		<!--						 Datatable with doc lines-->
		{#if data.DocLignes && data.DocLignes.length > 0}
			<h3 class="text-base font-semibold text-gray-700 mt-2">Lignes du document</h3>
			<hr class="mt-2" />
			<DataTable fromList={data.DocLignes} rowsPerPage={10} columns={docLignesColumns} />
		{/if}
	</div>
</Drawer>