import ArticleFournisseurModel from './ArticleFournisseurModel';
import ArticleParamComptaModel from './ArticleParamComptaModel';
import ChampStatModel from './ChampStatModel';
import InfoLibreModel from './InfoLibreModel';
import ArticleDepotModel from './ArticleDepotModel';
import ArticleTarifCategorieModel from './ArticleTarifCategorieModel';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Reference - 
 * @property {string} Design - 
 * @property {string} CodeFamille - 
 * @property {string} TypeFamille - 
 * @property {string} SuiviStock - 
 * @property {number} PrixVente - 
 * @property {string} CodeFiscal - 
 * @property {string} Pays - 
 * @property {Array<ArticleFournisseurModel>} Fournisseurs - 
 * @property {string} Catalogue1 - 
 * @property {string} Catalogue2 - 
 * @property {string} Catalogue3 - 
 * @property {string} Catalogue4 - 
 * @property {string} CodeBarre - 
 * @property {boolean} Sommeil - 
 * @property {boolean} Publie - 
 * @property {Array<ArticleParamComptaModel>} ParamComptas - 
 * @property {Array} Glossaires - 
 * @property {Array<ChampStatModel>} ChampsStats - 
 * @property {Array<InfoLibreModel>} InfoLibres - 
 * @property {Array<ArticleDepotModel>} Depots - 
 * @property {Array<ArticleTarifCategorieModel>} ArticleTarifCategories - 
 * @property {string} Nomenclature - 
 * @property {Array} NomenclatureComposant - 
 * @property {number} Nature - 
 * @property {number} DelaiLivraison - 
 * @property {number} prixAchat - 
 * @property {number} poidsBrut - 
 * @property {number} PoidsNet - 
 * @property {string} UnitePoids - 
 * @property {string} RefArticleSubstitution - 
 * @property {string} Langue1 - 
 * @property {string} Langue2 - 
 */
class ArticleNewModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Reference = null;
    /** @type {string} */
    this.Design = null;
    /** @type {string} */
    this.CodeFamille = null;
    /** @type {string} */
    this.TypeFamille = null;
    /** @type {string} */
    this.SuiviStock = null;
    /** @type {number} */
    this.PrixVente = null;
    /** @type {string} */
    this.CodeFiscal = null;
    /** @type {string} */
    this.Pays = null;
    /** @type {Array<ArticleFournisseurModel>} */
    this.Fournisseurs = [];
    /** @type {string} */
    this.Catalogue1 = null;
    /** @type {string} */
    this.Catalogue2 = null;
    /** @type {string} */
    this.Catalogue3 = null;
    /** @type {string} */
    this.Catalogue4 = null;
    /** @type {string} */
    this.CodeBarre = null;
    /** @type {boolean} */
    this.Sommeil = null;
    /** @type {boolean} */
    this.Publie = null;
    /** @type {Array<ArticleParamComptaModel>} */
    this.ParamComptas = [];
    /** @type {Array} */
    this.Glossaires = [];
    /** @type {Array<ChampStatModel>} */
    this.ChampsStats = [];
    /** @type {Array<InfoLibreModel>} */
    this.InfoLibres = [];
    /** @type {Array<ArticleDepotModel>} */
    this.Depots = [];
    /** @type {Array<ArticleTarifCategorieModel>} */
    this.ArticleTarifCategories = [];
    /** @type {string} */
    this.Nomenclature = null;
    /** @type {Array} */
    this.NomenclatureComposant = [];
    /** @type {number} */
    this.Nature = null;
    /** @type {number} */
    this.DelaiLivraison = null;
    /** @type {number} */
    this.prixAchat = null;
    /** @type {number} */
    this.poidsBrut = null;
    /** @type {number} */
    this.PoidsNet = null;
    /** @type {string} */
    this.UnitePoids = null;
    /** @type {string} */
    this.RefArticleSubstitution = null;
    /** @type {string} */
    this.Langue1 = null;
    /** @type {string} */
    this.Langue2 = null;
  }
}

export default ArticleNewModel;
