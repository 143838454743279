<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';
	import ButtonAction from '../../components/Actions/ButtonAction.svelte';
	import Modal from '../../components/Modal.svelte';
	import { Icon, ExclamationCircle, CheckCircle, XMark, Check } from 'svelte-hero-icons';

	// API
	import { getFacture } from '../../API/API_SAGE/DocVenteQueries';
	import { formatDateForDisplay } from '../../utils';
	import { getClientByReference } from '../../API/API_SAGE/ClientQueries';
	import {
		rechercherDemandesPaiement,
		transmettreDemandesPaiement
	} from '../../API/API_SAGE/UrssafQueries';
	import { addToast } from '../../store';
	import { onMount } from 'svelte';
	import { List } from '../../API/Backend/rights/permissions.js';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';

	const BATCH_SIZE = 10;

	const columns = [
		{ key: 'selected', title: '', type: 'checkbox', canSort: false, canSearch: false },
		{ key: 'Urssaf', title: '', type: 'indicator', action: actionAPIIndicator, canSort: false, canSearch: false },
		{ key: 'DO_Piece', title: 'N° Piece' },
		{ key: 'Collaborateur.CO_No', title: 'Coopérateur', canSort: false, canSearch: false },
		{ key: 'Client.CT_Intitule', title: 'Client', canSort: false, canSearch: false },
		{ key: 'DO_Date', title: 'Date' },
		{ key: 'DO_TotalHT', title: 'Montant HT' },
		{ key: 'DO_TotalTTC', title: 'Montant TTC' }
	];

	const bulkActions = [
		{
			component: ButtonAction,
			props: {
				content: 'Envoyer la sélection',
				action: 'sendBulk',
				classes: 'py-2 text-sm mt-2'
			}
		},
		{
			component: ButtonAction,
			props: {
				content: 'Vérifier la sélection',
				action: 'check',
				classes: 'py-2 text-sm mt-2'
			}
		}
	];

	let actions = [
		{
			component: ButtonAction,
			props: {
				content: 'Envoyer à l\'URSSAF',
				action: 'sendToUrssaf',
				condition: (row) => {
					// Vérifier uniquement que la facture n'a pas déjà été transmise
					const idPaiement = row.InfoLibres?.find(info => info.Intitule === "LS_ID_PAIEMENT")?.Valeur;
					return !idPaiement;
				}
			}
		}
	];
	let showCheckModal = false;
	let messages = [];

	onMount(() => {
		connectedUserHasAccess('urssaf', List);
	});

	function actionAPIIndicator(row) {
		// Vérifier uniquement si la facture a déjà été envoyée
		let idPaiement = row.InfoLibres?.find(res => res.Intitule === "LS_ID_PAIEMENT");
		if (idPaiement?.Valeur) {
			return 'bg-green-500';   // Facture déjà transmise
		}
		return 'bg-yellow-300';      // Facture à transmettre
	}

	async function getFacturesForUrssaf(strictSearchFields, strictSearchValues, excludeSearchFields, excludeSearchValues, searchFields, searchValues, OrderDirection, pageNumber = 1, pageSize = 10, OrderBy) {
		try {
			const response = await getFacture('CT.LS_API', '2', excludeSearchFields, excludeSearchValues, searchFields, searchValues, OrderDirection, pageNumber, pageSize, OrderBy, false);

			if (response.data) {
				// Enrichir les données avec les informations client
				for (let facture of response.data) {
					facture.DO_Date = formatDateForDisplay(facture.DO_Date);
					facture.Client = await getClientByReference(facture.Client.CT_Num).then(res => res.data);
				}
			}

			return response;
		} catch (error) {
			console.error('Erreur lors de la récupération des factures:', error);
			return { data: [] };
		}
	}

	let showResultModal = false;
	let resultMessages = [];

	async function handleSendToUrssaf(event) {
		const facture = event.detail;
		try {
			const response = await transmettreDemandesPaiement([{
				CT_Num: facture.Client.CT_Num,
				DO_Piece: facture.DO_Piece
			}]);

			processResponse(response.data);
		} catch (error) {
			console.error('Erreur lors de l\'envoi à l\'URSSAF:', error);
			addToast('Erreur lors de la transmission à l\'URSSAF', 'bg-red-500');
		}
	}

	async function handleSendBatch(factures) {
		const payload = factures.map(facture => ({
			CT_Num: facture.Client.CT_Num,
			DO_Piece: facture.DO_Piece
		}));

		try {
			const response = await transmettreDemandesPaiement(payload);
			processResponse(response.data);
			return response.data.every(result => result.idDemandePaiement);
		} catch (error) {
			console.error('Erreur lors de l\'envoi du lot à l\'URSSAF:', error);
			addToast('Erreur lors de la transmission du lot à l\'URSSAF', 'bg-red-500');
			return false;
		}
	}

	function processResponse(data) {
		resultMessages = data.map(result => ({
			facture: result.numFactureTiers,
			success: !!result.idDemandePaiement,
			idDemande: result.idDemandePaiement,
			errors: result.errors || []
		}));
		showResultModal = true;

		// Si au moins une facture a été transmise avec succès
		if (resultMessages.some(msg => msg.success)) {
			window.dispatchEvent(new CustomEvent('refresh'));
		}
	}

	async function handleSendSelected(event) {
		let selectedFactures = event.detail;
		if (selectedFactures.length === 0) {
			addToast('Veuillez sélectionner au moins une facture', 'bg-yellow-500');
			return;
		}

		const batches = [];
		for (let i = 0; i < selectedFactures.length; i += BATCH_SIZE) {
			batches.push(selectedFactures.slice(i, i + BATCH_SIZE));
		}

		let successCount = 0;
		for (const batch of batches) {
			const success = await handleSendBatch(batch);
			if (success) successCount += batch.length;
		}

		if (successCount > 0) {
			window.dispatchEvent(new CustomEvent('refresh'));
		}
	}

	async function handleCheck(event) {
		let selectedFactures = event.detail;
		if (selectedFactures.length === 0) {
			addToast('Veuillez sélectionner au moins une facture', 'bg-yellow-500');
			return;
		}

		const response = await rechercherDemandesPaiement({
			NumFactureTiers: selectedFactures.map(facture => facture.DO_Piece)
		}).then(res => res.data);

		if (response.errors != null) {
			messages = response.errors.map(error => ({
				title: error.message,
				message: error.description,
				isError: true
			}));
		}

		if (response.infoDemandePaiements === undefined) {
			messages = response.map(error => ({
				title: error.message,
				message: error.description,
				isError: true
			}));
		} else {
			messages.push(...response.infoDemandePaiements.map(info => {
				return {
					title: info.demandePaiement.NumFactureTiers,
					message : `${info.statut.libelle}`,
				}
			}));
		}

		showCheckModal = true;
	}
</script>


<DataTable
	getQuery={getFacturesForUrssaf}
	{columns}
	{actions}
	rowsPerPage={10}
	on:sendToUrssaf={handleSendToUrssaf}
	on:sendBulk={handleSendSelected}
	on:check={handleCheck}
	bulkActions={bulkActions}
/>



<Modal
	show={showResultModal}
	title="Résultats de la transmission URSSAF"
	on:close={() => showResultModal = false}
>
	<div class="p-6 space-y-6">
		<div class="space-y-4">
			{#each resultMessages as result}
				<div class="border rounded-lg p-4 {result.success ? 'border-green-200 bg-green-50' : 'border-red-200 bg-red-50'}">
					<div class="flex items-center gap-2 mb-2">
						<Icon
							src={result.success ? CheckCircle : ExclamationCircle}
							class="h-5 w-5 {result.success ? 'text-green-500' : 'text-red-500'}"
						/>
						<h3 class="font-medium">Facture {result.facture}</h3>
					</div>

					{#if result.success}
						<p class="text-sm text-green-700">
							Demande de paiement transmise avec succès
							<br>
							ID de la demande : {result.idDemande}
						</p>
					{:else}
						<div class="space-y-2">
							{#each result.errors as error}
								<div class="flex items-start gap-2">
									<Icon src={XMark} class="h-4 w-4 text-red-500 mt-1" />
									<div>
										<p class="text-sm font-medium text-red-700">{error.code}</p>
										<p class="text-sm text-red-600">{error.message}</p>
										{#if error.description}
											<p class="text-xs text-red-500">{error.description}</p>
										{/if}
									</div>
								</div>
							{/each}
						</div>
					{/if}
				</div>
			{/each}
		</div>
	</div>

	<div class="bg-gray-50 px-6 py-4 flex justify-end rounded-b-lg">
		<button
			type="button"
			class="bg-gray-100 text-gray-700 hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-medium transition-colors"
			on:click={() => showResultModal = false}
		>
			Fermer
		</button>
	</div>
</Modal>

<Modal show={showCheckModal} title="Résultats de la vérification" on:close={() => showCheckModal = false}>
	<div class="p-6 space-y-6">
		<div class="flex items-start">
			<div class="ml-3 w-full">
				<ul class="space-y-3">
					{#each messages as message}
						<li>
							<div class="bg-{message.isError ? 'red' : 'green' }-50 p-4 rounded-lg flex items-start">
                                <span class="flex-shrink-0 h-5 w-5 text-{message.isError ? 'red' : 'green' }-400">
                                    <Icon src={message.isError ? XMark : Check} />
                                </span>
								<div class="ml-2">
									<span class="text-sm font-medium text-gray-900">{message.title}</span>
									<p class="text-sm text-gray-600">{message.message}</p>
									{#if message.description}
										<p class="text-xs text-{message.isError ? 'red' : 'green' }-500">{message.description}</p>
									{/if}
								</div>
							</div>
						</li>
					{/each}
				</ul>
			</div>
		</div>
	</div>
	<div class="bg-gray-50 px-6 py-4 flex justify-end rounded-b-lg">
		<button
			type="button"
			class="bg-gray-100 text-gray-700 hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-medium transition-colors"
			on:click={() => showCheckModal = false}
		>
			Fermer
		</button>
	</div>
</Modal>