<script>
	import { Router, Route } from 'svelte-routing';

	// pages for this layout
	import IndexStock from '../views/stock/IndexStock.svelte';
	import MouvementStock from '../views/stock/MouvementStock.svelte';
	import NotFound from '../views/404.svelte';
</script>

<Router url="stock">
	<Route path="/" component={IndexStock} />
	<Route path="mouvement" component={MouvementStock} />
	<Route path="*" component={NotFound} />
</Router>
