import { cleanObject } from './utils.js';
import { fetchData } from './utils.js';

// DEVIS
export async function getDevis(searchFields = '', searchValues = '', pageNumber = 1, pageSize = 1000) {
    const queryParams = {
        SearchFields: searchFields,
        SearchValues: searchValues,
        PageNumber: pageNumber,
        PageSize: pageSize
    };

    return await fetchData('/Devis/LDE', queryParams); // Fetch with pagination
}

/**
 * Créer un nouveau devis
 * @param {*} body 
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newDevis(body) {
	body = cleanObject(body); // Clean body before sending
	return await fetchData('/Devis/nouveau', {}, 'POST', body); // POST request
}

// BON COMMANDE

/**
 * Créer un nouveau bon de commande
 * @param {*} body 
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newBonCommande(body) {
	body = cleanObject(body); // Clean body before sending
	return await fetchData('/BonCommande/nouveau', {}, 'POST', body); // POST request
}

// FACTURE COMPTABILISE

/**
 * Récupérer les factures comptabilisées
 * @param {string} searchFields
 * @param {string} searchValues
 * @param {number} pageNumber
 * @param {number} pageSize
 * @returns {Promise<{data: any, pagination: any}>}
 * */

export async function getFactureComptabilises(searchFields = '', searchValues = '', pageNumber = 1, pageSize = 1000) {
    const queryParams = {
        SearchFields: searchFields,
        SearchValues: searchValues,
        PageNumber: pageNumber,
        PageSize: pageSize
    };
    
    return await fetchData('/FactureComptabilise/LFAC', queryParams); // Fetch with pagination
}
 