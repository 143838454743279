import { cleanObject, fetchData } from './utils.js';


export async function getFacturesAchat(
	strictSearchFields = '',
	strictSearchValues = '',
	excludeSearchFields = '',
	excludeSearchValues = '',
	searchFields = '',
	searchValues = '',
	OrderDirection = '',
	pageNumber = 1,
	pageSize = 10,
	OrderBy = ''
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};
	return await fetchData('achat/Facture/LFA', queryParams); // Fetch with pagination
}

/**
 * Créer une nouvelle facture d'achat
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newFactureAchat(body) {
	body = cleanObject(body); // Clean body before sending
	return await fetchData('achat/Facture/nouveau', {}, 'POST', body); // POST request
}