<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
	<div class="text-center">
		<p class="font-semibold text-primary text-4xl">401</p>
		<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
			Vous n'êtes pas autorisé à accéder à cette page
		</h1>
		<p class="mt-6 text-base leading-7">Veuillez vous connecter</p>
	</div>
	<button class="w-44 bg-black mt-10 flex items-center justify-center gap-x-6 rounded-md ">
		<a href="/auth/login" class="w-full text-primaryLight rounded-md px-3.5 py-2.5 text-base font-semibold shadow-sm"
			>Page de connexion</a
		>
	</button>
</main>
