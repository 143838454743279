<script>
	// COMPONENTS
	import Input from '../../Input/Input.svelte';
	import DoubleInput from '../../Input/DoubleInput.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';
	import DateInput from '../../Input/DateInput.svelte';
	import Combobox from '../../Combobox/Combobox.svelte';
	import StepByStep from '../../Steps/StepByStep.svelte';
	import Card from '../../Card.svelte';
	import InfoButton from '../../Button/InfoButton.svelte';
	// DOCUMENT VENTE
	import {
		newDevis,
		newBonCommande,
		newFacture,
		getDevisByNumPiece,
		getFactureByNumPiece
	} from '../../../API/API_SAGE/DocVenteQueries.js';
	import DocumentVenteCreationModel from '../../../API/API_SAGE/model/DocumentVenteCreationModel.js';
	//CLIENT
	import { getClientByReference, getClients } from '../../../API/API_SAGE/ClientQueries.js';
	// UNITE VENTE
	import { getUniteVenteByReference } from '../../../API/API_SAGE/UniteVenteQueries.js';
	// SVELTE
	import { onMount, onDestroy } from 'svelte';
	// STORE
	import CheckBox from '../../Input/CheckBox.svelte';
	import { addToast, DOCVENTETYPES } from '../../../store.js';
	import { getArticleByReference, getArticles } from '../../../API/API_SAGE/ArticleQueries.js';
	import {
		formatDateForDisplay,
		formatDateForInput,
		formatToCurrency,
		getVentePDFBlob,
		openUrlInCurrentTab
	} from '../../../utils.js';
	import { getConnectedUser } from '../../../API/Backend/api.js';
	import { getCollaborateurByNum } from '../../../API/API_SAGE/CollaborateurQueries.js';
	import { calculateMontant } from '../../../API/API_SAGE/utils.js';
	import Select from '../../Select/Select.svelte';

	// VARIABLES
	export let docType = '';
	let clients = [];
	let clientOptions = [{ value: '', label: '' }];
	let isEdition = [];
	let article;
	let client;
	let taxeDetails = {};
	let totalHT = 0;
	let totalTTC = 0;
	let totalTaxe = 0;
	let doPiece = '';
	let isComment = false;
	let documentCreated;
	let pdfUrl;
	let prestationComboBox;
	let adressesLivraisonOptions = [];
	let adresseLivraison;

	// MODELS
	$: docVenteBody = new DocumentVenteCreationModel();
	$: if (docVenteBody.CtNum && docVenteBody.CtNum !== client?.CT_Num) {
		(async () => {
			client = await getClientByReference(docVenteBody.CtNum).then((res) => res.data);
		})();
	}

	// DOC LIGNES
	let DOCL_RefArticle = '';
	let DOCL_Qte = 1;
	let DOCL_PU = '';
	let DOCL_DesignArticle = '';
	let DOCL_minPU;
	let DOCL_uniteVente;
	let DOCL_DatePrestation = formatDateForInput(new Date());

	const steps = [
		{ href: '#', label: 'En-tête' },
		{ href: '#', label: 'Ligne' },
		{ href: '#', label: 'Récapitulatif' }
	];
	const columns = [
		{ key: 'Reference' },
		{ key: 'Designation' },
		{ key: 'Quantite', isDouble: true },
		{ key: 'PrixUnitaire', isCurrency: true },
		{ key: 'DatePrestation', isDate: true}
	];

	// FUNCTIONS
	onMount(async () => {
		docVenteBody.ListeRefArticle = [];

		let date = new Date().toISOString().split('T')[0];
		docVenteBody.Date = date;
		docVenteBody.DO_DateLivr = date;
		let user = await getConnectedUser();

		if (user.sageLink) {
			docVenteBody.TierPayeur = await getCollaborateurByNum(user.sageLink).then(
				(res) => res.data.CO_Matricule
			);
		}

		docVenteBody.Status = 'Accepte';
		docVenteBody.CtNum = clientOptions[0].value;
	});

	async function updateClient() {
		await getClientByReference(docVenteBody.CtNum).then((res) => {
			client = res.data;
		});
		adressesLivraisonOptions = [];
		if (client.AdressesLivraison) {
			for (const adresse of client.AdressesLivraison) {
				adressesLivraisonOptions.push({
					value: adresse.LI_Intitule,
					label: `${adresse.LI_Adresse} - ${adresse.LI_CodePostal} ${adresse.LI_Ville}`
				});
			}
			adresseLivraison = adressesLivraisonOptions[0].value;
		}
	}

	async function createDocVente(event) {
		event.preventDefault();
		if (docType === DOCVENTETYPES.FACTURE && totalTTC === 0) {
			addToast('Veuillez ajouter des lignes au document', 'bg-yellow-500');
			window.dispatchEvent(new CustomEvent('reset-loading'));
			return;
		}

		docVenteBody.Date = formatDateForInput(docVenteBody.Date);
		docVenteBody.ListeRefArticle.forEach((ligne) => {
			delete ligne.MontantHT;
			delete ligne.Taxe;
			delete ligne.MontantTTC;
			let infoLibres = [];
			if (ligne.DatePrestation) {
				infoLibres.push({
					Intitule: 'LS_DATE_PRESTATION',
					Valeur: formatDateForInput(ligne.DatePrestation)
				});
			}
			ligne.InfoLibres = infoLibres;
		});

		let bufferAdresse = client.AdressesLivraison.find(adresse => adresse.LI_Intitule === adresseLivraison);
		docVenteBody.LivraisonAdresse = {
			LI_Intitule: bufferAdresse.LI_Intitule,
			Adresse:{
				Adresse: bufferAdresse.LI_Adresse,
				Complement: bufferAdresse.LI_Complement,
				CodePostal: bufferAdresse.LI_CodePostal,
				Ville: bufferAdresse.LI_Ville,
				Pays: bufferAdresse.LI_Pays
			}
		}
		let response;
		try {
			if (docType === DOCVENTETYPES.DEVIS) response = (await newDevis(docVenteBody)).data;
			if (docType === DOCVENTETYPES.COMMANDE) response = (await newBonCommande(docVenteBody)).data;
			if (docType === DOCVENTETYPES.FACTURE) response = (await newFacture(docVenteBody)).data;

			if (response.status === 200) {
				doPiece = response.detail.split(' ')[2];
				switch (docType) {
					case DOCVENTETYPES.DEVIS:
						documentCreated = await getDevisByNumPiece(doPiece).then((res) => res.data);
						break;
					case DOCVENTETYPES.FACTURE:
						documentCreated = await getFactureByNumPiece(doPiece).then((res) => res.data);
						break;
					default:
						throw new Error('Type de document non supporté');
				}
				const { blob: pdfBlob, filename } = await getVentePDFBlob(documentCreated);
				pdfUrl = URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));

				window.dispatchEvent(new CustomEvent('final-step'));
				addToast(response.detail, 'bg-green-500');
			} else {
				addToast(response.detail, 'bg-red-500');
				window.dispatchEvent(new CustomEvent('reset-loading'));
			}
		} catch (error) {
			addToast('Erreur innatendue lors de la création du document de vente', 'bg-red-500');
			window.dispatchEvent(new CustomEvent('reset-loading'));
		}
	}
	async function addDocLigne() {
		if (isComment) {
			docVenteBody = {
				...docVenteBody,
				ListeRefArticle: [
					...docVenteBody.ListeRefArticle,
					{
						Reference: '',
						Designation: DOCL_DesignArticle.trim(),
						Quantite: 0,
						PrixUnitaire: 0,
						MontantHT: 0,
						Taxe: 0,
						MontantTTC: 0,
						UniteVente: ''
					}
				]
			};
			isEdition.push(false);
			DOCL_DesignArticle = '';
			return;
		}

		client =
			client.CT_Num === docVenteBody.CtNum
				? client
				: await getClientByReference(docVenteBody.CtNum).then((res) => res.data);
		article =
			article?.AR_Ref === DOCL_RefArticle
				? article
				: await getArticleByReference(DOCL_RefArticle).then((res) => res.data);
		let [montantHT, montantTaxe, montantTTC, taxeIntitule] = calculateMontant(article, client, {
			Quantite: DOCL_Qte,
			PrixUnitaire: DOCL_PU,
			PrixTTC: article.AR_PrixTTC
		});

		let articleBody =
			article.AR_PrixTTC === 1
			? {
					Reference: DOCL_RefArticle.trim(),
					Designation: DOCL_DesignArticle.trim(),
					Quantite: DOCL_Qte,
					PUTTC: DOCL_PU,
					MontantHT: montantHT,
					Taxe: montantTaxe,
					MontantTTC: montantTTC,
					UniteVente: DOCL_uniteVente,
					DatePrestation: DOCL_DatePrestation
				}
			: {
					Reference: DOCL_RefArticle.trim(),
					Designation: DOCL_DesignArticle.trim(),
					Quantite: DOCL_Qte,
					PrixUnitaire: DOCL_PU,
					MontantHT: montantHT,
					Taxe: montantTaxe,
					MontantTTC: montantTTC,
					UniteVente: DOCL_uniteVente,
					DatePrestation: DOCL_DatePrestation
				};

		docVenteBody = {
			...docVenteBody,
			ListeRefArticle: [
				...docVenteBody.ListeRefArticle,
				articleBody
			]
		};

		taxeDetails[taxeIntitule] = taxeDetails[taxeIntitule] ?? 0;
		taxeDetails[taxeIntitule] += montantTaxe;
		isEdition.push(false);
		calculateTotaux();
		DOCL_RefArticle = '';
		DOCL_DesignArticle = '';
		DOCL_PU = '';
		DOCL_Qte = 1;
		DOCL_uniteVente = '';
		prestationComboBox.reset();
		DOCL_DatePrestation = formatDateForInput(new Date());
	}

	async function removeDocLigne(index) {
		docVenteBody = {
			...docVenteBody,
			ListeRefArticle: docVenteBody.ListeRefArticle.filter((_, i) => i !== index)
		};
		isEdition.splice(index, 1);
		await setEditionLines(index);
		calculateTotaux();
	}

	async function edit(index) {
		isEdition[index] = true;
		await setEditionLines();
	}

	async function saveEdit(index) {
		isEdition[index] = false;
		await setEditionLines();

		if (docVenteBody.ListeRefArticle[index].Reference === '') {
			return;
		}

		let editedLine = docVenteBody.ListeRefArticle[index];
		client =
			client.CT_Num === docVenteBody.CtNum
				? client
				: await getClientByReference(docVenteBody.CtNum).then((res) => res.data);
		article =
			article?.AR_Ref === editedLine.Reference
				? article
				: await getArticleByReference(editedLine.Reference).then((res) => res.data);
		let [montantHT, montantTaxe, montantTTC, taxeIntitule] = calculateMontant(article, client, {
			Quantite: editedLine.Quantite,
			PrixUnitaire: article.AR_PrixTTC === 1 ? editedLine.PUTTC : editedLine.PrixUnitaire,
			PrixTTC: article.AR_PrixTTC
		});
		let previousTaxe = editedLine.Taxe;
		editedLine.MontantHT = montantHT;
		editedLine.Taxe = montantTaxe;
		editedLine.MontantTTC = montantTTC;
		docVenteBody.ListeRefArticle[index] = editedLine;
		taxeDetails[taxeIntitule] += montantTaxe - previousTaxe;
		calculateTotaux();
	}

	async function setEditionLines(lineRemoved = -1) {
		for (const [index, line] of docVenteBody.ListeRefArticle.entries()) {
			let originalLine;
			if (lineRemoved > -1 && index >= lineRemoved) {
				originalLine = docVenteBody.ListeRefArticle[index];
			}
			if (isEdition[index]) {
				let excludeColumns = ['Reference', 'MontantHT'];
				if (line.Reference !== '') {
					excludeColumns.push('Designation');
					article = await getArticleByReference(line.Reference).then(
						(res) => res.data
					);
				} else {
					excludeColumns.push('Quantite', 'PrixUnitaire');
				}

				if (docType === DOCVENTETYPES.DEVIS) {
					excludeColumns.push('DatePrestation');
				}
				columns
					.filter((column) => !excludeColumns.includes(column.key))
					.forEach((column) => {
						let td = document.getElementById(`${column.key}-${index}`);
						td?.children[0].remove();
						if (column.isCurrency || column.isDouble) {
							new DoubleInput({
								target: td,
								props: {
									value: column.key === 'PrixUnitaire'
										? (docVenteBody.ListeRefArticle[index].PrixUnitaire ?? docVenteBody.ListeRefArticle[index].PUTTC)
										: docVenteBody.ListeRefArticle[index][column.key],
									min: column.isCurrency ? article.AR_PrixVen : 0,
								}
							});
						} else if (column.isDate) {
							new DateInput({
								target: td,
								props: {
									value: docVenteBody.ListeRefArticle[index][column.key]
								}
							})
						} else {
							new Input({
								target: td,
								props: {
									value: docVenteBody.ListeRefArticle[index][column.key]
								}
							});
						}
					});
			} else {
				columns.filter((col) => {
					if (docType === DOCVENTETYPES.DEVIS) {
						return col.key !== 'DatePrestation';
					}
				}).forEach((column) => {
					let td = document.getElementById(`${column.key}-${index}`);
					let input = td?.children[0]?.children[0];
					if (column.isDate) {
						input = input.children[0];
					}
					if (input === undefined) {
						switch (true) {
							case column.isCurrency:
								td.innerHTML = `<p> ${line[column.key] === 0 ? '' : formatToCurrency(line[column.key])} </p>`;
								break;
							case column.isPercent:
								td.innerHTML = `<p> ${line[column.key] === 0 ? '' : `${line[column.key]} %`}</p>`;
								break;
							case column.isDate:
								td.innerHTML = `<p> ${line[column.key] ? formatDateForDisplay(line[column.key]) : ''} </p>`;
								break;
							default:
								td.innerHTML = `<p> ${line[column.key] === 0 ? '' : line[column.key]} </p>`;
								break;
						}
						return;
					}

					if (line.Reference === '') {
						if (column.key === 'Designation') {
							if (originalLine) {
								line[column.key] = originalLine[column.key];
							} else {
								line[column.key] = input.value ?? docVenteBody.ListeRefArticle[index][column.key];
							}
						}
					} else {
						if (column.key !== 'Designation') {
							if (originalLine) {
								line[column.key] = originalLine[column.key];
							} else {
								line[column.key] = input.value ?? docVenteBody.ListeRefArticle[index][column.key];
								if (column.key === 'PrixUnitaire') {
									line['PUTTC'] = line[column.key];
								}
							}
						}
					}

					switch (true) {
						case column.isCurrency:
							td.innerHTML = `<p> ${line[column.key] === 0 ? '' : formatToCurrency(line[column.key])} </p>`;
							break;
						case column.isPercent:
							td.innerHTML = `<p> ${line[column.key] === 0 ? '' : `${line[column.key]} %`}</p>`;
							break;
						case column.isDate:
							td.innerHTML = `<p> ${line[column.key] ? formatDateForDisplay(line[column.key]) : ''} </p>`;
							break;
						default:
							td.innerHTML = `<p> ${line[column.key] === 0 ? '' : line[column.key]} </p>`;
							break;
					}
				});
			}
		}
	}

	function calculateTotaux() {
		totalHT = docVenteBody.ListeRefArticle.reduce(
			(acc, ligne) => acc + parseFloat(ligne.MontantHT ?? 0),
			0
		);
		totalTTC = docVenteBody.ListeRefArticle.reduce(
			(acc, ligne) => acc + parseFloat(ligne.MontantTTC ?? 0),
			0
		);
		totalTaxe = docVenteBody.ListeRefArticle.reduce(
			(acc, ligne) => acc + parseFloat(ligne.Taxe ?? 0),
			0
		);
	}

	function editDocVente() {
		let category;
		switch (docType) {
			case DOCVENTETYPES.DEVIS:
				category = 'devis';
				break;
			case DOCVENTETYPES.FACTURE:
				category = 'facture';
				break;
			default:
				break;
		}
		openUrlInCurrentTab(`/ventes/${category}/${doPiece}`);
	}

	async function clientQuery(searchTerm = null) {
		return await getClients(
			'',
			'',
			'',
			'',
			searchTerm ? 'ct_intitule' : '',
			searchTerm ?? '',
			'',
			1,
			100
		).then((res) => {
			clients = res.data;
			if (!Array.isArray(clients)) {
				return [];
			}
			return clients.map((client) => ({
				value: client.CT_Num,
				label: client.CT_Intitule
			}));
		});
	}

	async function articleQuery(searchTerm = null) {
		return await getArticles(
			'',
			'',
			'',
			'',
			searchTerm ? 'AR_Design' : '',
			searchTerm ?? '',
			'',
			1,
			1000,
			''
		).then((res) => {
			let articles = res.data;
			if (articles.status && articles.status === 404) {
				return [];
			}
			return articles.map((article) => ({ value: article.AR_Ref, label: article.AR_Design }));
		});
	}

	onDestroy(() => {
		if (pdfUrl) {
			URL.revokeObjectURL(pdfUrl);
		}
	});
</script>

<div class="flex flex-col justify-between gap-4 pb-4 h-full p-4">
	<div class="flex justify-center space-y-1.5">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Création d'un document de vente - {docType}
		</h1>
	</div>

	<div class="flex justify-center px-5">
		<Card title="Totaux" classes="w-1/3">
			<div>
				Total HT : {formatToCurrency(totalHT)}
			</div>
			<div class="flex gap-2 items-center">
				<p>TVA : {formatToCurrency(totalTaxe)}</p>
				{#key docVenteBody.ListeRefArticle}
					<InfoButton>
						<div>
							<p>Details des taxes</p>
							{#each Object.entries(taxeDetails) as [label, montant]}
								<p>{label} : {formatToCurrency(montant)}</p>
							{/each}
						</div>
					</InfoButton>
				{/key}
			</div>
			<div>
				Total TTC : {formatToCurrency(totalTTC)}
			</div>
		</Card>
	</div>

	<!-- FORM -->
	<StepByStep {steps} let:currentStep on:create={createDocVente}>
		<!-- Formulaire pour l'entête -->
		<form>
			{#if currentStep === 0}
				<div class="space-y-6">
					<div class="grid grid-cols-3 gap-4">
						<Combobox
							id="CtNum"
							label="Nom du client"
							placeholder="Nom du client"
							query={clientQuery}
							bind:value={docVenteBody.CtNum}
							on:change={updateClient}
							required
						/>
						<div>
							{#if docVenteBody.CtNum}
								<Select
									id="adressesPrestation"
									label="Adresse de prestation"
									options={adressesLivraisonOptions}
									bind:value={adresseLivraison}
								/>
							{/if}
						</div>

						{#if docType !== DOCVENTETYPES.DEVIS}
							<Input
								id="reference"
								placeholder="REF123"
								label="Référence"
								bind:value={docVenteBody.Reference}
								required
								minlength="3"
								maxlength="15"
							/>
						{/if}
					</div>
					<div class="grid grid-cols-3 gap-4">
						<DateInput id="date" label="Date {docType}" bind:value={docVenteBody.Date} />
					</div>
				</div>
			{/if}

			<!-- Formulaire pour les lignes -->
			{#if currentStep === 1}
				<div class="p-6 space-y-4">
					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2 col-span-2 flex items-center space-x-3">
							{#if isComment}
								<Input label="Commentaire" bind:value={DOCL_DesignArticle} required maxlength={69} />
							{:else}
								<Combobox
									id="ar_ref"
									label="Prestation"
									placeholder="Prestation"
									query={articleQuery}
									bind:this={prestationComboBox}
									bind:value={DOCL_RefArticle}
									on:change={async () => {
										article = await getArticleByReference(DOCL_RefArticle).then((res) => res.data);
										DOCL_DesignArticle = article.AR_Design ?? '';
										DOCL_PU = article.AR_PrixVen ?? '';
										DOCL_minPU = article.AR_PrixVen ?? '';
										let uniteVente = await getUniteVenteByReference(article.AR_UniteVen).then(
											(res) => res.data ?? ''
										);
										DOCL_uniteVente = uniteVente.U_Intitule;
									}}
									required
									minlength="3"
									maxlength="10"
								/>
							{/if}
							<div class="flex items-end h-full">
								<CheckBox id="isComment" label="Commentaire" bind:checked={isComment} />
							</div>
						</div>

						{#if !isComment}
							<div class="space-y-2">
								<DoubleInput
									id="qte"
									placeholder="1"
									label="Quantité"
									bind:value={DOCL_Qte}
									type="number"
									required
								/>
							</div>

							<div class="space-y-2">
								<Input
									id="uniteVente"
									label="Unité de vente"
									bind:value={DOCL_uniteVente}
									disabled="true"
								/>
							</div>

							<div class="space-y-2">
								<DoubleInput
									id="pu"
									placeholder="10.00"
									label="Prix unitaire"
									bind:value={DOCL_PU}
									type="number"
									min={DOCL_minPU}
									step="0.01"
									required
								/>
							</div>
							{#if docType === "Facture"}
								<div class="space-y-2">
									<DateInput
										id="datePrestation"
										label="Date Prestation"
										bind:value={DOCL_DatePrestation}
									/>
								</div>
							{/if}
						{/if}
					</div>

					<div>
						<ButtonPrimary on:click={addDocLigne} type="button">Ajouter la ligne</ButtonPrimary>
					</div>

					<!-- Liste des lignes ajoutées -->
					<div class="mt-6">
						{#if docVenteBody.ListeRefArticle.length > 0}
							<table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
								<thead class="bg-gray-50">
									<tr>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Référence
										</th>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-fit"
											>Désignation
										</th>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-fit"
											>Quantité
										</th>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Unité de vente
										</th>
										{#if docType === "Facture"}
											<th
												class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
												>Date Prestation
											</th>
										{/if}
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Prix Unitaire TTC
										</th>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Montant HT
										</th>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											TVA
										</th>
										<th
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Montant TTC
										</th>
										<th
											class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
											>Actions
										</th>
									</tr>
								</thead>
								<tbody class="bg-white divide-y divide-gray-200">
									{#each docVenteBody.ListeRefArticle as ligne, index}
										<tr>
											<td
												id="Reference-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
											>
												<p>{ligne.Reference ?? ''}</p>
											</td>
											<td
												id="Designation-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 w-fit"
											>
												<p>{ligne.Designation}</p>
											</td>
											<td
												id="Quantite-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-fit"
											>
												<p>{ligne.Quantite === 0 ? '' : ligne.Quantite}</p>
											</td>
											<td
												id="UniteVente-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
											>
												<p>{ligne.UniteVente ?? ''}</p>
											</td>
											{#if docType === "Facture"}
												<td
													id="DatePrestation-{index}"
													class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
												>
													<p>{ligne.DatePrestation ? formatDateForDisplay(ligne.DatePrestation) : ''}</p>
												</td>
											{/if}
											<td
												id="PrixUnitaire-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-fit"
											>
												<p>{ligne.PrixUnitaire
													? formatToCurrency(ligne.PrixUnitaire)
													: ligne.PUTTC
														? formatToCurrency(ligne.PUTTC)
														: ''}</p>
											</td>
											<td
												id="MontantHT-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
											>
												<p>{ligne.MontantHT ? formatToCurrency(ligne.MontantHT) : ''}</p>
											</td>
											<td
												id="Taxe-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
											>
												<p>{ligne.Taxe ? formatToCurrency(ligne.Taxe) : ''}</p>
											</td>
											<td
												id="MontantTTC-{index}"
												class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
											>
												<p>{ligne.MontantTTC ? formatToCurrency(ligne.MontantTTC) : ''}</p>
											</td>

											<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
												{#if isEdition[index]}
													<button
														type="button"
														class="text-green-600 hover:text-green-900"
														on:click={() => saveEdit(index)}
													>
														Sauvegarder
													</button>
												{:else}
													<button
														type="button"
														class="text-blue-600 hover:text-blue-900"
														on:click={() => edit(index)}
													>
														Modifier
													</button>
												{/if}

												<button
													type="button"
													class="text-red-600 hover:text-red-900"
													on:click={() => removeDocLigne(index)}
												>
													Supprimer
												</button>
											</td>
										</tr>
									{/each}
								</tbody>
								<!-- Total row -->
								<tfoot class="divide-y divide-gray-200">
									<tr>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm">
											<p class="font-medium">Total</p>
										</td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm"></td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm"></td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm"></td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm"></td>
										{#if docType === "Facture"}
											<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm"></td>
										{/if}
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm">
											{formatToCurrency(totalHT)}
										</td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm">
											{formatToCurrency(totalTaxe)}
										</td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm">
											{formatToCurrency(totalTTC)}
										</td>
										<td class="text-gray-500 px-6 py-4 whitespace-nowrap text-sm"></td>
									</tr>
								</tfoot>
							</table>
						{:else}
							<p class="text-gray-500">Aucune ligne ajoutée pour le moment.</p>
						{/if}
					</div>
				</div>
			{/if}
		</form>
		{#if currentStep === 2}
			<!-- Afficher le blob du PDF en prévisualisation -->
			{#if pdfUrl}
				<div class="flex flex-col gap-4">
					<div class="text-lg font-semibold p-2 bg-gray-100">
						Document : {documentCreated?.DO_Piece || 'PDF'}
					</div>
					<object
						class="h-screen"
						data={pdfUrl}
						type="application/pdf"
						width="100%"
						aria-labelledby="PDF document"
						title={documentCreated?.DO_Piece || 'Document PDF'}
					>
						<p>Votre navigateur ne supporte pas les PDF.</p>
					</object>
				</div>
			{/if}
			{#if docType !== DOCVENTETYPES.COMMANDE}
				<div class="flex justify-center">
					<ButtonPrimary on:click={editDocVente} type="button">Modifier</ButtonPrimary>
				</div>
			{/if}
		{/if}
	</StepByStep>
</div>
