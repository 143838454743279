<script>
	import MaintenanceToggle from '../../components/Maintenance/MaintenanceToggle.svelte';
	import { connectedUserHasAccess, connectedUserHasRight } from '../../API/Backend/api';
	import { Icon, Cog6Tooth, ArrowPath, Users, ShieldCheck } from 'svelte-hero-icons';
	import { Edit, List } from '../../API/Backend/rights/permissions';
	import Card from '../../components/Card.svelte';
	import ListUserRights from './user-rights/ListUserRights.svelte';
	// SVELTE && STORE
	import { siteStatus } from '../../store';
	import { onMount } from 'svelte';
	import ListUser from './user/ListUser.svelte';

	const sections = [
		{
			title: 'Maintenance',
			description: 'Gérer le mode maintenance du site',
			icon: ArrowPath,
			right: 'admin',
			component: MaintenanceToggle,
		},
		{
			title: 'Utilisateurs',
			description: 'Gérer les utilisateurs du site',
			icon: Users,
			right: 'users',
			component: ListUser,
		},
		{
			title: 'Droits utilisateurs',
			description: 'Gérer les droits des utilisateurs du site',
			icon: ShieldCheck,
			right: 'rights',
			component: ListUserRights,
		},
	];

	onMount(() => {
		// Vérifier que l'utilisateur a les droits d'administration
		connectedUserHasAccess('admin', Edit);
	});

</script>

<div class="min-h-screen bg-gray-50 py-8">
	<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
		<!-- En-tête -->
		<div class="mb-8">
			<h1 class="text-3xl font-bold text-gray-900">Panneau d'administration</h1>
			<p class="mt-2 text-sm text-gray-600">
				Gérez les paramètres système, la maintenance et les configurations globales.
			</p>
		</div>

		<!-- Statistiques rapides -->
		<div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
			<Card noTitle={true} classes="p-4 w-full">
				<div class="flex items-center">
					<div class="flex-shrink-0">
						<Icon src={Cog6Tooth} class="h-6 w-6 text-gray-400" />
					</div>
					<div class="ml-5 w-0 flex-1">
						<dl>
							<dt class="text-sm font-medium text-gray-500 truncate">État du système</dt>
							<dd class="flex items-center truncate">
								<div class="flex-shrink-0">
									<span
										class="px-2 py-1 text-xs font-medium {$siteStatus.isInMaintenance
											? 'text-red-800 bg-red-100'
											: 'text-green-800 bg-green-100'} rounded-full"
									>
										{#if $siteStatus.isInMaintenance}
											Hors ligne
										{:else}
											En ligne
										{/if}
									</span>
								</div>
							</dd>
						</dl>
					</div>
				</div>
			</Card>
		</div>

		<!-- Sections principales -->
		<div class="grid grid-cols-1 gap-6 mb-8">
			{#each sections as section}
				{#if connectedUserHasRight(section.right, List)}
					<Card>
						<div slot="title" class="flex items-center">
							<Icon src={section.icon} class="h-6 w-6 text-gray-400 mr-3" />
							<h2 class="text-lg font-medium text-gray-900">{section.title}</h2>
						</div>
						<div class="flex justify-between">
							<p class="text-sm text-gray-500 mb-4">{section.description}</p>
						</div>
						<svelte:component this={section.component} />
					</Card>
				{/if}
			{/each}
		</div>
	</div>
</div>
