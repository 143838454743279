import { get, post } from './api';

/**
 * @description Récupère le statut actuel de la maintenance
 * @returns {Promise<{isInMaintenance: boolean, message?: string, startTime?: Date, endTime?: Date}>}
 */
export async function getMaintenanceStatus() {
    try {
        return await get('/maintenance/status');
    } catch (error) {
        console.error('Erreur lors de la récupération du statut de maintenance:', error);
        throw error;
    }
}

/**
 * @description Active ou désactive le mode maintenance
 * @param {boolean} isInMaintenance - true pour activer, false pour désactiver
 * @param {string} message - Message à afficher pendant la maintenance
 * @param {Date} endTime - Date de fin prévue de la maintenance
 * @returns {Promise<{isInMaintenance: boolean, message?: string, startTime: Date, endTime?: Date}>}
 */
export async function toggleMaintenance({ isInMaintenance, message, endTime }) {
    try {
        return await post('/maintenance/toggle', {
            isInMaintenance,
            message,
            endTime
        });
    } catch (error) {
        console.error('Erreur lors du basculement de la maintenance:', error);
        throw error;
    }
}
