<script>
    import { onMount } from 'svelte';
    import { getClientByReference, updateClient } from '../../API/API_SAGE/ClientQueries';
    import { addToast } from '../../store';
    import { formatDateForInput } from '../../utils.js';
    import Input from '../../components/Input/Input.svelte';
    import DateInput from '../../components/Input/DateInput.svelte';
    import Select from '../../components/Select/Select.svelte';
    import ButtonPrimary from '../../components/Button/ButtonPrimary.svelte';
    import { connectedUserHasAccess } from '../../API/Backend/api.js';
    import { Edit, List } from '../../API/Backend/rights/permissions.js';

    const civiliteOptions = [
        { value: '1', label: 'Monsieur' },
        { value: '2', label: 'Madame' }
    ];

    let client = {
        InfoLibres: [
            { Intitule: 'LS_CIVILITE', Valeur: '' },
            { Intitule: 'LS_NOM', Valeur: '' },
            { Intitule: 'LS_PRENOMS', Valeur: '' },
            { Intitule: 'LS_DATE_NAISSANCES', Valeur: '' },
            { Intitule: 'LS_PAYS_NAISSANCE', Valeur: '' },
            { Intitule: 'LS_DEPT_NAISSANCE', Valeur: '' },
            { Intitule: 'LS_COMMUNE_NAISSANCE', Valeur: '' },
            { Intitule: 'LS_CODE_COMMUNE_NAISSANCE', Valeur: '' },
            { Intitule: 'LS_CODE_COMMUNE_ADRESSE', Valeur: '' },
            { Intitule: 'LS_NOM_USAGE', Valeur: '' }
        ]
    };
    let isLoading = true;

    onMount(async () => {
        connectedUserHasAccess('urssaf', List);
        connectedUserHasAccess('client', Edit);
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const clientData = JSON.parse(urlParams.get('payload'));
            
            if (!clientData?.CT_Num) {
                throw new Error('Données client invalides');
            }

            const response = await getClientByReference(clientData.CT_Num);
            const fullClientData = response.data;

            client = {
                ...fullClientData,
                InfoLibres: [
                    { Intitule: 'LS_CIVILITE', Valeur: '' },
                    { Intitule: 'LS_NOM', Valeur: '' },
                    { Intitule: 'LS_PRENOMS', Valeur: '' },
                    { Intitule: 'LS_DATE_NAISSANCES', Valeur: '' },
                    { Intitule: 'LS_PAYS_NAISSANCE', Valeur: '' },
                    { Intitule: 'LS_DEPT_NAISSANCE', Valeur: '' },
                    { Intitule: 'LS_COMMUNE_NAISSANCE', Valeur: '' },
                    { Intitule: 'LS_CODE_COMMUNE_NAISSANCE', Valeur: '' },
                    { Intitule: 'LS_CODE_COMMUNE_ADRESSE', Valeur: '' },
                    { Intitule: 'LS_NOM_USAGE', Valeur: '' }
                ].map(template => {
                    const existingInfo = fullClientData.InfoLibres.find(
                        info => info.Intitule === template.Intitule
                    );
                    if (template.Intitule === 'LS_DATE_NAISSANCES' && existingInfo?.Valeur) {
                        return { ...template, Valeur: formatDateForInput(existingInfo.Valeur) };
                    }
                    return { ...template, Valeur: existingInfo?.Valeur || '' };
                })
            };
        } catch (error) {
            console.error('Erreur lors du chargement:', error);
            addToast('Erreur lors du chargement des données', 'bg-red-500');
        } finally {
            isLoading = false;
        }
    });

    async function handleSubmit() {
        try {
            const response = await updateClient(client.CT_Num, {
                InfoLibres: client.InfoLibres
                  .filter(infoLibre => infoLibre.Valeur)
            });

            if (response.data.status === 200) {
                addToast('Informations URSSAF mises à jour avec succès', 'bg-green-500');
                setTimeout(() => window.history.back(), 1000);
            } else {
                addToast('Erreur lors de la mise à jour', 'bg-red-500');
            }
        } catch (error) {
            console.error(error);
            addToast('Erreur lors de la mise à jour', 'bg-red-500');
        }
    }

    function getInfoLibreValue(intitule) {
        return client.InfoLibres.find(info => info.Intitule === intitule)?.Valeur;
    }

    function setInfoLibreValue(intitule, value) {
        const info = client.InfoLibres.find(info => info.Intitule === intitule);
        if (info) info.Valeur = value;
        client = { ...client };
    }
</script>

{#if !isLoading && client}
    <div class="container mx-auto p-4">
        <h1 class="text-2xl font-bold mb-6">Édition des informations URSSAF - {client.CT_Intitule}</h1>
        
        <form on:submit|preventDefault={handleSubmit} class="space-y-6 max-w-2xl">
            <div class="grid grid-cols-2 gap-4">
                <Select
                    label="Civilité"
                    options={civiliteOptions}
                    value={getInfoLibreValue('LS_CIVILITE')}
                    on:change={e => setInfoLibreValue('LS_CIVILITE', e.detail)}
                    required
                />

                <Input
                    label="Nom"
                    value={getInfoLibreValue('LS_NOM')}
                    on:input={e => setInfoLibreValue('LS_NOM', e.detail)}
                    required
                />

                <Input
                    label="Prénoms"
                    value={getInfoLibreValue('LS_PRENOMS')}
                    on:input={e => setInfoLibreValue('LS_PRENOMS', e.detail)}
                    required
                />

                <DateInput
                    label="Date de naissance"
                    value={getInfoLibreValue('LS_DATE_NAISSANCES')}
                    on:input={e => setInfoLibreValue('LS_DATE_NAISSANCES', e.detail)}
                    required
                />

                <Input
                    label="Pays de naissance"
                    value={getInfoLibreValue('LS_PAYS_NAISSANCE')}
                    on:input={e => setInfoLibreValue('LS_PAYS_NAISSANCE', e.detail)}
                    required
                />

                <Input
                    label="Département de naissance"
                    value={getInfoLibreValue('LS_DEPT_NAISSANCE')}
                    on:input={e => setInfoLibreValue('LS_DEPT_NAISSANCE', e.detail)}
                    required
                />

                <Input
                    label="Commune de naissance"
                    value={getInfoLibreValue('LS_COMMUNE_NAISSANCE')}
                    on:input={e => setInfoLibreValue('LS_COMMUNE_NAISSANCE', e.detail)}
                    required
                />

                <Input
                    label="Code commune de naissance"
                    value={getInfoLibreValue('LS_CODE_COMMUNE_NAISSANCE')}
                    on:input={e => setInfoLibreValue('LS_CODE_COMMUNE_NAISSANCE', e.detail)}
                    required
                />

                <Input
                    label="Code commune adresse"
                    value={getInfoLibreValue('LS_CODE_COMMUNE_ADRESSE')}
                    on:input={e => setInfoLibreValue('LS_CODE_COMMUNE_ADRESSE', e.detail)}
                    required
                />

                <Input
                    label="Nom d'usage"
                    value={getInfoLibreValue('LS_NOM_USAGE')}
                    on:input={e => setInfoLibreValue('LS_NOM_USAGE', e.detail)}
                />
            </div>

            <div class="flex justify-end space-x-4">
                <ButtonPrimary type="button" on:click={() => window.history.back()}>
                    Annuler
                </ButtonPrimary>
                <ButtonPrimary type="submit">
                    Enregistrer
                </ButtonPrimary>
            </div>
        </form>
    </div>
{:else}
    <div class="flex justify-center items-center h-screen">
        <p>Chargement...</p>
    </div>
{/if}
