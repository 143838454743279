

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} RefArt - 
 * @property {string} Design - 
 * @property {string} NoSerie - 
 * @property {number} Qte - 
 * @property {number} PdsBrut - 
 * @property {number} PdsNet - 
 */
class StocksDataEntryModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.RefArt = null;
    /** @type {string} */
    this.Design = null;
    /** @type {string} */
    this.NoSerie = null;
    /** @type {number} */
    this.Qte = null;
    /** @type {number} */
    this.PdsBrut = null;
    /** @type {number} */
    this.PdsNet = null;
  }
}

export default StocksDataEntryModel;
