<script>
	// Components
	import DataTable from '../../../components/DataTable.svelte';
	import ButtonPrimary from '../../../components/Button/ButtonPrimary.svelte';
	import LinkAction from '../../../components/Actions/LinkAction.svelte';
	import ButtonSecondary from '../../../components/Button/ButtonSecondary.svelte';
	import Modal from '../../../components/Modal.svelte';

	// Queries
	import { getUsersRights, removeRightFromUser } from '../../../API/Backend/rights/UserRightsQueries.js';

	// Utils
	import { All, allPermissions, Create, Delete, Edit } from '../../../API/Backend/rights/permissions.js';
	import { connectedUserHasRight } from '../../../API/Backend/api.js';
	import { openUrlInCurrentTab, ucfirst } from '../../../utils.js';
	import { addToast } from '../../../store.js';
	import { onMount } from 'svelte';

	const columns = [
		{ key: 'user.name', title: 'Utilisateur'},
		{ key: 'right.name', title: 'Droit', display: (row) => {
				return ucfirst(row.right.name).replace('_', ' ');
			}},
		{ key: 'right.description', title: 'Description'},
		{ key: 'permissions.data', title: 'Permission', display: (row) => {
				let permissions = row.permissions;
				if (permissions === All || permissions === allPermissions.reduce((acc, permission) => acc | permission.value, 0)) {
					return 'Tous';
				}
				let result = '';

				for (let permissionSearch of allPermissions) {
					if (permissions & permissionSearch.value) {
						if (result !== '') {
							result += ', ';
						}
						result += permissionSearch.name;
					}
				}
				return result;
			}},
	];
	const permissionsOptions = allPermissions.map(permission => ({ value: permission.value, label: permission.name }));

	let user;
	let right;
	let permission;
	let actions = [];
	let canCreate = false;
	let showConfirmDeleteModal = false;

	onMount(() => {
		canCreate = connectedUserHasRight('rights', Create);
		if (connectedUserHasRight('rights', Edit)) {
			actions.push({ component: LinkAction, props: { content: 'Modifier', action: 'edit' } });
		}

		if (connectedUserHasRight('rights', Delete)) {
			actions.push({ component: LinkAction, props: { content: 'Supprimer', action: 'delete' } });
		}
	})

	function handleEdit(row) {
		let userRight = row.detail.id;
		openUrlInCurrentTab(`/administration/manage-user-right/${userRight}`);
	}

	async function handleDelete(row) {
		user = row.detail.user;
		right = row.detail.right;
		showConfirmDeleteModal = true;
		window.dispatchEvent(new CustomEvent('refresh'));
	}
</script>

{#if canCreate}
	<ButtonPrimary>
		<a href="administration/manage-user-right">
			Créer droit utilisateur
		</a>
	</ButtonPrimary>
{/if}

<DataTable
	getQuery={getUsersRights}
	{columns}
	rowsPerPage={10}
	{actions}
	on:edit={handleEdit}
	on:delete={handleDelete}
/>

<Modal
	show={showConfirmDeleteModal}
	on:close={() => showConfirmDeleteModal = false}
	title="Suppression d'un droit d'un utilisateur"
>
	<div class="p-4">
		<p>Vous vous apprêtez à supprimer le droit {right.name} de l'utilisateur {user.name}.</p>
		<p>Êtes-vous sûr ?</p>
		<div class="flex justify-end gap-2 mt-4">
			<ButtonSecondary on:click={() => showConfirmDeleteModal = false}>
				Annuler
			</ButtonSecondary>
			<ButtonPrimary on:click={async () => {
				showConfirmDeleteModal = false;
				await removeRightFromUser(user.id, right.id);
				addToast('Droit supprimé avec succès', 'bg-green-500');
				openUrlInCurrentTab('/administration');
			}}>
				Supprimer le droit
			</ButtonPrimary>
		</div>
	</div>
</Modal>