<script>
	import { onMount } from 'svelte';
	import { getMaintenanceStatus } from '../API/Backend/maintenance';
	import Logo from '../../public/assets/img/LOGOsansINFO.png';
	import LoadingDots from '../components/Loading/LoadingDots.svelte';
	import { formatDateForDisplay } from '../utils';
	import ButtonPrimary from '../components/Button/ButtonPrimary.svelte';

	let maintenanceInfo = {
		message: '',
		endTime: null,
		isInMaintenance: true,
		isLoading: true
	};

	async function checkMaintenance() {
		try {
			const status = await getMaintenanceStatus();
			maintenanceInfo = {
				message: status.message || 'Site en maintenance',
				endTime: status.endTime,
				isInMaintenance: status.isInMaintenance,
				isLoading: false
			};

			// Si la maintenance est désactivée, rediriger vers l'accueil
			if (!status.isInMaintenance) {
				window.location.href = '/';
			}
		} catch (error) {
			maintenanceInfo.isLoading = false;
			console.error('Erreur lors de la récupération des informations de maintenance:', error);
		}
	}

	// Vérifier le statut toutes les 5 secondes
	onMount(() => {
		checkMaintenance();
		const interval = setInterval(checkMaintenance, 5000);
		return () => clearInterval(interval);
	});

	function retourApplication() {
		window.location.href = '/';
	}
</script>

<div class="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
	<div class="bg-white rounded-lg shadow-xl p-8 max-w-lg w-full text-center">
		<img src={Logo} alt="Logo" class="mx-auto h-24 mb-8" />

		{#if maintenanceInfo.isLoading}
			<div class="flex justify-center">
				<LoadingDots />
			</div>
		{:else if maintenanceInfo.isInMaintenance}
			<h1 class="text-3xl font-bold text-primary mb-4">Site en maintenance</h1>

			<p class="text-gray-600 mb-6">
				{maintenanceInfo.message}
			</p>

			{#if maintenanceInfo.endTime}
				<p class="text-sm text-gray-500">
					Fin prévue : {formatDateForDisplay(maintenanceInfo.endTime)}
				</p>
			{/if}

			<div class="mt-8 flex justify-center space-x-2">
				<div class="animate-bounce w-3 h-3 bg-primary rounded-full"></div>
				<div
					class="animate-bounce w-3 h-3 bg-primary rounded-full"
					style="animation-delay: 0.2s"
				></div>
				<div
					class="animate-bounce w-3 h-3 bg-primary rounded-full"
					style="animation-delay: 0.4s"
				></div>
			</div>
		{:else}
			<div class="text-center">
				<h2 class="text-2xl font-bold text-green-600 mb-4">La maintenance est terminée</h2>
				<p class="text-gray-600 mb-6">Vous pouvez maintenant retourner à l'application.</p>
				<ButtonPrimary content="Retour à l'application" on:click={retourApplication} />
			</div>
		{/if}
	</div>

	<footer class="mt-8 text-center text-gray-500 text-sm">
		<p>© {new Date().getFullYear()} Assortir. Tous droits réservés.</p>
		<p class="mt-2">Pour toute question, contactez votre administrateur.</p>
	</footer>
</div>