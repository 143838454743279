import Catalogue from './Catalogue';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} FA_CodeFamille - 
 * @property {string} FA_Intitule - 
 * @property {number} FA_Type - 
 * @property {number} FA_UniteVen - 
 * @property {number} FA_Coef - 
 * @property {number} FA_SuiviStock - 
 * @property {number} FA_Garantie - 
 * @property {number} FA_Central - 
 * @property {string} FA_Stat01 - 
 * @property {string} FA_Stat02 - 
 * @property {string} FA_Stat03 - 
 * @property {string} FA_Stat04 - 
 * @property {string} FA_Stat05 - 
 * @property {string} FA_CodeFiscal - 
 * @property {string} FA_Pays - 
 * @property {number} FA_UnitePoids - 
 * @property {number} FA_Escompte - 
 * @property {number} FA_Delai - 
 * @property {number} FA_HorsStat - 
 * @property {number} FA_VteDebit - 
 * @property {number} FA_NotImp - 
 * @property {string} FA_Frais01FR_Denomination - 
 * @property {number} FA_Frais01FR_Rem01REM_Valeur - 
 * @property {number} FA_Frais01FR_Rem01REM_Type - 
 * @property {number} FA_Frais01FR_Rem02REM_Valeur - 
 * @property {number} FA_Frais01FR_Rem02REM_Type - 
 * @property {number} FA_Frais01FR_Rem03REM_Valeur - 
 * @property {number} FA_Frais01FR_Rem03REM_Type - 
 * @property {string} FA_Frais02FR_Denomination - 
 * @property {number} FA_Frais02FR_Rem01REM_Valeur - 
 * @property {number} FA_Frais02FR_Rem01REM_Type - 
 * @property {number} FA_Frais02FR_Rem02REM_Valeur - 
 * @property {number} FA_Frais02FR_Rem02REM_Type - 
 * @property {number} FA_Frais02FR_Rem03REM_Valeur - 
 * @property {number} FA_Frais02FR_Rem03REM_Type - 
 * @property {string} FA_Frais03FR_Denomination - 
 * @property {number} FA_Frais03FR_Rem01REM_Valeur - 
 * @property {number} FA_Frais03FR_Rem01REM_Type - 
 * @property {number} FA_Frais03FR_Rem02REM_Valeur - 
 * @property {number} FA_Frais03FR_Rem02REM_Type - 
 * @property {number} FA_Frais03FR_Rem03REM_Valeur - 
 * @property {number} FA_Frais03FR_Rem03REM_Type - 
 * @property {number} FA_Contremarque - 
 * @property {number} FA_FactPoids - 
 * @property {number} FA_FactForfait - 
 * @property {number} FA_Publie - 
 * @property {string} FA_RacineRef - 
 * @property {string} FA_RacineCB - 
 * @property {Catalogue} Catalogue1 - 
 * @property {Catalogue} Catalogue2 - 
 * @property {Catalogue} Catalogue3 - 
 * @property {Catalogue} Catalogue4 - 
 * @property {number} FA_Nature - 
 * @property {number} FA_NbColis - 
 * @property {number} FA_SousTraitance - 
 * @property {number} FA_Fictif - 
 * @property {number} FA_Criticite - 
 */
class FamilleResponse {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.FA_CodeFamille = null;
    /** @type {string} */
    this.FA_Intitule = null;
    /** @type {number} */
    this.FA_Type = null;
    /** @type {number} */
    this.FA_UniteVen = null;
    /** @type {number} */
    this.FA_Coef = null;
    /** @type {number} */
    this.FA_SuiviStock = null;
    /** @type {number} */
    this.FA_Garantie = null;
    /** @type {number} */
    this.FA_Central = null;
    /** @type {string} */
    this.FA_Stat01 = null;
    /** @type {string} */
    this.FA_Stat02 = null;
    /** @type {string} */
    this.FA_Stat03 = null;
    /** @type {string} */
    this.FA_Stat04 = null;
    /** @type {string} */
    this.FA_Stat05 = null;
    /** @type {string} */
    this.FA_CodeFiscal = null;
    /** @type {string} */
    this.FA_Pays = null;
    /** @type {number} */
    this.FA_UnitePoids = null;
    /** @type {number} */
    this.FA_Escompte = null;
    /** @type {number} */
    this.FA_Delai = null;
    /** @type {number} */
    this.FA_HorsStat = null;
    /** @type {number} */
    this.FA_VteDebit = null;
    /** @type {number} */
    this.FA_NotImp = null;
    /** @type {string} */
    this.FA_Frais01FR_Denomination = null;
    /** @type {number} */
    this.FA_Frais01FR_Rem01REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais01FR_Rem01REM_Type = null;
    /** @type {number} */
    this.FA_Frais01FR_Rem02REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais01FR_Rem02REM_Type = null;
    /** @type {number} */
    this.FA_Frais01FR_Rem03REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais01FR_Rem03REM_Type = null;
    /** @type {string} */
    this.FA_Frais02FR_Denomination = null;
    /** @type {number} */
    this.FA_Frais02FR_Rem01REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais02FR_Rem01REM_Type = null;
    /** @type {number} */
    this.FA_Frais02FR_Rem02REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais02FR_Rem02REM_Type = null;
    /** @type {number} */
    this.FA_Frais02FR_Rem03REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais02FR_Rem03REM_Type = null;
    /** @type {string} */
    this.FA_Frais03FR_Denomination = null;
    /** @type {number} */
    this.FA_Frais03FR_Rem01REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais03FR_Rem01REM_Type = null;
    /** @type {number} */
    this.FA_Frais03FR_Rem02REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais03FR_Rem02REM_Type = null;
    /** @type {number} */
    this.FA_Frais03FR_Rem03REM_Valeur = null;
    /** @type {number} */
    this.FA_Frais03FR_Rem03REM_Type = null;
    /** @type {number} */
    this.FA_Contremarque = null;
    /** @type {number} */
    this.FA_FactPoids = null;
    /** @type {number} */
    this.FA_FactForfait = null;
    /** @type {number} */
    this.FA_Publie = null;
    /** @type {string} */
    this.FA_RacineRef = null;
    /** @type {string} */
    this.FA_RacineCB = null;
    /** @type {Catalogue} */
    this.Catalogue1 = null;
    /** @type {Catalogue} */
    this.Catalogue2 = null;
    /** @type {Catalogue} */
    this.Catalogue3 = null;
    /** @type {Catalogue} */
    this.Catalogue4 = null;
    /** @type {number} */
    this.FA_Nature = null;
    /** @type {number} */
    this.FA_NbColis = null;
    /** @type {number} */
    this.FA_SousTraitance = null;
    /** @type {number} */
    this.FA_Fictif = null;
    /** @type {number} */
    this.FA_Criticite = null;
  }
}

export default FamilleResponse;
