const API_URL = process.env.API_BACKEND_URL; // L'URL de votre backend NestJS

// Fonction pour récupérer le token JWT depuis le local storage
function getToken() {
	return localStorage.getItem('token');
}

// Fonction pour configurer les headers avec JWT
function getHeaders() {
	const token = getToken();
	return token
		? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
		: { 'Content-Type': 'application/json' };
}

// Fonction pour faire une requête GET
export async function get(endpoint) {
	const response = await fetch(`${API_URL}${endpoint}`, {
		method: 'GET',
		headers: getHeaders()
	});
	return response.json();
}

// Fonction pour faire une requête POST
export async function post(endpoint, data) {
	const response = await fetch(`${API_URL}${endpoint}`, {
		method: 'POST',
		headers: getHeaders(),
		body: JSON.stringify(data)
	});
	return response.json();
}

// Autres méthodes (PUT, DELETE) peuvent être ajoutées ici
