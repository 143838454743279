<script>
	import DataTable from '../../components/DataTable.svelte';
	import { getDevis } from '../../API/API_SAGE/DocVenteQueries';
	import { onMount } from 'svelte';
	import Chart from '../../components/Chart/Chart.svelte'; 
	import { addToast } from '../../store';

	let devis = [];
	let nbSaisie;
	let nbAccepte;
	let nbPerdu;

	onMount(async () => {
		devis = (await getDevis()).data;

		if (devis.status === 404) {
			addToast(devis.detail, 'bg-red-500');
			return;
		}
        
		devis.forEach((devis) => {
			if (devis.DO_Type === 0) {
				nbSaisie += 1;
			}
			if (devis.DO_Type === 2) {
				nbAccepte += 1;
			}
			if (devis.DO_Type === 3) {
				nbPerdu += 1;
			}
		});
	});

    const data = {
		labels: ['Saisie', 'Accepté', 'Perdu'],
		datasets: [
			{
				label: 'Devis',
				data: [nbSaisie, nbAccepte, nbPerdu],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)'
				],
				borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
				borderWidth: 1
			}
		]
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom'
			},
			title: {
				display: true,
				text: 'Nombre de devis par type'
			}
		}
	};

	let columns = [
		{ key: 'DO_Piece', title: 'DO_Piece' },
		{ key: 'DO_Date', title: 'DO_Date' },
		{ key: 'DO_Type', title: 'DO_Type' },
		{ key: 'DO_Ref', title: 'DO_Ref' }
	];
</script>

<DataTable {columns} getQuery={getDevis} />

<div class="chart-container">
	<Chart type="polarArea" {data} {options} />
</div>

<style>
	.chart-container {
		width: 50%;
		height: 50%;
	}
</style>
