import { cleanObject } from "./utils";
import { fetchData } from "./utils";


// STOCK

/**
 * Créer une nouvelle entrée en stock
 * @param {*} body 
 * @returns {Promise<{data: any, pagination: any}>}
 * 
 */
export async function newEntreeStock(body) {
    body = cleanObject(body); // Clean body before sending
    return await fetchData('/Stock/nouveau/entreeStock', {}, 'POST', body); // POST request
}

/**
 * Créer une nouvelle sortie en stock
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newSortieStock(body) {
    body = cleanObject(body); // Clean body before sending
    return await fetchData('/Stock/nouveau/sortieStock', {}, 'POST', body); // POST request
}

