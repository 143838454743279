<script>
	// Components
	import ButtonPrimary from '../../../components/Button/ButtonPrimary.svelte';
	import Input from '../../../components/Input/Input.svelte';
	import MailInput from '../../../components/Input/MailInput.svelte';
	import PasswordInput from '../../../components/Input/PasswordInput.svelte';
	import ButtonSecondary from '../../../components/Button/ButtonSecondary.svelte';
	import Modal from '../../../components/Modal.svelte';
	import Card from '../../../components/Card.svelte';

	// Utils
	import { addToast } from '../../../store.js';
	import { openUrlInCurrentTab } from '../../../utils.js';
	import { onMount } from 'svelte';

	import { Create, Delete, Edit } from '../../../API/Backend/rights/permissions.js';
	// Queries
	import { connectedUserHasAccess, connectedUserHasRight } from '../../../API/Backend/api.js';
	import { createUser, deleteUser, getUserById, updateUser } from '../../../API/Backend/rights/UserQueries.js';

	let user;
	let isEdition = false;
	let canEdit = false;
	let isCreation = false;
	let name = '';
	let email = '';
	let password = '';
	let showConfirmDeleteModal = false;

	onMount(async () => {
		let userID = parseInt(window.location.pathname.split('/').pop());

		if (!userID) {
			connectedUserHasAccess('users', Create);
			isCreation = true;
			return;
		}

		connectedUserHasAccess('users', Edit);
		isEdition = true;
		canEdit = true;
		user = await getUserById(userID);
		name = user.name;
		email = user.email;
	});

	async function handleSave() {
		let response;
		if (isCreation) {
			response = await createUser(name, email, password);
		} else if (isEdition) {
			response = await updateUser(user.id, name, email, password);
		}

		if (response.statusCode && response.statusCode !== 200) {
			addToast(`Erreur lors de la ${isCreation ? 'création' : 'modification'} de l'utilisateur ! ${response.message}`, 'bg-red-500');
			return;
		}

		if (isCreation) {
			addToast('Utilisateur créé avec succès', 'bg-green-500');
			setTimeout(() => {
				openUrlInCurrentTab(`manage-user/${response.id}`);
			}, 1000);
		} else if (isEdition) {
			addToast('Utilisateur modifié avec succès', 'bg-green-500');
		}
	}

	function handleDelete() {
		showConfirmDeleteModal = true;
	}
</script>

<Card classes="m-4">
	<div slot="title">
		{#if isCreation}
			Création d'un utilisateur
		{:else if isEdition}
			Modification de l'utilisateur {user?.name ?? ''}
		{/if}
	</div>
	<div class="flex-col w-full space-y-2 items-center p-4">
		<div class="flex-col space-y-2 w-1/3">
			<Input
				id="name"
				label="Nom de l'utilisateur"
				bind:value={name}
				disabled={!isCreation && !canEdit}
			/>

			<MailInput
				id="email"
				label="Adresse mail"
				bind:value={email}
				disabled={!isCreation && !canEdit}
			/>

			<PasswordInput
				id="password"
				label="Mot de passe"
				bind:value={password}
				disabled={!isCreation && !canEdit}
				autoComplete={false}
			/>
		</div>

		<div class="flex space-x-3">
			<ButtonPrimary type="submit" on:click={handleSave} classes="text-xs font-semibold">
				<div class="m-1">
					{#if !isEdition}
						Créer
					{:else}
						Sauvegarder
					{/if}
				</div>
			</ButtonPrimary>
			{#if isEdition && connectedUserHasRight('users', Delete)}
				<ButtonPrimary type="button" on:click={handleDelete}>
					Supprimer
				</ButtonPrimary>
			{/if}
		</div>
	</div>
</Card>

<Modal
	show={showConfirmDeleteModal}
	on:close={() => showConfirmDeleteModal = false}
	title="Suppression d'un utilisateur"
>
	<div class="p-4">
		<p>Vous vous apprêtez à supprimer l'utilisateur {user.name} possédant l'email : {user.email}.</p>
		<p>Êtes-vous sûr ?</p>
		<div class="flex justify-end gap-2 mt-4">
			<ButtonSecondary on:click={() => showConfirmDeleteModal = false}>
				Annuler
			</ButtonSecondary>
			<ButtonPrimary on:click={async () => {
				showConfirmDeleteModal = false;
				await deleteUser(user.id);
				addToast('Utilisateur supprimé avec succès', 'bg-green-500');
				openUrlInCurrentTab('/administration');
			}}>
				Supprimer l'utilisateur
			</ButtonPrimary>
		</div>
	</div>
</Modal>