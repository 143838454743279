<script>
	// Components
	import Input from '../../Input/Input.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';

	// Queries
	import { transformerDevis } from '../../../API/API_SAGE/DocVenteQueries.js';

	// Store
	import { addToast, closeDrawer, TYPEDOCDEST } from '../../../store.js';
	import { openUrlInCurrentTab } from '../../../utils.js';

	export let data;
	export let selectedDocVente;

	const typeDocDest = data.typeDocDest;

	async function transform() {
		if (data.DO_TotalTTC <= 0) {
			addToast('Le montant total TTC doit être supérieur à 0', 'bg-red-500');
			closeDrawer();
			return;
		}
		const body = {
			NumPiece: data.DO_Piece,
			TypeDocDest: typeDocDest,
			Statut: 'A comptabiliser'
		}
		let response;
		switch (selectedDocVente) {
			case 'devis':
				response = await transformerDevis(body).then((res) => res.data);
				break;
			default:
				response = { status: 400, detail: 'Action non autorisée' };
				break;
		}
		window.dispatchEvent(new CustomEvent('refresh'));
		if (response.status !== 200) {
			addToast(response.detail, 'bg-red-500');
		} else {
			addToast(response.detail, 'bg-green-500');
			setTimeout(() => openUrlInCurrentTab(`/ventes/${TYPEDOCDEST[typeDocDest]}/${response.do_piece}`), 2000);
		}
		closeDrawer();
	}
</script>

<div class="grid gap-2">
	<Input
		label="N° Piece {selectedDocVente}"
		bind:value={data.DO_Piece}
		disabled />

	<div class="w-full flex items-center justify-center mt-2">
		<ButtonPrimary on:click={transform}>
			<div class="p-2 font-medium text-sm">
				Transformer
			</div>
		</ButtonPrimary>
	</div>
</div>