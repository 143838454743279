import { fetchData } from './utils';
import { cleanObject } from './utils';

/**
 *
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get all clients
 */
export async function getClients(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy
) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

	return await fetchData('/client/LC', queryParams);
}

/**
 *
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Create a new client
 */
export async function newClient(body) {
	body = cleanObject(body); // Ensure body is cleaned before sending
	return await fetchData('/client/nouveau', {}, 'POST', body); // POST request with body
}
/**
 *
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Create a new client
 */
export async function newClientProspect(body) {
	body = cleanObject(body); // Ensure body is cleaned before sending
	return await fetchData('/Client/nouveau/prospect', {}, 'POST', body); // POST request with body
}
