<script>
	import { Router, Route } from 'svelte-routing';

	// pages for this layout
	import Login from 'views/auth/Login.svelte';
	import NotFound from 'views/404.svelte';
</script>

<Router url="auth">
	<Route path="login" component={Login} />
	<Route path="*" component={NotFound} />
</Router>
